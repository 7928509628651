<template>
  <div class="imgDialog">
    <el-dialog
      v-model="dialogVisible"
      lock-scroll
      width="100%"
      @close="closeDialog"
    >
      <!-- 弹出框左上角标题替换成按钮 -->
      <template #title="{ titleId, titleClass }" class="el-dialog__header">
        <button
          v-for="item in headerButton"
          :key="item.id"
          :id="titleId"
          class="hover_opacity"
          :class="{ active: isActive === item.id, titleClass }"
          @click="changeActive(item.id)"
        >
          {{ item.name }}
        </button>
      </template>
      <!-- 若有左上角按钮，则需传入图层距离按钮的距离imgTop -->
      <div class="dialogBody" :style="{ marginTop: imgTop }">
        <slot></slot>
        <div class="container_box full_height" v-if="openType !== ''">
          <img class="abso_img" v-show="isActive === '1'" :src="dataUrl" />
          <div class="container full_height" id="container"></div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { t } from '../../languages';
import { defineComponent, ref, onMounted, nextTick, toRefs } from "vue";

export default defineComponent({
  /**
   * 使用时传参(不需要用到则不用传)
   * isVisebleImg --是否显示
   * imgTop --图片与插槽间距
   *
   * 绑定事件
   * onClose --同步设置关闭弹窗
   */
  props: {
    isVisebleImg: [Boolean],
    imgTop: {
      type: String,
      default: "4rem",
    },
    openType: {
      type: String,
      default: "",
    },
    dataUrl: {
      type: String,
    },
  },
  emits: ["onClose"],
  setup(props, { emit }) {
    const panolens = window.PANOLENS;
        //解析外部组件传入的值
    let { isVisebleImg, dataUrl, openType } = toRefs(props);

    const headerButton = openType.value !== '' ? [
      {
        id: "1",
        name: t("220"),
      },
      {
        id: "2",
        name: t("221"),
      },
    ] : [];

    /* 切换按钮效果 */
    let isActive = ref("1");
    function changeActive(id) {
      isActive.value = id;
    }

    /* 弹窗关闭事件 */
    let dialogVisible = ref(false);
    dialogVisible.value = isVisebleImg.value;

    //关闭按钮
    function closeDialog() {
      dialogVisible.value = false;
      emit("onClose");
    }

    onMounted(() => {
      nextTick(() => {
        if(openType.value !== ''){
          initPanorama();
        }
      });
    });
    /* 初始化全景 */
    function initPanorama() {
      document.getElementById("container").innerHTML = "";
      const mainViewer = new panolens.Viewer({
        container: document.querySelector("#container"), //html容器
        // reverseDragging: true, //反向拖拽
        controlBar: false, //控制栏
      });
      const control = mainViewer.getControl();
      control.momentumDampingFactor = 0.9;
      const panorama = new panolens.ImagePanorama(dataUrl.value);
      mainViewer.add(panorama);
    }

    return {
      headerButton,
      isActive,
      dialogVisible,
      changeActive,
      closeDialog,
    };
  },
});
</script>
<style scoped lang="less">
.imgDialog {
  // width: 100%;
  // height: 100%;
  button {
    width: 7.1875rem;
    height: 2.5rem;
    background: #0d4460;
    border: 1px solid #318fb4;
    border-radius: 4px 0px 0px 4px;
    color: #ffffff;
  }
  button:last-child {
    border-radius: 0px 4px 4px 0px;
  }
  .dialogBody {
    width: 95.625rem;
    height: 45.5rem;
    margin: 0;
    padding: 0;
  }
}
.active {
  background: #0090da !important;
}
/deep/.el-dialog {
  background: rgba(0, 0, 0, 0.6);
  margin: 0;
  .el-dialog__header {
    padding-top: 0;
    padding-left: 5.875rem;
    margin-top: 3.75rem;
  }
  .el-dialog__body {
    padding-left: 13.0625rem;
    padding-top: 0;
    padding-bottom: 0;
  }
  .el-dialog__close {
    color: #ffffff;
    font-size: 23px;
    font-weight: bold;
  }
}
.el-dialog__header {
  margin-bottom: 1.5rem;
}
.container_box {
  position: relative;
  .abso_img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
