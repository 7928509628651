<template>
<div class="transfer_bac">
  <!-- 被选择的用户 -->
  <div class="has-choose">
    <div class="search flex-a search-position">
          <el-input
            style="100%"
            class="rolePut common-input"
            type="text"
            :placeholder="t('898')"
            v-model.trim="searchValue"
            clearable
            @clear="input_clean"
            @input="search(searchValue)"
          >
          <template #prefix>
            <el-icon color="#a8abb2" :size="15" class="no-inherit">
             <Search />
        </el-icon>
          </template>
          </el-input>
    </div>
    <div class="choose-user">
      <div class="choose-user-list" v-for="item in typeList" :key="item.id">
        <div class="choose-name-img" @click="chooseUser(item)">
        <img v-if="item.avatar" :src="item.avatar" alt=""><div  class="divAvatar" v-else>{{ item.userName.slice(0, 1) }}</div>
        <div><span class="choose-list-userName"  >{{ item.userName }}</span></div>
        </div>
        <div>
        <el-checkbox v-model="item.checked" size="large"></el-checkbox>
        </div>
      </div>
    </div>
  </div>
  <!-- 分割线 -->
  <el-divider direction="vertical" />
  <!-- 已选择的用户 -->
  <div class="chooseed">
    <div class="search flex-a search-position">
        <span class="chooseed-length">{{ t('548') }}@{{typeListEd.length}}{{ t('899') }}</span>
    </div>
    <div class="choose-user">
      <div class="choose-user-list" v-for="item in typeListEd" :key="item.id">
        <img v-if="item.avatar" :src="item.avatar" alt=""><div class="divAvatar" v-else>{{ item.userName.slice(0, 1) }}</div>
        <div><span class="choose-list-userName">{{ item.userName }}</span></div>
        <el-icon color="#409EFC" :size="15" class="no-inherit"  @click="delChooseUser(item)">
          <CircleCloseFilled />
        </el-icon>
      </div>
    </div>
    <div class="btn-position">
      <el-button size="small" @click="cancel">{{ t('217') }}</el-button>
      <el-button size="small" @click="sava">{{ t('218') }}</el-button>
    </div>
  </div>
</div>
</template>
  <script>
import { t } from '../../../languages';
import {
  defineComponent,
  onMounted,
  reactive,
  toRefs,
  computed,
  nextTick,
} from "vue";
import zhCn from "element-plus/lib/locale/lang/zh-cn";
import members from "@/network/member";
import chat from "@/network/chat.js";
import { ElMessage } from "element-plus";
export default defineComponent({
  props: {
    currGroupId: {
      type: String,
      default: '',
    },
    aiteEdList: {
      type: Array,
      default: () => [],
    }
  },
    emits: ["aiteEd",'closeaiteEd'],
    setup(props, { emit }) {
      const { currGroupId,aiteEdList } = toRefs(props);
      const state = reactive({
        leftValue: [],
        searchValue: '',
        typeList:[],
        userNameList:[],
        typeListOther:[]
      });
      const typeListEd = computed(() => {
        return state.typeListOther.filter(e=>{
           return e.checked !== false
        })
      });
      onMounted(() => {
        //获取类型列表
        methods.queryTypeList()
      });
      const methods = reactive({
        //获取所有用户
        async queryTypeList (){
          let allRes = await chat.getAllUsers({
          groupId: currGroupId.value,
        });
        allRes.resultData.filter((e,index,)=>{
          e.checked = false   
        })
        state.typeList = allRes.resultData.filter(e => e.userId !== JSON.parse(localStorage.getItem("userInfo")).id);
        state.typeList = state.typeList.filter(e => !aiteEdList.value.includes(e.userId));
        state.typeListOther = state.typeList
        },
        chooseUser(data){
          data.checked = !data.checked
        },
        delChooseUser(data){
          data.checked = false
        },
        sava: () => {
          let nameArr = []
          let idArr = []
          if (typeListEd.value.length !== 0) {
              typeListEd.value.forEach(e=>{
                nameArr.push(e.userName)
                idArr.push(e.userId)
              })
              emit("aiteEd",nameArr,idArr);
              emit("closeaiteEd");
          }else{
            return
          }
        },
        cancel: () => {
            emit("closeaiteEd");
        },
        input_clean: () => {
          state.searchValue = ''
        },
        search: (queryString) => {
          let valueInput=queryString || '';
          let dataSource= state.typeListOther.filter(function (item) {
              //遍历数组，返回值为true保留并复制到新数组，false则过滤掉
              let inputValue=new RegExp(`(.*)(${valueInput.split('').join(')(.*)(')})(.*)`, 'i');
              return item.userName.match(inputValue);
          });
          state.typeList = dataSource
        },
      });
  
      return {
        ...toRefs(state),
        ...toRefs(methods),
        typeListEd
      };
    }
  });
  </script>
  <style lang="less" scoped>
  .transfer_bac{
    background: #00578f;
    width: 35.3125rem;
    height: 30.6875rem;
    display: flex;
    .has-choose{
      width: 45%;
      height: 100%;
      .search-position{
        width: 85%;
        margin: 1rem auto;
      }
      .choose-user{
        width: 97.5%;
        height: 26rem;
        max-height: 26rem;
        overflow-y: scroll;
        margin: auto 5px;
        .choose-user-list{
          width: 100%;
          height: 3.75rem;
          background: #005185;
          margin-bottom: 2px;
          display: flex;
          align-items: center;
          border-radius: 4px;
          .choose-name-img{
            width: 80%;
            height: 3.75rem;
            background: #005185;
            display: flex;
            cursor: pointer;
            align-items: center;
            img{
              width: 2rem;
              height: 2rem;
              margin: 0px 1rem;
            }
            .choose-list-userName{
              font-size: 0.875rem;
              display: inline-block;
              margin: 0px 1rem;
              width: 6rem;
            }
          }
          /deep/ .el-checkbox__inner{
            border-radius: 50%;
            cursor: pointer;
            background-color: transparent;
          }
          /deep/.el-checkbox__input.is-checked .el-checkbox__inner{
            background-color: #32e743;
          }
        }
      }
    }
    .chooseed{
      width: 55%;
      height: 100%;
      position: relative;
      .search-position{
        width: 85%;
        margin: 1rem 0.5rem;
        .chooseed-length{
          display: inline-block;
          height: 34px;
          line-height: 34px;
        }
      }
      .choose-user{
        width: 92.5%;
        height: 18rem;
        max-height: 18rem;
        overflow-y: scroll;
        margin: auto 10px;
        .choose-user-list{
          width: 100%;
          height: 3.75rem;
          background: #005185;
          margin-bottom: 2px;
          display: flex;
          align-items: center;
          img{
            width: 2rem;
            height: 2rem;
            margin: 0px 1rem;
          }
          .choose-list-userName{
            font-size: 0.875rem;
            display: inline-block;
            margin: 0px 1rem;
            width: 9rem;
          }
          .no-inherit{
            cursor: pointer;
          }
          .no-inherit:hover{
            color: #fff;
          }
        }
      }
      .btn-position{
        position: absolute;
        bottom: 3%;
        right: 5%;
      }
    }
    /deep/ .el-divider--vertical{
      height: 100%;
      border-left: #049cff;
      background: #049cff;
      margin: 0px;
    }
  }
  .divAvatar{
        display: inline-block;
        width: 2rem;
        height: 2rem;
        // border-radius: 50%;
        line-height: 2rem;
        text-align: center;
        display: inline-block;
        background: #000;
        font-size: 0.5rem;
        margin: 0px 1rem;
      }
      /deep/.el-button--small{
    background: #049cff;
    color: #fff;
    border: none;
    
  }
  </style>