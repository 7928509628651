<template>
  <div class="container">
    <div class="top">
      <div class="content">
        <img :src="userInfo.avatar" class="avatar" v-if="userInfo.avatar" />
        <div v-else class="default_avatar flex-fac">
          <p class="default_name">{{ userInfo?.userName?.charAt(0) }}</p>
        </div>
        <p class="name">{{ userInfo?.userName }}</p>
      </div>
    </div>
    <div class="middle">
      <div class="middle_item flex-sb">
        <p class="iconfont icon-dianhua"></p>
        <p>{{ userInfo.phone }}</p>
      </div>
      <div class="middle_item flex-sb">
        <p class="iconfont icon-a-ziyuan24"></p>
        <p>{{ userInfo?.region?.split(';')[0] }}</p>
      </div>
      <div class="middle_item flex-sb">
        <p class="iconfont icon-yonghuguanli_huaban"></p>
        <p>{{ userInfo.teamName }}</p>
      </div>
      <div class="middle_item flex-sb">
        <img src="@/asset/img/benzi.png" />
        <p>{{ userInfo.roleName }}</p>
      </div>
    </div>
    <div class="user_certificate flex-sb">
      <div class="flex">
        <img src="@/asset/img/benzi.png" />
        <p style="margin-left: 10px">{{ t('914') }}</p>
      </div>
      <p
        class="iconfont icon-jiantouxia hover_opacity"
        :class="{ rotateBtn: isShow === true }"
        @click="isShow = !isShow"
      ></p>
    </div>
    <transition name="el-zoom-in-top">
      <div class="certificate_list" v-if="isShow">
        <el-image
          @click="clickImg"
          @close="closeImg"
          style="width: 5.2rem; height: 4.375rem"
          v-for="(item, index) in picList"
          :key="index"
          :src="item+ '!t270x150'"
          :initial-index="index"
          lazy
          :preview-src-list="picList"
          fit="cover"
        ></el-image>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { t } from '../../../languages';
import { ref, watch, toRefs } from "vue";
import chat from "@/network/chat.js";
import user from "@/network/user.js"
const props = defineProps({
  userId: {
    type: String,
    default: "",
  },
});

const isShow = ref(true);
const userInfo = ref({});
const { userId } = toRefs(props);

const getUserInfoById = async () => {
  const res = await chat.getUserInfoById(userId.value);
  userInfo.value = res.resultData;
  console.log(userInfo.value);
};
getUserInfoById();

const picList = ref([])
const getPicById = async() => {
  picList.value = []
  const res = await user.getCertificateList(userId.value)
  console.log(res.resultData);
  res.resultData.forEach(item => {
    picList.value.push(...item.photoUrl?.split(';'))
  });
  console.log(picList.value);
}
getPicById()
watch(
  () => userId.value,
  () => {
    getUserInfoById();
    getPicById();
  }
);
const emit = defineEmits(['clickImg','closeImg'])
   
       
const clickImg = () => {
  emit('clickImg')
}
const closeImg = () => {
  emit('closeImg')
}
</script>

<style lang='less' scoped>
.container {
  .top {
    width: 100%;
    height: 9.1875rem;
    background: #004775;
    padding-top: 0.8125rem;
    .content {
      margin: 0 auto;
      width: 4.5rem;
      height: 4.5rem;
      box-shadow: 0px 0px 3px black;
      border-radius: 50%;
      cursor: pointer;
      .avatar {
        height: 4.5rem;
        width: 4.5rem;
        border-radius: 50%;
      }
      .default_avatar {
        height: 4.5rem;
        width: 4.5rem;
        border-radius: 50%;
        background: #000;
        .default_name {
          font-size: 2.375rem;
        }
      }
      .name {
        text-align: center;
        font-size: 16px;
        margin-top: 9px;
      }
    }
  }
  .middle {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 0.75rem;
    padding: 1rem;
    width: 100%;
    height: 9.0625rem;
    background: #004775;
  }
  .user_certificate {
    width: 100%;
    height: 2.3125rem;
    background: #049cff;
    padding: 1rem;
    .rotateBtn {
      transform: rotate(180deg);
    }
  }
  .certificate_list {
    width: 100%;
    height: 5.6875rem;
    padding:0.6rem;
    background: #004775;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5625rem;
    justify-content: space-between;
    align-items: center;
    overflow-y: auto;
  }
}
</style>