import { t } from '../languages';

import service from "./service.js";

const syssetting = {
  //获取系统日志
  getLog: () => {
    return service.get({
      url: "/api/v1/log",
    });
  },
  //获取日志天数
  getLogDay: () => {
    return service.get({
      url: "/api/v1/log/systemLogConfig",
    });
  },
  //修改日志天数
  reviseDate: (day) => {
    return service.put({
      url: `/api/v1/settings/log/${day}`,
    })
  },
  //系统logo上传
  uploadLogo: (data) => {
    return service.put({
      url: `/api/v1/system/logoUrl?url=${data.url}`,
    })
  },
  //修改系统设置
  systemConfig: (data) => {
    return service.put({
      url: `/api/v1/system/config`,
      data,
    })
  },
  //获取系统配置信息
  getSystemData:()=>{
    return service.get({
      url:`/api/v1/system/config`
    })
  },
  //获取logo地址
  getLogoURL: () => {
    return service.get({
        url: `/api/v1/system/logoUrl`,
    })
  },
  //获取租户
  getTenant: () => {
    return service.get({
        url: `/api/v1/getTenant`,
    })
  },
}
export default syssetting;