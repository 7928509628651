import { t } from '../languages';

import service from "./service.js";

const members = {
    //获取table表格
    getTableDate: () => {
        return service.get({
            url: `/api/v1/member/listMemberByQuery`,
        });
    },
    //获取树表格
    getTreeData: () => {
        return service.get({
            url: `/api/v1/team/listTeamTree`,
        });
    },
    //新增成员
    addMember: (data) => {
        return service.post({
            url: `/api/v1/add/member`,
            data
        })
    },
    // 修改成员信息
    editMember: (data) => {
        return service.put({
            url: `/api/v1/settings/member`,
            data
        })
    },
    // 获取下拉角色列表
    getRoleDwonList:()=>{
        return service.get({
            url:`/api/v1/show/role/Down`
        })
    },

  //获取树表格
  getTreeList: () => {
    return service.get({
      url: `/api/v1/show/team/teamTreeList`,
    });
  },
  getRoleList: () => {
    return service.get({
      url: `/api/v1/show/roles/list`,
    });
  },
  /* 新增组织信息 */
  addTeam: (data) => {
    return service.post({
      url: `/api/v1/add/team`,
      data
    });
  },
  /* 修改组织信息 */
  editTeam: (data) => {
    return service.put({
      url: `/api/v1/settings/team`,
      data
    });
  },
  /* 删除组织 */
  delTeam: (teamId) => {
    return service.delete({
      url: `/api/v1/delete/team/${teamId}`,
    });
  },
  /* 获取人员列表 */
  getMemberList: (params) => {
    return service.get({
      url: `/api/v1/get/member/list`,
      params
    });
  },
  /* 批量删除人员 */
  batchDelMember: (data) => {
    return service.delete({
      url: `/api/v1/delete/member`,
      data
    });
  },
  /* 导出人员 */
  exportMember: (data) => {
    return service.post({
      url: `/api/v1/export/member`,
      data
    });
  },
   /* 导入人员 */
   importMember: (data,isFormData) => {
    return service.post({
      url: `/api/v1/member/import`,
      data,
      isFormData
    });
  },
  /*重置密码 */
  resetPassword: (data) => {
    return service.put({
      url: `/api/pv/myselfManage/reset/password`,
      data
    });
  },
  /*踢人下线*/
  kickout: (data) => {
    return service.post({
      url: `/auth/v1/kickout`,
      data
    });
  },
}
export default members;