<template>
  <div class="chat_room full_height">
    <Vue3DraggableResizable
      :initW="initW"
      :initH="initH"
      v-model:x="sizeParams.x"
      v-model:y="sizeParams.y"
      v-model:w="sizeParams.w"
      v-model:h="sizeParams.h"
      :draggable="true"
      :resizable="false"
      :handles="['bl']"
      @dragging="handleDrag"
    >
      <div class="box_top clearfix">
        <h2 class="fl">{{ t('357') }}</h2>
        <span
          class="iconfont icon-guanbi1 hover_opacity fr"
          @click.stop="closeDialog"
          @mousedown.stop
        ></span>
      </div>
      <div class="box_bottom" @mousedown.stop>
        <!-- 左侧在线离线 -->
        <div
          class="bottom_div online_box"
          @click.self="userinfoVisible = false"
        >
          <el-popover
            ref="popover"
            placement="right"
            :width="180"
            :visible="isPop"
            popper-class="notice_pop"
          >
            <template #reference>
              <div class="show_notice flex-fac" @click="toggleDialog('notice')">
                <div :class="noticeVisible?'notice_tbg':'notice_fbg'" class="notice flex-fac">
                  <i class="iconfont icon-laba"></i>
                </div>
              </div>
            </template>
            <div>
              <div class="notice14">{{ t('358') }}</div>
              <div class="notice12">{{ t('359') }}</div>
              <el-checkbox
                :label="t('272')"
                v-model="isNotPop"
                size="large"
                @change="notPopChange"
              />
            </div>
          </el-popover>
          <div class="user_list">
            <div
              class="user_item"
              @click="toggleDialog('userinfo', item.userId)"
              v-for="item in onlineUser"
              :key="item.userId"
            >
              <img
                v-if="item.avatar"
                :src="item.avatar"
                class="avatar"
                :class="{ avatar_leave: item.isOnLine === 0 }"
              />
              <div
                v-else
                class="default_avatar flex-fac"
                :class="{ avatar_leave: item.isOnLine === 0 }"
              >
                <p class="default_name">{{ item.userName[0] }}</p>
              </div>
              <p class="user_name overflow_text">{{ item.userName }}</p>
              <div class="iconfont icon-cha" v-if="item.isOnLine === 0"></div>
              <div class="iconfont icon-chenggong" v-else></div>
            </div>
          </div>
        </div>
        <!-- end -->
        <div class="bottom_div chat_box" @click="userinfoVisible = false">
          <div class="chat_box_con">
            <chatBox 
            :newMsg="newMsg" 
            :currGroupId="currGroupId" 
            v-if="currGroupId&&currGroupIdFlag" 
            :yiduMsg="yiduMsg" :subNotice="subNotice" 
            @submitNotice="submitNotice" 
            :trueNotice="trueNotice" 
            @openUserInfo="openUserInfo"
            @goMarkedPoint="goMarkedPoint"
            ></chatBox>
          </div>
        </div>
        <div class="absolute_box clearfix" >
          <transition name="leftSlide">
            <div class="notice_box fl" v-show="noticeVisible" ref="noticeContentRef">
              <div class="notice_con">
                <div class="notice_header flex-sb">
                  <div class="header_text">{{ t('358') }}</div>
                  <div v-if="isShowAdd" @click="noticeAdd" class="add">
                    <i class="iconfont icon-tianjia"></i>
                  </div>
                  <div v-if="!isShowAdd" class="flex">
                    <div @click="cancleNotice" class="btn">{{ t('217') }}</div>
                    <div @click="saveNotice" class="btn btn_left">{{ t('218') }}</div>
                  </div>
                </div>
                <div class="notice_content">
                  <div v-show="!isShowAdd" class="notice_list">
                    <div class="notice_list_header flex-sb">
                      <div class="index_font">{{ noticeList.length + 1 }}</div>
                    </div>
                    <div class="notice_list_content">
                      <el-input
                        ref="noticeRef"
                        type="textarea"
                        class="m2 common-input"
                        v-model.trim="noticeText"
                        onkeyup="this.value=this.value.replace(/\ +/g, '')"
                        show-word-limit
                        maxlength="100"
                        autosize
                        rows="5"
                        :placeholder="t('360')"
                        @input="noticeLen"
                      ></el-input>
                    </div>
                  </div>
                  <div
                    class="notice_list"
                    v-for="(item, index) in noticeList"
                    :key="item.id"
                  >
                    <div class="notice_list_header flex-sb">
                      <div class="index_font">
                        {{ noticeList.length - index }}
                      </div>
                      <div @click="delNotice(item.id)" class="del">
                        <i class="iconfont icon-shanchu"></i>
                      </div>
                    </div>
                    <div class="notice_list_content">{{ item.content }}</div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
          <transition name="leftSlide">
            <div class="userinfo_box fl" v-if="userinfoVisible">
              <div class="userinfo_con">
                <UserInfoBox :userId="userID" @clickImg="editZindex" @closeImg="editZindex('close')"></UserInfoBox>
              </div>
            </div>
          </transition>
        </div>
      </div>
      <!-- 选择任务照片 -->
      <!-- <div class="choose_photo">
        <choosePhoto></choosePhoto>
      </div> -->
    </Vue3DraggableResizable>
  </div>
</template>

<script setup>
import { t } from '../../languages';
import { reactive, ref } from "@vue/reactivity";
import { onBeforeUnmount, onMounted } from "@vue/runtime-core";
import Vue3DraggableResizable from "vue3-draggable-resizable";
import "vue3-draggable-resizable/dist/Vue3DraggableResizable.css";
import UserInfoBox from "./components/userInfoBox.vue";
import chatBox from "./components/chatBox.vue";
import socket from "./websocket.js";
import choosePhoto from "./components/choose-photo.vue";
import chat from "@/network/chat.js";
import { ElMessage } from "element-plus";
import "animate.css";
import emitter from "../../utils/mitt";

/* prop、emit */
const props = defineProps({
  isVisible: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["update:isVisible"]);
/* prop、emit */

/* localStorage */
const notNoticePop = localStorage.getItem("notNoticePop");
const userInfo = JSON.parse(localStorage.getItem("userInfo")); //获取用户信息
const allUser = ref(); //当前群组的所有成员
const onlineUser = ref(); //当前群主在线的成员
// console.log(userInfo);
/* ws模块start */

const newMsg = ref({});
const yiduMsg = ref({});
/* 接收消息 */
function receiveMess(data) {
  const action = data.action;
  switch (action) {
    case "BIND":
      // console.log(123);
      // console.log(data);
      ElMessage.info(t("361"));
      break;
    case "GROUP_COMM":
      newMsg.value = data;
      break;
    //离线case
    case "OFF_LINE":
      isOnLineList();
      break;
    //在线case
    case "CHAT":
      isOnLineList();
      break;
    case "GROUP_ANNOUNCEMENT":
      getUpdateNotice();
      break;
    case "CONFIRM_READ":
      if(data.data.code === "CONFIRM_ANNOUNCEMENT"){
        trueNotice.value = data;
        getUpdateNotice();
      }
      if (data.data.code === 'CONFIRM_MSG') {
        yiduMsg.value = data;
      }
      break;
    default:
      break;
  }
  // console.log(t("362"), data);
}

/* 发送消息 */
function sendMess(action, data, code = "") {
  websocketsend({
    action: action,
    code: code,
    data: data,
  });
}

const { openSocketConnect, websocketsend, closeSocketConnect } = socket(
  wsOpenCallback,
  receiveMess,
);
// 打开ws连接
openSocketConnect();

let groupList = []; //群组列表
let currGroup = null; //当前群组
const currGroupId = ref("");//当前群组id
const currGroupIdFlag = ref(false);//当前群组id 是否获取成功
const noticeList = ref([]); //公告
let locationData = null;  //标记消息
/* 打开ws连接回调 */
async function wsOpenCallback() {
  // 新建im用户
  await chat.saveImUser({
    account: userInfo.account,
    avatar: userInfo.avatar,
    jobNumber: userInfo.jobNumber,
    phone: userInfo.phone,
    region: userInfo.region,
    roleName: userInfo.roleName,
    teamName: userInfo.teamName,
    userId: userInfo.id,
    userName: userInfo.username,
  });
  // 获取全部群组
  const groupRes = await chat.queryAllGroup({ userId: userInfo.id });
  // console.log(groupRes);
  if (groupRes.resultStatus) {
    groupList = groupRes.resultData;
    if (groupList.length > 0) {
      currGroupId.value = groupList[0];
      currGroupIdFlag.value = true
      //bind 加入聊天室群组
      websocketsend({
        action: "BIND",
        code: "",
        data: {
          groupId: currGroupId.value, //群组id
          userId: userInfo.id, //成员id
        },
      });
      //获取公告
      getUpdateNotice();
    }
  }
  // 指挥中心进来聊天室的，通信就绪后发送标记消息
  if(locationData !== null){
    sendMapMsg(locationData);
  }
}

// 处理在线离线
async function isOnLineList() {
  //查询群组所有成员
  const allRes = await chat.getAllUsers({
    groupId: currGroupId.value,
  });
  allUser.value = allRes.resultData;
  // console.log(allUser.value);

  //查询im在线用户
  const onlineRes = await chat.onlineInquiry(currGroupId.value);
  onlineUser.value = onlineRes.resultData;
  let onLineId = onlineUser.value.map((item) => item.userId);
  // console.log(onLineId);
  allUser.value.forEach((item) => {
    if (onLineId.indexOf(item.userId) === -1) {
      onlineUser.value.push({ ...item, isOnLine: 0 });
    } else {
      onlineUser.value[onLineId.indexOf(item.userId)].isOnLine = 1;
    }
  });
  // console.log(onlineUser.value);
}

/* 退出聊天室 */
function offline() {
  websocketsend({
    action: "OFF_LINE",
    code: "",
    data: {
      groupId: currGroupId.value, //群组id
      userId: userInfo.id, //成员id
    },
  });
}

/* ws模块end */

/* 页面布局start */
const noticeVisible = ref(true);
const userinfoVisible = ref(false);

function getScale(width) {
  let scale = 1;
  if (width > 1750) {
    scale = 1;
  } else if (width > 1600 && width <= 1750) {
    scale = 0.875;
  } else if (width > 1440 && width <= 1600) {
    scale = 0.8125;
  } else {
    scale = 0.75;
  }
  return scale;
}

let clientW = document.documentElement.clientWidth;
const clientH = document.documentElement.clientHeight;

const scale = getScale(clientW);
const initW = 780 * scale;
const initH = 686 * scale;

const sizeParams = reactive({
  x: (clientW - initW) / 2,
  y: (clientH - initH) / 2,
  w: initW,
  h: initH,
});

/* 
  捕捉窗口变化事件 -- 隐藏公告框和用户信息框
*/
function hideNoticeUser() {
  noticeVisible.value = false;
  userinfoVisible.value = false;
}

/* 捕获拖拽 */
function handleDrag() {
  hideNoticeUser();
  if (isPop.value) {
    isPop.value = false;
  }
}

/* 打开框 */
const userID = ref("");
function toggleDialog(type, userId) {
  if (type === "notice") {
    noticeVisible.value = !noticeVisible.value;
    if (!noticeVisible.value) {
      isPop.value = false;
    }
  } else {
    if (userID.value === userId) {
      userinfoVisible.value = !userinfoVisible.value;
    } else {
      userinfoVisible.value = true;
      userID.value = userId;
    }
  }
}

/* 关闭弹窗 */
function closeDialog() {
  emit("update:isVisible", false);
}

/* 页面布局start */

function resize() {
  hideNoticeUser();
  sizeParams.w = initW;
  clientW = document.documentElement.clientWidth;
  sizeParams.x = (clientW - initW) / 2;
}

window.addEventListener("resize", resize);


onBeforeUnmount(() => {
  window.removeEventListener("resize", resize);
  offline();
  closeSocketConnect();
});

// 公告
const isPop = ref(true);
const isNotPop = ref(false);
const noticeText = ref("");
const isShowAdd = ref(true);
const subNotice = ref(false);
const trueNotice = ref({});
const noticeRef = ref(null);
isPop.value = notNoticePop === null ? true : false;
isNotPop.value = notNoticePop === null ? false : true;

const noticeAdd = () => {
  isShowAdd.value = !isShowAdd.value;
  noticeRef.value.focus();
};
const cancleNotice = () => {
  noticeText.value = "";
  isShowAdd.value = !isShowAdd.value;
};
const saveNotice = () => {
  if(noticeText.value!==''){
    websocketsend({
    action: "GROUP_ANNOUNCEMENT",
    code: "PUBLIC_ANNOUNCEMENT",
    groupCode: currGroupId.value,
    data: {
      userId: userInfo.id,
      content: noticeText.value,
    },
  });
  noticeText.value = "";
  isShowAdd.value = !isShowAdd.value;
  }else{
     ElMessage.warning(t("363"));
  }
  
};
const delNotice = (id) => {
  websocketsend({
    action: "GROUP_ANNOUNCEMENT",
    code: "DELETE_ANNOUNCEMENT",
    groupCode: currGroupId.value,
    data: {
      userId: userInfo.id,
      id: id,
    },
  });
};
function submitNotice(){
    websocketsend({
    action: "CONFIRM_READ",
    code: "CONFIRM_ANNOUNCEMENT",
    groupCode: currGroupId.value,
    data: {
      userId: userInfo.id,
      userName:userInfo.username,
      id: noticeList.value[0].id,
    },
  });
}

function openUserInfo(type,userId){
  toggleDialog(type,userId)
}

const noticeLen = (e) => {
  if (e.length >= 100) {
    ElMessage.warning(t("364"));
  }
};
const getUpdateNotice = async () => {
  const notice = await chat.getNotice(currGroupId.value);
  if (notice.resultStatus) {
    noticeList.value = notice.resultData;
    if(noticeList.value.length === 0){
      subNotice.value = false;
      return;
    };
    let confirmList = [];
    confirmList  = notice.resultData[0].confirmList.split(',');
    let res = confirmList.includes(userInfo.id);
    if(res){
      subNotice.value = false;
    }else{
      subNotice.value = true;
    }
  }
};
const notPopChange = (val) => {
  if (val) {
    setTimeout(() => {
      isPop.value = false;
    }, 500);
    localStorage.setItem("notNoticePop", false);
  }
};
onMounted(() => {
  document.querySelector(".chat_room").onclick = function () {
    if (isPop.value) {
      setTimeout(() => {
        isPop.value = false;
      }, 500);
    }
  };
});

//动态修改公告层级
const noticeContentRef = ref(null)
const editZindex = (type) => {
  if(type == 'close'){
    noticeContentRef.value.style.zIndex = 2
  }else{
    noticeContentRef.value.style.zIndex = 1
  }
  
}
/**
 * 发送标记位置
 * flag -- 是否立马发送， true（立马发送） false（等待通信就绪后发送）
 * data -- 标记数据
 */
function sendLocation(flag, data){
  if(flag){
    // 立马发送
    sendMapMsg(data);
  } else {
    // 存储起来
    locationData = data;
  }
}

async function sendMapMsg (data)  {
  const formData = new FormData();
  formData.append('file',data.file);
  const res = await chat.upLoadImg(formData);
  if (res.resultStatus) {
    const mapControl = {
          action: "GROUP_COMM",
          code: "TEXT_MSG",
          groupCode: currGroupId.value, //群组id
          data: {
            type: 3, //0-普通消息，1-@消息，2-任务消息
            confirmUser: '', //被@成员列表 成员id逗号隔开。（若不是@消息，则该字段为空字符串）
            level: 1, //消息等级
            userId: userInfo.id, // 用户id（消息发送人）
            userName: userInfo.username, // 用户id（消息发送人）
            avatar: userInfo.avatar, // 用户id（消息发送人）
            longitude: data.lng, //经度
            latitude: data.lat, //纬度
            altitude: data.alt, //高度
            locationName: data.name, //定位标点名称
            msg: res.resultData.url, //pic
          },
        };
  websocketsend(mapControl)
  }
   
}

function goMarkedPoint(data) {
  emitter.emit('position-mark', {
        lng: data.longitude,
        lat: data.latitude, 
        alt: data.altitude
      });
}


defineExpose({
  sendLocation
})

//yyh区域

// yyh区域end
</script>

<style scoped lang="less">
.chat_room {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 80;
  pointer-events: none;
}
.box_top {
  height: 3.375rem;
  line-height: 3.375rem;
  background: #0b659f;
  padding: 0 0.625rem 0 0.875rem;
  > h2 {
    display: inline-block;
    font-weight: normal;
    font-size: 1.125rem;
  }
  .iconfont {
    font-size: 1.625rem;
  }
}
.box_bottom {
  position: relative;
  height: calc(100% - 3.375rem);
  padding-left: 4.75rem;
  .bottom_div {
    height: 100%;
  }
  .online_box {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 4.75rem;
    // padding-bottom: 1.25rem;
    background: #0b4265;
    .show_notice {
      width: 100%;
      padding: 1.5rem 1rem;
      .notice {
        width: 2.75rem;
        height: 2.75rem;
        border-radius: 50%;
        cursor: pointer;
        i {
          color: #ffffff;
          font-size: 1.625rem;
        }
      }
      .notice_tbg{
        background: #049cff;
      }
      .notice_fbg{
        background: #052a3f;
      }
    }
    .user_list {
      display: flex;
      flex-direction: column;
      height: calc(100% - 92px);
      overflow-y: auto;
      align-items: center;
      .user_item {
        position: relative;
        width: 3rem;
        height: 3rem;
        box-shadow: 0px 0px 5px black;
        border-radius: 50%;
        cursor: pointer;
        .avatar {
          height: 3rem;
          width: 3rem;
          border-radius: 50%;
        }
        // 遮罩层
        .avatar_leave {
          opacity: 0.4;
          filter: alpha(opacity=40);
        }
        .default_avatar {
          height: 3rem;
          width: 3rem;
          border-radius: 50%;
          background: #000;
          .default_name {
            font-size: 1.375rem;
          }
        }
        .user_name {
          width: 100%;
          text-align: center;
          font-size: 12px;
        }
        .icon-chenggong {
          position: absolute;
          color: #00ff00;
          background: #fff;
          border-radius: 100%;
          font-size: 12px;
          bottom: 0;
          right: 0;
          z-index: 1;
        }
        .icon-cha {
          position: absolute;
          background: #fff;
          border-radius: 100%;
          color: red;
          font-size: 12px;
          bottom: 0;
          right: 0;
          z-index: 1;
        }
      }
      .user_item:not(:first-child) {
        margin-top: 40px;
      }
    }
  }
  .chat_box {
    padding-left: 1px;
    .chat_box_con {
      height: 100%;
      background: #0b4265;
      position: relative;
    }
  }
  .absolute_box {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 100%;
    width: 31.375rem;
    overflow: hidden;
    pointer-events: none;
  }
  .notice_box {
    width: 17.125rem;
    height: 100%;
    padding-left: 1px;
    position: relative;
    z-index: 2;
    pointer-events: auto;
    .notice_con {
      height: 100%;
      background: #0b4265;
    }
    .notice_header {
      width: 100%;
      height: 2.8125rem;
      padding: 0.875rem 1.375rem;
      border-bottom: 1px solid #0587db;
      font-size: 1.125rem;
      .header_text {
        color: #049cff;
        font-weight: 400;
      }
      .add {
        cursor: pointer;
        i {
          font-size: 1.5rem;
        }
      }
      .btn {
        width: 3.6875rem;
        height: 1.5rem;
        background: #ffffff;
        border-radius: 4px;
        color: #049cff;
        font-size: 0.75rem;
        text-align: center;
        line-height: 1.5rem;
        cursor: pointer;
      }
      .btn_left {
        margin-left: 0.875rem;
      }
    }
    .notice_content {
      width: 100%;
      height: calc(100% - 2.8125rem);
      overflow: hidden;
      overflow: auto;
      .notice_list {
        width: 100%;
        min-height: 9.375rem;
        padding: 0.875rem 1.375rem;
        border-bottom: 1px solid#105888;
        font-size: 0.75rem;
        .notice_list_header {
          height: 2.8125rem;
          width: 100%;
        }
        .index_font {
          font-size: 1.125rem;
          color: #049cff;
        }
        .notice_list_content {
          height: calc(100% - 2.8125rem);
          width: 100%;
          color: #ffffff;
          line-height: 1.25rem;
        }
        .del {
          cursor: pointer;
          i {
            font-size: 1.125rem;
          }
        }
        /deep/.el-textarea__inner {
          border: none !important;
          background: none !important;
          box-shadow: 0 0 0 0;
          color: #ffffff !important;
          padding: 0 !important;
          font-size: 0.75rem;
          min-height: 4.375rem !important;
        }
        /deep/.el-textarea .el-input__count {
          display: none !important;
        }
      }
      .notice_list:last-child {
        border-bottom: 0px solid#084973;
      }
    }
  }
  .userinfo_box {
    width: 14.25rem;
    height: 100%;
    padding-left: 1px;
    position: relative;
    z-index: 1;
    pointer-events: auto;
    .userinfo_con {
      height: 100%;
      background: #0b4265;
      padding: 0.75rem;
    }
  }
}

.vdr-container {
  pointer-events: auto;
}

.vdr-container.active {
  border: none;
}

.choose_photo {
  position: absolute;
  top: 100px;
  left: 100px;
}

/* 左移动画 */

.leftSlide-enter-active {
  animation: left-slide-in 0.5s;
}
.leftSlide-leave-active {
  animation: left-slide-in 0.5s reverse;
}
@keyframes left-slide-in {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}
</style>