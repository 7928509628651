import { t } from '../../../languages';
import { getToken } from '@/utils/auth'
const user = {
  state: {
    token: getToken(),
    userInfo: {},
  },
  mutations: {
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo;
    },
    removeUserInfo(state) {
      state.userInfo = {}
    },
    //修改用户名
    updateUsername(state, username) {
      state.userInfo.username = username;
    },
    //修改用户头像
    updateAvatar(state, avatar) {
      state.userInfo.avatar = avatar;
    }
  },
  actions: {
    changeUsername(context, username) {
      context.commit('updateUsername', username)
    },
    changeAvatar(context, avatar) {
      context.commit('updateAvatar', avatar)
    },
  }
}
export default user