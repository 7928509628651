<template>
  <div class="table-wrapper fullHeight" :style="{ height: fullHeight }">
    <div class="table_box">
      <el-table
        ref="eleTable"
        height="90%"
        :row-style="rowStyle"
        :cell-style="cellStyle"
        :data="tableData"
        :header-cell-style="headerStyle"
        :border="border"
        fit
        highlight-current-row
        header-row-class-name="header-row-class"
        class="el_table"
        @selection-change="handleSelectionChange"
        @row-click="handleRowClick"
      >
        <el-table-column
          type="selection"
          align="center"
          width="35"
          v-if="hasSelection"
        />
        <el-table-column
          v-if="!serialNub"
          type="index"
          :label="t('222')"
          width="80"
          align="center"
          :index="calculateIndex"
        />
        <el-table-column
          v-for="item in tableColumnArray"
          :key="item.prop"
          :prop="item.prop"
          :label="item.label"
          :align="item.align || 'center'"
          :fixed="item.fixed"
          show-overflow-tooltip
          :width="item.width"
        >
          <template v-slot="{ row }">
            <slot :prop="item.prop" :row="row">
              <span>
                {{ row[item.prop] || (row[item.prop] === 0 && "0") || "-" }}
              </span>
            </slot>
          </template>
          <!-- <slot name="buttonHanlder" :index="index"></slot> -->
        </el-table-column>
        <slot name="buttonHanlder"></slot>
        <template #empty>
          <div class="emptyTbody">
            <div>
              <img src="./../../asset/img/empty_data.png" :alt="t('223')" />
            </div>
            <p class="no_data">{{ t('223') }}</p>
          </div>
        </template>
      </el-table>
    </div>

    <!-- 底部分页 -->
    <el-pagination
      v-if="!notShowingPagination"
      class="el_pageination"
      background
      @size-change="handleSizeChange"
      @current-change="handleNoChange"
      :current-page="pageNo"
      :page-size="pageSize"
      layout="total, prev, pager, next, sizes, jumper"
      :total="total"
    ></el-pagination>
  </div>
</template>

<script>
import { t } from '../../languages';
import { computed, defineComponent, reactive, toRefs, ref } from "vue";
export default defineComponent({
  name: "eleTable",
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    headerStyle: {
      type: Object,
      default: () => {
        return {
          background: "rgba(1, 55, 82,.8)",
          color: "#36E5FF",
          fontSize: "1.125rem",
          fontWeight: "normal",
        };
      },
    },

    tableColumnArray: {
      type: Array,
      default: () => [],
    },

    hasSelection: {
      type: Boolean,
      default: true,
    },
    tailSelection: {
      type: Boolean,
      default: false,
    },
    fullHeight: {
      type: String,
      default: "75vh",
    },

    serialNub: {
      type: Boolean,
      default: false,
    },

    border: {
      type: Boolean,
      default: false,
    },

    pageNo: {
      type: Number,
      default: 1,
    },

    pageSize: {
      type: Number,
      default: 10,
    },

    total: {
      type: Number,
      default: 0,
    },

    notShowingPagination: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    "update:pageNo",
    "handleNoChange",
    "update:pageSize",
    "handleSizeChange",
    "handleSelectionChange",
  ],
  setup(props, { emit }) {
    /* 切换分页后，更新序号 */
    const calculateIndex = computed(() => {
      return (props.pageNo - 1) * props.pageSize + 1;
    });
    const eleTable = ref(null);
    const methods = reactive({
      rowStyle: () => {
        return {
          height: "48px",
          background: "rgba(0, 45, 74, 0.6)",
          fontSize: "1.0625rem",
          color: "#CCCCCC",
        };
      },
      cellStyle: (column) => {
        const columnClassName = column.column.className;
        if (columnClassName === 'el-table-column--selection') {
          return {
            background: "#0092CF",
            borderRadius: "8px 0px 0px 8px",
          };
        }
      },
      //新当前页触发的函数
      handleNoChange: (pageNo) => {
        emit("update:pageNo", pageNo);
        emit("handleNoChange");
      },
      //选择多少条每页时触发的函数
      handleSizeChange: (pageSize) => {
        emit("update:pageSize", pageSize);
        emit("handleSizeChange");
      },
      //当选择项发生变化时会触发的函数
      handleSelectionChange: (selection) => {
        emit("handleSelectionChange", selection);
      },
      handleRowClick: (row) => {
        eleTable.value.toggleRowSelection(row);
      },
    });
    return {
      calculateIndex,
      eleTable,
      ...toRefs(methods),
    };
  },
});
</script>

<style >
/* 去掉表格上下边框 */
.el-table td,
.el-table th.is-leaf {
  border-bottom: none !important;
}
.el-table .cell {
  text-overflow: unset;
}
</style>

<style lang="less" scoped>
.table-wrapper {
  position: relative;
  /deep/ .el-table {
    background: none !important;
  }
  /deep/ .el-table::before {
    background: transparent;
  }
  .table_box {
    height: calc(100% - 4rem);
  }
  /deep/.el-table__inner-wrapper::before {
    height: 0;
  }
  /deep/ .el-table__body {
    border-spacing: 0 4px !important;
  }
  /deep/ .el-table__empty-block{
    height:calc(100% - .5rem) !important;
  }
  /deep/ .el-table__row {
    margin-top: 15px;
  }
  /deep/ .el-table__body tr:hover > td {
    background-color: #004375 !important;
  }
  /deep/.el-table__body tr.current-row > td {
    background-color: #004375 !important;
  }
  /deep/ .el-scrollbar__view {
    height: 100%;
  }
}
// 分页
.el_pageination {
  position: absolute;
  right: 0;
  //总页数样式设置
  /deep/ .el-pagination__total {
    color: #ffffff;
  }
  //前往（）页
  /deep/ .el-pagination__jump {
    color: #ffffff;
    margin-left: 0;
  }
  //分页条数选择背景，前往（）页jump背景
  /deep/ .el-input__wrapper {
    background: #001c31;
    box-shadow: 0 0 0 0;
    margin-left: 5px;
    border: 1px solid #36e5ff;
  }
  //分页条数选择，前往（）页jump文字颜色
  /deep/ .el-input__inner {
    color: #ffffff;
  }
}
.el_pageination.is-background {
  /deep/ .el-pager li:not(.disabled):hover {
    background: #0090da !important;
    border-color: #0090da;
    color: #fff;
  }
  //活动的按钮
  /deep/ .el-pager li:not(.disabled).is-active {
    background: #0090da;
    border: 1px solid #0090da;
  }
  /deep/ .btn-prev {
    background: rgba(0, 28, 49, 0.8);
    border: 1px solid #36e5ff;
  }
  /deep/ .btn-next {
    background: rgba(0, 28, 49, 0.8);
    border: 1px solid #36e5ff;
  }
  /deep/ .el-pager li {
    background: rgba(0, 28, 49, 0.8);
    border: 1px solid #36e5ff;
    color: #fff;
  }
}
.emptyTbody {
  font-size: 1.125rem;
  color: #fff;
  img {
    width: 17.5rem;
  }
}
</style>