<template>
  <el-drawer
    :title="drawerTitle"
    v-model="drawerVisible"
    :size="drawerWidth"
    :modal="true"
    destroy-on-close
    :before-close="handleClose"
    :custom-class="isFullScreen ? 'full_screen_drawer' : 'common_drawer'"
    :close-on-click-modal="true"
  >
  <slot></slot>
  </el-drawer>
</template>
<script>
import { t } from '../../languages';
import { defineComponent, ref, toRefs, watch } from "vue";

export default defineComponent({
  props: {
    /* 抽屉可见 */
    isVisible: {
      type: Boolean,
      default: false,
    },
    /* 抽屉标题 */
    drawerTitle: {
      type: String,
      default: "",
    },
    /* 抽屉宽度 */
    drawerWidth: {
      type: [Number, String],
      default: "96%",
    },
     /* 是否全屏 */
    isFullScreen: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["on-close"],
  setup(props, { emit }) {
    let drawerVisible = ref(false);
    const { isVisible } = toRefs(props);
    // 弹窗初始状态
    drawerVisible.value = isVisible.value;
    // 监听抽屉变化
    watch(isVisible, (newProps) => {
      drawerVisible.value = newProps;
    });
    function handleClose() {
      drawerVisible.value = false;
      emit("on-close");
    }
    return {
      drawerVisible,
      handleClose,
    };
  },
});
</script>
<style lang="less" scoped>

</style>
