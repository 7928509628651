<template>
    <div class="drag_dialog">
        <div class="drag_dialog_top flex-sb" @mousedown="move">
            <div class="title">
                {{title}}
            </div>
            <div class="iconfont icon-cuocha_kuai hover_opacity"
                style="color:#00ECF7"
                @click="close"
            ></div>
        </div>
        <slot></slot>
    </div>
</template>

<script setup>
import { t } from '../../languages';
import { ref,toRefs } from 'vue'
    // 以下的数字全部是rem单位 
  const props =  defineProps({
        right:{
            type:Number,
            default:60
        },
        top:{
            type:Number,
            default:6.25
        },
        width:{
            type:Number,
            default:21.25
        },
        height:{
            type:Number,
        },
        title:{
            type:String,
            default:t('216')
        },
        visible:{
            type:Boolean,
            default:false
        },
        //是否可拖拽
        drag:{
            type:Boolean,
            default:true
        },
        //title位置 left center right
        titlePosition:{
            type:String,
            default:'left'
        },
        isTrain:{
            type:Boolean,
            default:false
        }
    })
    const { right,top,width,height,title,visible,drag,titlePosition,isTrain } = toRefs(props) 
    const dialogWidth = ref(width.value + 'rem')
    const dialogHeight = ref(height.value + 'rem')
    const positionRight = ref(isTrain.value ? right.value : right.value+ 'rem')
    const positionTop = ref(top.value + 'rem')
   
    
    const emit = defineEmits(['update:visible', 'close'])
    const close = () => {
        emit('close')
        emit('update:visible',false)
    }

    const move = (e) => {
        if(drag.value === false){
            return
        }
        let dialog = e.currentTarget.parentElement;//获取dom
        let disX = e.clientX - dialog.offsetLeft;
        let disY = e.clientY - dialog.offsetTop;
        document.onmousemove = (e)=>{
                let left = e.clientX - disX; //元素离左边的距离
                let top = e.clientY - disY; //元素离上边的距离
                left < 0 ? left = 0 : null;//不能超出左边界
                top < 0 ? top = 0 : null;//不能超出上边界
                let fullWidth = document.body.clientWidth - 89
                let fullHeight = document.body.clientHeight - 89
                //不能超出右边界
                if (left >= fullWidth - dialog.clientWidth) {
					left = fullWidth - dialog.clientWidth
				}
                //不能超出下边界
                 if (top >= fullHeight - dialog.clientHeight) {
					top = fullHeight - dialog.clientHeight
				}
                //移动当前元素
                dialog.style.left = left + 'px';
                dialog.style.top = top + 'px';
        }
        document.onmouseup = (e) => {
                document.onmousemove = null;
                document.onmouseup = null;
            };
    }

</script>


<style lang="less" scoped>
    .drag_dialog{
        width: v-bind(dialogWidth);
        height: v-bind(dialogHeight);
        background: rgba(11, 79, 122, 0.9);
        position: absolute;
        top: v-bind(positionTop);
        right: v-bind(positionRight);
        z-index: 99;
       
        &_top{
            height: 2.4375rem;
            background:#0E99C1;
            padding: 0 .875rem;
             cursor: move;
            .title{
                color: #FFFFFF;
                text-shadow: 0px 2px 6px #091C3B;
                font-size: 1rem;
                width: 100%;
                text-align: v-bind(titlePosition);
            }
        }
    }

</style>