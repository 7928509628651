import { t } from '../languages';
import flvjs from "flv.js";
let player; 
//flv.js初始化方法
export function initVideo(myVideo, flowAddr,type) {
    player = flvjs.createPlayer({
        type: type,
        hasVideo:true,
        hasAudio:false,
        url: flowAddr,
    });
    // 播放视频用的video标签
    player.attachMediaElement(myVideo);
    player.load();
    let k = myVideo.getAttribute("ishas");
    if (k === null) {
        myVideo.setAttribute("ishas", true);
        myVideo.addEventListener(
            "waiting",
            function () {
                // do nothing
            },
            false
        );
        myVideo.addEventListener(
            "playing",
            function () {
                // do nothing
            },
            false
        );
    }
}