<template>
  <div class="homepage">
    <!-- 头部 -->
    <Header />
    <div
      :class="
        $route.matched[1].path == '/commandcenterIndex'
          ? ''
          : $route.matched[1].path == '/executeRecord'
          ? ''
          : 'homepages'
      "
    >
      <!-- 内容 -->
      <Content />
      <!-- 左侧按钮 -->
      <Menus />
    </div>
    <Tip v-if="tipDialogShow" v-model:isVisible="tipDialogShow"> </Tip>
  </div>
</template>

<script setup>
import { t } from '../../languages';
import { ref } from "vue";
import Header from "./children/header.vue";
import Menus from "./children/meus.vue";
import Content from "./children/content.vue";
import Tip from "../../views/command-center/components/tip.vue";

const userInfo = ref(JSON.parse(localStorage.getItem("userInfo")));
const isFirstSign = userInfo.value ? userInfo.value.isFirstSign : false;
const tipDialogShow = ref(isFirstSign);
</script>

<style lang="less">
.homepage {
  height: 100%;
  min-height: 52.5rem;
  position: relative;
  overflow: hidden;
  background-image: url("../../asset/img/h_bg.png");
  background-repeat: no-repeat;
  background-position: 0px 0px;
  background-size: 100% 100%;
  .homepages {
    width: 58.4375rem;
    height: 58.4375rem;
    background-image: url("../../asset/img/map_1.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    margin: 1.125rem auto;
  }
}
</style>