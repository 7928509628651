<template>
  <ele-dialog
    :isVisible="isVisible"
    :dialogTitle="t('130')"
    :dialogWidth="'370px'"
    dialogTop="228px"
    @on-cancel="$emit('update:isVisible', false)"
    @jumpLogin="jumpLogin"
    @on-add="save"
    :flag="false"
  >
    <p style="margin-bottom:10px;color:red">{{ t('1045') }}</p>
    <el-form
      ref="passFormRef"
      :model="state.passForm"
      :rules="passFormRules"
      label-width="80px"
      label-position="left"
    >
      <el-form-item :label="t('1046')" prop="password" class="fromItem">
        <el-input
          :type="visiblePass ? 'input' : 'password'"
          class="common-input"
          v-model.trim="state.passForm.password"
          :placeholder="t('1047')"
        >
          <template #suffix>
            <span
              class="iconfont icon-xianshikejian hover_opacity"
              v-show="visiblePass"
              @click="visiblePass = !visiblePass"
            ></span>
            <span
              class="iconfont icon-yincangbukejian hover_opacity"
              v-show="!visiblePass"
              @click="visiblePass = !visiblePass"
            ></span>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item :label="t('854')" prop="newPassword" class="fromItem">
        <el-input
          :type="newPass ? 'input' : 'password'"
          class="common-input"
          v-model.trim="state.passForm.newPassword"
          :placeholder="t('1048')"
          @keyup="
            state.passForm.newPassword = state.passForm.newPassword.replace(
              /[ ]/g,
              ''
            )
          "
        >
          <template #suffix>
            <span
              class="iconfont icon-xianshikejian hover_opacity"
              v-show="newPass"
              @click="newPass = !newPass"
            ></span>
            <span
              class="iconfont icon-yincangbukejian hover_opacity"
              v-show="!newPass"
              @click="newPass = !newPass"
            ></span>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item :label="t('1049')" prop="repassword" class="fromItem">
        <el-input
          :type="rewritePass ? 'input' : 'password'"
          class="common-input"
          v-model.trim="state.passForm.repassword"
          :placeholder="t('1050')"
          @keyup="
            state.passForm.repassword = state.passForm.repassword.replace(
              /[ ]/g,
              ''
            )
          "
        >
          <template #suffix>
            <span
              class="iconfont icon-xianshikejian hover_opacity"
              v-show="rewritePass"
              @click="rewritePass = !rewritePass"
            ></span>
            <span
              class="iconfont icon-yincangbukejian hover_opacity"
              v-show="!rewritePass"
              @click="rewritePass = !rewritePass"
            ></span>
          </template>
        </el-input>
      </el-form-item>
    </el-form>
  </ele-dialog>
</template>
<script setup>
import { t } from '../../../languages';
import { reactive, ref } from "vue";
import { router } from "@/router";
import { store } from "@/store";
// import userService from "@/service/user";
import JSEncrypt from "jsencrypt";
import user from "@/network/user";
import { ElMessage } from "element-plus";
const props = defineProps({
  isVisible: {
    type: Boolean,
    default: false,
  },
});
const visiblePass = ref(false); //原密码是否可见
const newPass = ref(false); //新密码是否可见
const rewritePass = ref(false); //确认密码是否可见
const equalToPassword = (rule, value, callback) => {
  if (state.passForm.newPassword !== value) {
    callback(new Error(t("1051")));
  } else {
    callback();
  }
};
const newToOriginal = (rule, value, callback) => {
  if (state.passForm.password === value) {
    callback(new Error(t("1052")));
  } else {
    callback();
  }
};

const state = reactive({
  passForm: {
    password: "",
    newPassword: "",
    repassword: "",
  },
});

const passFormRules = reactive({
  password: [
    {
      required: true,
      message: t("1053"),
      trigger: "blur",
    },
  ],
  newPassword: [
    {
      required: true,
      message: t("1054"),
      trigger: "blur",
    },
    {
      min: 8,
      max: 20,
      message: t("1055"),
      trigger: "blur",
    },
    { required: true, validator: newToOriginal, trigger: "blur" },
  ],
  repassword: [
    {
      required: true,
      message: t("1056"),
      trigger: "blur",
    },
    { required: true, validator: equalToPassword, trigger: "blur" },
  ],
});

const jumpLogin = () => {
  router.push("/");
  localStorage.removeItem("token");
};

let publicKey = "";
/* 获取公钥 */
async function getPublicKey() {
  const res = await user.getPublicKey();
  if (res) {
    publicKey = res.resultData;

  }
}
getPublicKey();

const passFormRef = ref(null);
const userId = ref(JSON.parse(localStorage.getItem('userInfo')))
const save = () => {
  passFormRef.value.validate(async (bool) => {
    if (bool) {
      console.log('success')
      const encryptor = new JSEncrypt();
      console.log(encryptor);
      if (!publicKey) return;
      encryptor.setPublicKey(publicKey); // 设置公钥
      const passwordEncrypt = encryptor.encrypt(state.passForm.password); // 对需要加密的数据进行加密
      const newPasswordEncrypt = encryptor.encrypt(state.passForm.newPassword); // 对需要加密的数据进行加密
      // const repasswordEncrypt = encryptor.encrypt(state.passForm.repassword); // 对需要加密的数据进行加密
      if (!newPasswordEncrypt || !passwordEncrypt) return;

      const sendData = {
        memberId:userId.value.id,
        oldPassword: passwordEncrypt,
        newPassword: newPasswordEncrypt,
        // repassword: repasswordEncrypt,
      };

      const res = await user.changePassword(sendData);
      if (res.resultStatus) {
        ElMessage.success(t("1057"));
        state.updatePassShow = false;
        router.push("/");
      }
    } else {
      return false;
    }
  });
};
</script>

<style lang='less' scoped>
:deep(.el-form-item__label){
  color: #fff;
}
</style>