import { t } from '../../languages';
import {
  ElMessage
} from "element-plus";

let websock = null;
let linkTimer = 0; //重试次数
let lastConnectTime = 0;   //上次重试时间

/**
 * 打开通信连接notice_list
 */
const socket = (wsOpenCallback, receiveMess) => {
  let closeFlag = false;

  function openSocketConnect() {
    if (typeof WebSocket === "undefined") {
      ElMessage.error(t('47'));
    } else {
      const wsUrl = `${window.g.imWsUrl}`;
      websock = new WebSocket(wsUrl);
      websock.binaryType = "blob";
      websock.onopen = websocketonopen;
      websock.onclose = websocketclose;
      websock.onerror = websocketonerror;
      websock.onmessage = websocketonmessage;
    }
  }

  /* 连接已开启 */
  function websocketonopen() {
    ElMessage.success(t('67'));
    linkTimer = 0;
    closeFlag = false;
    wsOpenCallback();
    // 通知开启连接
    // emitter.emit('ws-open');
  }

  /* 重连 */
  function reconnection() {
    const now = new Date().valueOf();
    // lastConnectTime控制重连间隔
    if (linkTimer < 3 && (now - lastConnectTime >= 6000)) {
      linkTimer++;
      lastConnectTime = now; 
      setTimeout(() => {
        ElMessage.info(t("52") + linkTimer + t("53"));
        openSocketConnect();
      }, 6000);
    }
  }

  /* websocket关闭 */
  function websocketclose(e) {
    ElMessage.info(t('68'));
    if (e.code === 1006) {
      reconnection();
    }
    console.log(t('55') + e.code + ' ' + e.reason + ' ' + e.wasClean)
  }

  /* websocket错误 */
  function websocketonerror(event) {
    console.log(event)
    // 产生错误已关闭 -- 进行重连
    if (websock.readyState === WebSocket.CLOSED) {
      reconnection();
    }
  }

  /**
   * websocket发送消息
   * @param {* 数据对象} data  Object
   */
  function websocketsend(data) {
    if (websock && websock.readyState === 1) {
      if (!closeFlag) {
        websock.send(JSON.stringify(data));
      }
    } else {
      ElMessage.error(t("49"))
    }
  }


  /* websocket接收消息 -- 在此做消息分发 */
  function websocketonmessage(e) {
    const dataInfo = JSON.parse(e.data);
    // 非关闭状态 -- 接受消息
    if (!closeFlag) {
      receiveMess(dataInfo)
    }
    // emitter.emit('chat-message', dataInfo);
  }

  /* 关闭通信连接 */
  function closeSocketConnect() {
    if (websock) {
      websock.close();
      closeFlag = true;
    }
  }

  return {
    openSocketConnect,
    websocketsend,
    closeSocketConnect
  }
}
export default socket