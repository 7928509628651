import { t } from '../languages';
import request from './http.js';

/**
 * 二次封装请求 -- 减少请求代码量
 * get方法限制params形式参数（即地址栏携带），
 * post、put、delete显示data形式参数（即body请求体）
 * 如需定制请求请使用http.js进行请求
 */
const service = {
  /**
   * 
   * @param {* 地址} url 
   * @param {* 携带参数} params 
   * @returns 
   */
  get({
    url = '',
    params = {},
    timeout = 0,
  } = {}) {
    /* 修改接口请求时间 */
    if(timeout !== 0) {
      timeout = timeout * 60 * 1000
    }
    return new Promise((resolve, reject) => {
      request
        .get(url, {
          params:params,
          timeout:timeout
        })
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          console.log('catch error:', error)
          reject(error)
        })
    })
  },
  /**
   * 
   * @param {* 地址} url 
   * @param {* 携带数据} data 
   * @param {* 是否表单类型数据} isFormData 
   * @param {* 配置} config 
   * @returns 
   */
  post({
    url = '',
    data = {},
    isFormData = false,
    config = {},
  } = {}) {

    /* 处理 formData形式请求的数据*/
    if (isFormData === true && data instanceof Object) {
      const formData = new FormData(); //formData类型数据
      for (const key in data) {
        formData.append(key, data[key])
      }
      data = formData;
      // 修改请求头类型为form-data
      config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    }

    return new Promise((resolve, reject) => {
      request
        .post(url, data, config)
        .then(res => resolve(res))
        .catch(error => {
          reject(error)
          console.log('catch-error:', error)
        })
    })
  },
  /**
   * 
   * @param {* 地址} url 
   * @param {* 携带数据} data 
   * @param {* 配置} config 
   * @returns 
   */
  put({
    url = '',
    data = {},
    config = {},
  } = {}) {
    return new Promise(resolve => {
      request
        .put(url, data, config)
        .then(res => resolve(res))
        .catch(error => {
          console.log('catch-error:', error)
        })
    })
  },
  /**
   * 
   * @param {* 地址} url 
   * @param {* 携带数据} data 
   * @param {* 配置} config 
   * @returns 
   */
  delete({
    url = '',
    data = {},
    config = {},
  } = {}) {
    return new Promise(resolve => {
      request
        .delete(url, {
          data: data
        }, config)
        .then(res => resolve(res))
        .catch(error => {
          console.log('catch-error:', error)
        })
    })
  },

   
  /**
   * 自定义请求时间 -- post请求
   * @param {* 地址} url 
   * @param {* 携带数据} data 
   * @param {* 是否表单类型数据} isFormData 
   * @param {* 请求时间/分钟} timeout
   * @param {* 配置} config 
   * @returns 
   */
   longPost({
      url = '',
      data = {},
      isFormData = false,
      timeout = 0,
      config = {},
    } = {}) {
  
      /* 处理 formData形式请求的数据*/
      if (isFormData === true && data instanceof Object) {
        const formData = new FormData(); //formData类型数据
        for (const key in data) {
          formData.append(key, data[key])
        }
        data = formData;
        // 修改请求头类型为form-data
        config = {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      }
  
      /* 修改接口请求时间 */
      if(timeout !== 0) {
        timeout = timeout * 60 * 1000
        config = Object.assign(config,{timeout:timeout})
      }
  
      return new Promise((resolve, reject) => {
        request.post(url, data, config)
          .then(res => resolve(res))
          .catch(error => {
            reject(error)
            console.log('catch-error:', error)
          })
      })
    },

}

export default service

/* 
  使用方法
  return service.get({
    url: `/task/v1/tasks`,
    params
  })
*/