import { t } from '../languages';
import request from "./http.js";
import service from "./service.js";

const chat = {
    // 查询Im用户是否存在
    // queryExistImUser: (userId) => {
    //     return request({
    //         url: `/api/v1/queryExistImUser`,
    //         method: "post",
    //         params: {
    //             userId: userId,
    //         }
    //     });
    // },
    //新建IM用户
    saveImUser: (data) => {
        return service.post({
            url: `/api/v1/loginUser`,
            data
        });
    },
    //查询所有的群组信息
    queryAllGroup: (data) => {
        return service.post({
            url: `/api/v1/getAllGroup`,
            data
        });
    },
    //通过群id获取历史聊天记录信息
    queryGroupChats: (id,num) => {
        return request({
            url: `/api/v1/query/groupChats/${id}`,
            method: "get",
            params: {
                pageNum: num,
            }
        });
    },
    //查询群组所有成员
    getAllUsers: (data) => {
        return service.post({
            url: `/api/v1/getAllUsers`,
            data
        });
    },
    //查询im在线用户
    onlineInquiry: (groupId) => {
        return request({
            url: `/api/v1/onlineInquiry`,
            method: "post",
            params: {
                groupId: groupId,
            }
        });
    },
    //查询im用户详情
    getUserInfoById: (userId) => {
        return request({
            url: `/api/v1/getUserInfoById`,
            method: "post",
            params: {
                userId: userId,
            }
        });
    },
    // 查询群公告
    getNotice: (groupId) => {
        return service.get({
            url: `/api/v1/query/announcements/${groupId}`
        });
    },
    // 上传图片
    upLoadImg: (data) => {
        return service.post({
            url: `/api/v1/imChat/upload`,
            data
        });
    }
}
export default chat;