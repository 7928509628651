<template>
  <div class="footer_content">
    <div class="footer_left">
      <span>{{ t('1152') }}：</span>
    </div>
    <div class="footer_right">
      <el-scrollbar>
        <div style="display: flex">
          <el-tag
            v-for="(i, index) in dataList"
            :style="
              videoData.includes(i.id)
                ? 'background:#2878ff;color:#fff'
                : 'background:transparent'
            "
            :class="
              videoData.includes(i.id)
                ? ''
                : 'hover_opacity'
            "
            :key="i.id"
            :ref="setBoxRef"
            type="success"
            @click="changeVideo(i, index)"
            closable
            @close="handleClose(i)"
            class="scrollbar-demo-item "
          >
            <el-tooltip
              class="box-item"
              effect="dark"
              :content="i.liveName"
              :disabled="i.liveName.length < 7"
              placement="top-start"
            >
              {{ i.liveName }}
            </el-tooltip>
          </el-tag>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import { t } from '../../../languages';
import {
  defineComponent,
  onMounted,
  reactive,
  toRefs,
  watch,
  ref,
  nextTick,
} from "vue";
import emitter from "../../../utils/mitt";
export default defineComponent({
  props: {
    cellCount: {
      type: Number,
      default: 4,
    },
    videoData: {
      type: Array,
      default: function () {
        return [];
      },
    },
    startData: {
      type: Array,
      default: function () {
        return [];
      },
    },
    videoDataList: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  emits: ["queryTag", "changeVideoPlay", "changeAirport"],
  setup(props, { emit }) {
    const { videoData, startData, videoDataList, cellCount } = toRefs(props);
    const boxRefs = ref([]);
    const setBoxRef = (el) => {
      if (el) {
        boxRefs.value.push(el);
      }
    };
    const state = reactive({
      dialogTableVisible: false,
      dataList: [],
      arrIndex: "",
      // videoData1:[]
    });

    watch(startData, (newVal) => {
      state.dataList = newVal;
    });
    const methods = reactive({
      // 点击切换到视频里
      changeVideo(i, index) {
        if (cellCount.value === 4) {
          let ids = [];
          videoDataList.value.forEach((e) => {
            ids.push(e.id);
          });
          if (!ids.includes(i.id)) {
            emit("changeVideoPlay", i);
            let arr = [];
            arr.push(i);
            arr.push(videoDataList.value[3]);
            emitter.emit("createPlayerFooter", arr);
          }
        } else if (cellCount.value === 8) {
          let ids = [];
          videoDataList.value.forEach((e) => {
            ids.push(e.id);
          });
          if (!ids.includes(i.id)) {
            let arr = [];
            arr.push(i);
            arr.push(videoDataList.value[7]);
            emitter.emit("createPlayerFooter", arr);
            emit("changeVideoPlay", i);
          }
        }
      },
      //关闭标签
      handleClose(tag) {
        emitter.emit("changeStatus", tag);
        let temoData = JSON.parse(sessionStorage.getItem("streamingList"));
        temoData.forEach((e) => {
          e.liveInfoList.forEach((item) => {
            if (item.id === tag.id) {
              item.isAdd = false;
            }
          });
        });
        let temoDataUav = JSON.parse(
          sessionStorage.getItem("streamingListUav")
        );
        if (temoDataUav) {
          temoDataUav.forEach((e) => {
            e.liveInfoList.forEach((item) => {
              if (item.id === tag.id) {
                item.isAdd = false;
              }
            });
          });
        }
        state.dataList.forEach((e, index) => {
          if (e.id === tag.id) {
            state.dataList.splice(index, 1);
          }
        });
        sessionStorage.setItem("streamingList", JSON.stringify(temoData));
        sessionStorage.setItem("streamingListUav", JSON.stringify(temoDataUav));
        let tempData = JSON.parse(sessionStorage.getItem("videoArr"));
        if (tempData) {
          tempData.forEach((e, index) => {
            if (e === tag.id) {
              tempData.splice(index, 1);
            }
          });
        }
        sessionStorage.setItem("videoArr", JSON.stringify(tempData));
        let tempDataUav = JSON.parse(sessionStorage.getItem("videoArrUav"));
        if (tempDataUav) {
          tempDataUav.forEach((e, index) => {
            if (e === tag.id) {
              tempDataUav.splice(index, 1);
            }
          });
          sessionStorage.setItem("videoArrUav", JSON.stringify(tempDataUav));
        }

        emit("queryTag", state.dataList);
        emit("changeAirport", tag.id);
        emitter.emit("destoryJes", tag.id);
      },
      onCancel() {
        console.log("ooooo");
      },
    });

    return {
      ...toRefs(state),
      ...toRefs(methods),
      setBoxRef,
      //   elForms
    };
  },
});
</script>

<style lang="less" scoped>
.footer_content {
  width: 100%;
  height: 100%;
  background: #00203e;
  display: flex;
  .footer_left {
    width: 11.846rem;
    color: #6e777e;
    font-size: 1.0769rem;
    text-align: center;
    height: 3.692rem;
    line-height: 3.992rem;
  }
  .footer_right {
    width: calc(100% - 12.846rem);
    /deep/.el-scrollbar {
      width: 100%;
    }
    /deep/.el-tag.is-closable {
      width: 9rem;
      background: transparent;
      border: 1px solid #197ad7;
      color: #197ad7;
      position: relative;
      margin: 0.923rem 0px;
      font-size: 14;
      margin-left: 1rem;
      margin-right: 1rem;
      height: 1.8461rem;
      flex-shrink: 0;
    }
    /deep/.el-tag .el-tag__close {
      margin-left: 16px;
      color: #fff;
      background: #197ad7;
      position: absolute;
      right: -0.45rem;
      bottom: 1.2rem;
    }
    /deep/.el-tag__content {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    /deep/.el-tag__content {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
