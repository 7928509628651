<template>
  <div class="dialog_box">
    <el-dialog
      v-model="dialogTableVisible"
      :before-close="onCancel"
      :custom-class="isCenter ? 'center_dialog common-dialog' : 'common-dialog'"
      :append-to-body="false"
      :destroy-on-close="true"
      width="100%"
      :show-close="false"
    >
      <div class="content">
        <div class="left">
          <div class="header">
            <div style="width: calc(100% - 5.69rem)">
              <el-button type="primary" @click="onCancel">{{ t('408') }}</el-button>
            </div>
            <div class="icon_con hover_opacity">
              <el-tooltip
                class="box-item"
                popper-class="codeOutToolTip"
                placement="left"
              >
                <template #content>
                  <span>{{ t('645') }}</span>
                </template>
                <span
                  class="iconfont icon-a-7Dsifenping"
                  :style="{ color: cellCount == 4 ? '#23a3ec' : '#fff' }"
                  @click="changeLayout(4)"
                ></span>
              </el-tooltip>
            </div>
            <div class="icon_con hover_opacity">
              <el-tooltip
                class="box-item"
                popper-class="codeOutToolTip"
                placement="left"
              >
                <template #content>
                  <span>{{ t('646') }}</span>
                </template>
                <span
                  class="iconfont icon-a-7Jyijiaqifenping"
                  :style="{ color: cellCount == 8 ? '#23a3ec' : '#fff' }"
                  @click="changeLayout(8)"
                ></span>
              </el-tooltip>
            </div>
          </div>
          <div class="monitoring">
            <HwCellPlayer
              :cellCount="cellCount"
              :startData="startData"
              :videoData="videoData"
              :videoDataList="videoDataList"
              @closeVideo="closeVideo"
              @smallToBig="smallToBig"
            ></HwCellPlayer>
          </div>
          <div class="footer">
            <footerList
              :cellCount="cellCount"
              :startData="startData"
              :videoData="videoData"
              @queryTag="queryTag"
              :videoDataList="videoDataList"
              @changeAirport="changeAirport"
              @changeVideoPlay="changeVideoPlay"
            ></footerList>
          </div>
        </div>
        <div class="right">
          <streamingVideo
            :cellCount="cellCount"
            :steamingId="steamingId"
            @queryStartData="queryStartData"
            @queryVideoData="queryVideoData"
          ></streamingVideo>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { t } from '../../languages';
import {
  defineComponent,
  onBeforeMount,
  onUnmounted,
  reactive,
  toRefs,
  watch,
  ref,
  nextTick,
} from "vue";
import HwCellPlayer from "./components/HwCellPlayer.vue";
import streamingVideo from "./components/streamingVideo.vue";
import footerList from "./components/footerList.vue";
import device from "@/network/device";
import { ElMessage } from "element-plus";
import emitter from "../../utils/mitt";
export default defineComponent({
  components: {
    HwCellPlayer,
    streamingVideo,
    footerList,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    /* 是否居中 */
    isCenter: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["closeIsVisible"],
  setup(props, { emit }) {
    const { isVisible } = toRefs(props);
    // const {  } = toRefs(props);
    const state = reactive({
      dialogTableVisible: false,
      cellCount: 4,
      videoData: [],
      startData: [],
      videoOtherData: [],
      steamingId: "",
      videoDataList: [],
    });
    watch(isVisible, (newVal) => {
      state.dialogTableVisible = newVal;
    });
    const methods = reactive({
      //8分布局时  点击切换到大屏
      smallToBig(index, data) {
        [state.videoDataList[0], state.videoDataList[index]] = [
          state.videoDataList[index],
          state.videoDataList[0],
        ];
      },
      //点击视频标签  切换播放
      changeVideoPlay(data) {
        if (state.cellCount === 4) {
          state.videoDataList = [];
          state.videoData = [];
          if (state.startData.length >= 5) {
            state.startData.forEach((e, index, arr) => {
              if (index < 4) {
                state.videoDataList.push(arr[index]);
              }
            });
            state.videoDataList[3] = data;
          } else {
            state.videoDataList = state.startData;
          }
          state.videoDataList.forEach((e) => {
            state.videoData.push(e.id);
          });
        } else {
          state.videoDataList = [];
          state.videoData = [];
          if (state.startData.length >= 9) {
            state.startData.forEach((e, index, arr) => {
              if (index < 8) {
                state.videoDataList.push(arr[index]);
              }
            });
            state.videoDataList[7] = data;
          } else {
            state.videoDataList = state.startData;
          }
          state.videoDataList.forEach((e) => {
            state.videoData.push(e.id);
          });
        }
      },
      closeVideo(data) {
        state.startData.forEach((e, index) => {
          if (e.id === data.id) {
            state.startData.splice(index, 1);
            state.steamingId = data.id;
          }
        });
        if (state.cellCount === 4) {
          state.videoDataList = [];
          state.videoData = [];
          if (state.startData.length >= 5) {
            state.startData.forEach((e, index, arr) => {
              if (index < 4) {
                state.videoDataList.push(arr[index]);
              }
            });
          } else {
            state.videoDataList = state.startData;
          }
          state.videoDataList.forEach((e) => {
            state.videoData.push(e.id);
          });
        } else {
          state.videoDataList = [];
          state.videoData = [];
          if (state.startData.length >= 9) {
            state.startData.forEach((e, index, arr) => {
              if (index < 8) {
                state.videoDataList.push(arr[index]);
              }
            });
          } else {
            state.videoDataList = state.startData;
          }
          state.videoDataList.forEach((e) => {
            state.videoData.push(e.id);
          });
        }
      },
      //改变机库状态
      changeAirport(data) {
        state.steamingId = data;
      },
      queryTag(data) {
        if (state.cellCount === 4) {
          state.videoDataList = [];
          state.videoData = [];
          if (data.length >= 5) {
            data.forEach((e, index, arr) => {
              if (index < 4) {
                state.videoDataList.push(arr[index]);
              }
            });
          } else {
            state.videoDataList = data;
          }
          state.videoDataList.forEach((e) => {
            state.videoData.push(e.id);
          });
        } else {
          state.videoDataList = [];
          state.videoData = [];
          if (data.length >= 9) {
            data.forEach((e, index, arr) => {
              if (index < 8) {
                state.videoDataList.push(arr[index]);
              }
            });
          } else {
            state.videoDataList = data;
          }
          state.videoDataList.forEach((e) => {
            state.videoData.push(e.id);
          });
        }
      },
      //获取播放列表
      queryVideoData(data) {
        // state.videoData = data;
      },
      //获取开启列表
      queryStartData(data, type) {
        // console.log(data);
        state.startData = data;
        let arr = state.startData.filter((e) => {
          if (type === "airport") {
            if (JSON.parse(sessionStorage.getItem("videoArr")).includes(e.id)) {
              if (e.playPath.split(":")[0] !== "ws") {
                // let middle = e.playPath.replace('rtmp','ws')
                // if (middle.lastIndexOf(":") < 4) {
                //   const urlAdress = middle.concat(".flv");
                //   e.liveRtmpPath = urlAdress
                //   e.flag = 0
                // }
                // else{
                //   const urlAdress = middle
                //   .replace(
                //     middle.split(":")[2].split("/")[0],
                //     e.rtcApi + "/jessica"
                //   )
                //   .concat(".flv");
                //   e.liveRtmpPath = urlAdress
                e.flag = 0;
                // e.liveRtmpPath = 'http://120.76.178.0:1936/live/NEST0000001_in.flv'
              }
              e.liveRtmpPath = e.playPath;
              return e;
            }
          } else {
            if (
              JSON.parse(sessionStorage.getItem("videoArrUav")).includes(e.id)
            ) {
              if (e.liveRtmpPath.split(":")[0] !== "ws") {
                let middle = e.liveRtmpPath.replace("rtmp", "ws");
                const urlAdress = middle
                  .replace(
                    middle.split(":")[2].split("/")[0],
                    e.rtcApi + "/jessica"
                  )
                  .concat(".flv");
                e.liveRtmpPath = urlAdress;
                // e.liveRtmpPath = 'http://120.76.178.0:1936/live/NEST0000001_in.flv'
                return e;
              } else {
                return e;
              }
            }
          }
        });
        if (state.cellCount === 4) {
          state.videoDataList = [];
          state.videoData = [];
          if (arr.length >= 5) {
            arr.forEach((e, index, arr) => {
              if (index < 4) {
                state.videoDataList.push(arr[index]);
              }
            });
          } else {
            state.videoDataList = arr;
          }
          state.videoDataList.forEach((e) => {
            state.videoData.push(e.id);
          });
        } else {
          state.videoDataList = [];
          state.videoData = [];
          if (data.length >= 9) {
            data.forEach((e, index, arr) => {
              if (index < 8) {
                state.videoDataList.push(arr[index]);
              }
            });
          } else {
            state.videoDataList = data;
          }
          state.videoDataList.forEach((e) => {
            state.videoData.push(e.id);
          });
        }
      },
      changeLayout(data) {
        state.cellCount = data;
        if (state.cellCount === 4) {
          state.videoDataList = [];
          state.videoData = [];
          if (state.startData.length >= 5) {
            state.startData.forEach((e, index, arr) => {
              if (index < 4) {
                state.videoDataList.push(arr[index]);
              }
            });
          } else {
            state.videoDataList = state.startData;
          }
          state.videoDataList.forEach((e) => {
            state.videoData.push(e.id);
          });
        } else {
          state.videoDataList = [];
          state.videoData = [];
          if (state.startData.length >= 9) {
            state.startData.forEach((e, index, arr) => {
              if (index < 8) {
                state.videoDataList.push(arr[index]);
              }
            });
          } else {
            state.videoDataList = state.startData;
          }
          state.videoDataList.forEach((e) => {
            state.videoData.push(e.id);
          });
        }
      },
      onCancel() {
        emit("closeIsVisible");
        emitter.emit("startJessibuca");
        state.dialogTableVisible = false;
        // console.log(state.dialogTableVisible);
      },
    });

    return {
      ...toRefs(state),
      ...toRefs(methods),
      //   elForms
    };
  },
});
</script>

<style lang="less" scoped>
.content {
  display: flex;
  height: 100%;
  width: 100%;
}
.left {
  flex: 1;
  width: calc(100% - 24.692307rem);
  height: 100%;
  padding: 0px 1.5384rem 0.7692rem 0.7692rem;
  .header {
    height: 3.69rem;
    width: 100%;
    display: flex;
    background: #00203e;
    /deep/.el-button:not(.is-text) {
      height: 3.69rem;
      width: 8.153rem;
      font-size: 1.23rem;
      background: #23a3ec;
      border-radius: 0px;
    }
    .icon_con {
      display: inline-block;
      height: 2.307rem;
      line-height: 2.307rem;
      text-align: center;
      margin: auto 0px;
      margin-right: 1.7692rem;
      vertical-align: middle;
    }
    .iconfont {
      font-size: 1.5rem;
    }
  }
  .monitoring {
    height: calc(100% - 7.384rem);
    width: 100%;
  }
  .footer {
    height: 3.69rem;
    width: 100%;
  }
}
.right {
  width: 24.692307rem;
}
@media screen and (max-width: 1920px) {
  .right {
    height: 98.63%;
  }
}
@media screen and (max-width: 1536px) {
  .right {
    height: 100%;
    padding-bottom: 0.7692rem;
  }
}
.confirm_btn {
  margin-left: 2rem;
}
.dialog_box :deep(.common-dialog) {
  height: 100%;
  background: #001327;
  // border-radius: 8px;
  padding: 0px 0.769rem;
  margin: 0;
  .el-dialog__header {
    padding: 0px;

    margin-right: 0;

    .el-dialog__title {
      //   font-size: 22px;
      //   font-family: Source Han Sans CN;
      //   font-weight: 400;
      //   color: @heightLight;
    }

    .el-dialog__headerbtn {
      //   top: 22px;

      .el-dialog__close {
        color: @heightLight;
        font-size: 18px;
      }
    }
  }
  .el-dialog__body {
    padding: 0px;
    color: #fff;
    height: 100%;
  }
}

/* 居中弹窗 */
/deep/.el-overlay-dialog {
  height: 100%;
  padding-top: 5.25rem;
  overflow: visible;
}
/deep/ .dialog_box .common-dialog {
  margin: 0;
}
</style>
