<template>
  <div class="dialog_box">
    <el-dialog
      :title="dialogTitle"
      v-model="dialogVisible"
      :width="dialogWidth"
      :custom-class="isCenter ? 'center_dialog common-dialog' : 'common-dialog'"
      :append-to-body="false"
      lock-scroll
      :top="dialogTop"
      :before-close="onCancel"
      destroy-on-close
      ref="eleDialog"
      :close-on-click-modal="flag"
    >
      <slot></slot>
      <template #footer v-if="hasFooter" style="z-index:99">
        <span class="dialog-footer">
          <el-button
            class="common-cancel-btn"
            @click="onCancel"
            size="large"
            style="
              background: #001c31;
              border-color: #00f5ff;
              color: #ffffff;
              padding: 0 30px;
            "
            >{{ t('217') }}</el-button
          >
          <el-button
            class="common-confirm-btn confirm_btn"
            @click="onDetermine"
            size="large"
            style="
              background: #00959b;
              border-color: #00f5ff;
              color: #ffffff;
              padding: 0 30px;
            "
            >{{ t('218') }}</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { t } from '../../languages';
import { defineComponent, ref, toRefs, watch } from "vue";
export default defineComponent({
  props: {
    /* 弹窗可见 */
    isVisible: {
      type: Boolean,
      default: false,
    },
    /* 弹窗标题 */
    dialogTitle: {
      type: String,
      default: t("219"),
    },
    /* 弹窗宽度 */
    dialogWidth: {
      type: String,
      default: "30%",
    },
    /* 是否居中 */
    isCenter: {
      type: Boolean,
      default: true,
    },
    /* 距离上边的距离 */
    dialogTop: {
      type: String,
      default: "15vh",
    },
    hasFooter: {
      type: Boolean,
      default: true,
    },
    /* 新增/编辑 */
    isEdit: {
      type: Boolean,
      default: false,
    },
     //是否可以点击modal层关闭弹窗
    flag:{
      type: Boolean,
      default: true,
    }
  },
  emits: ["on-cancel", "on-add"],
  setup(props, { emit }) {
    // console.info(props.isVisible);
    let dialogVisible = ref(false);
    const { isVisible } = toRefs(props);
    // 弹窗初始状态
    dialogVisible.value = isVisible.value;
    watch(isVisible, (newProps) => {
      dialogVisible.value = newProps;
    });

    function onCancel() {
      emit("on-cancel");
       emit("jumpLogin");
      dialogVisible.value = false;
    }

    function onDetermine() {
      emit("on-add");
    }

    return {
      dialogVisible,
      onCancel,
      onDetermine,
    };
  },
});
</script>

<style scoped lang="less">
.confirm_btn {
  margin-left: 26px;
}
.dialog_box :deep(.common-dialog)  {
  background: #004375;
  border-radius: 8px;

  .el-dialog__header {
    padding: 18px 20px;
    border-bottom: 1px solid #02c9d1;
    margin-right: 0;

    .el-dialog__title {
      font-size: 22px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: @heightLight;
    }

    .el-dialog__headerbtn {
      top: 22px;

      .el-dialog__close {
        color: @heightLight;
        font-size: 18px;
      }
    }
  }
  .el-dialog__body {
    padding: 20px 20px 0 20px;
    color: #fff;
  }
}

/* 居中弹窗 */
.dialog_box /deep/ .center_dialog {
  margin-top: 0 !important;
  top: 50%;
  transform: translateY(-50%);
}
</style>