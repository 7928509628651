import { t } from '../languages';
import service from "./service.js";

const user = {
  /* 登录 */
  login: (data) => {
    return service.post({
      url: "/auth/v1/login",
      data
    });
  },
  /* 退出登录 */
  loginOut: () => {
    return service.post({
      url: "/auth/v1/logout"
    })
  },

  /* 检查账号存在 */
  checkExist: (account) => {
    return service.get({
      url: `/api/v1/show/account/${account}`,
    });
  },
  /* 获取公钥 */
  getPublicKey: () => {
    return service.get({
      url: `/api/v1/show/account/publicKey`,
    });
  },
  /* 获取飞行证书列表 */
  getCertificateList: (memberId) => {
    return service.get({
      url: `/api/v1/show/certificateList/${memberId}`,
    })
  },

  /* 获取修改的飞行证书 */
  editCertificate: (id) => {
    return service.get({
      url: `/api/v1/show/certificate/${id}`,
    })
  },

  /* 添加/证书 */
  addCertificate: (data) => {
    return service.post({
      url: "/api/v1/add/certificate",
      data
    })
  },

  /* 保存修改飞行证书 */
  saveCertificate: (data) => {
    return service.put({
      url: "/api/v1/settings/certificate",
      data
    })
  },

  /* 删除飞行证书 */
  deleteCertificate: (id) => {
    return service.delete({
      url: `/api/v1/delete/certificate/${id}`,
    })
  },

  /**
   * 修改用户的密码
   * @param {* 用户id} memberId 
   * @param {* 密码数据} data 
   * @returns 
   */
  changePassword: (data) => {
    return service.post({
      url: `/api/v1/settings/member/password`,
      data
    })
  },

  /* 获取用户基本信息 */
  getUserinformation: (memberId) => {
    return service.get({
      url: `/api/v1/show/member/${memberId}`,
    })
  },

  /* 修改用户名 */
  updateUsername: (data) => {
    let paramUrl = `?userId=${data.userId}&username	=${data.username}`
    return service.put({
      url: `/api/v1/settings/member/username${paramUrl}`,
    })
  },

  /* 修改用户手机号 */
  updateUserphone: (data) => {
    let paramUrl = `?phone=${data.phone}&userId	=${data.userId}`
    return service.put({
      url: `/api/v1/settings/member/phone${paramUrl}`,
    })
  },

  /* 修改用户头像 */
  updateUseravatar: (data) => {
    let paramUrl = `?avatar=${data.avatar}&id=${data.id}`
    return service.put({
      url: `/api/v1/settings/member/avatar${paramUrl}`,
    })
  },

  /* 上传用户头像文件 */
  uploadAvatarFile: (data, isFormData) => {
    return service.longPost({
      url: `/api/v1/upload/avatar`,
      data,
      isFormData,
      timeout: 2
    })
  },
}
export default user