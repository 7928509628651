<template>
  <div class="right_content">
    <div class="header">
      <div class="header_text">{{ t('1163') }}</div>
      <div class="header_line"></div>
    </div>
    <div class="search">
      <el-input :placeholder="t('1058')" style="color:#fff" v-model="inputValue" clearable @clear="getStreamingArraySearch" />
      <button class="select" @click="getStreamingArraySearch">
        <el-icon size="1.5rem">
          <Search />
        </el-icon>
      </button>
    </div>
    <div class="tab">
      <el-tabs @tab-change="tabChange" v-model="activeNameTab" :stretch="true" type="border-card">
        <el-tab-pane :label="t('374')" name="AIRPORT">
          <div>
            <el-collapse v-model="activeNameCollapse">
              <el-collapse-item name="1">
                <template #title>
                  <!-- <el-icon style="font-size: 1.269rem;color: #00F5FF;" class="header-icon" >
                                <info-filled />
                  </el-icon>-->
                  <div class="icon_con1">
                    <span
                      style="font-size: 1.2538rem;vertical-align: 0.07142rem;"
                      class="iconfont icon-quanbu"
                    ></span>
                  </div>
                  <div
                    style="margin-left:0.7692rem;color:#00F5FF;font-size: 1rem;"
                  >{{ airportCount }}</div>
                  <div style="margin-left:1.5384rem;font-size: 0.9rem;">{{ airportNum }}{{ t('1164') }}</div>
                </template>
                <div v-if="airportNum !== 0">
                  <el-scrollbar>
                    <el-card v-for="item in airportList" :key="item.deviceId" class="box-card">
                      <template #header>
                        <div class="card-header" style="height:100%">
                          <div class="icon_con">
                            <span style="font-size: 1.415rem;" class="iconfont icon-hezi201"></span>
                          </div>
                          <el-tooltip
                            class="box-item"
                            effect="dark"
                            :content="item.deviceSn"
                            :disabled="item.deviceSn.length<12"
                            placement="bottom-start"
                          >
                          <div
                            style="margin-left: 1rem;color: rgb(0, 236, 247);font-size: 1.0307rem;display: inline-block;display: inline-block;width: 12.1538rem;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;"
                          >{{item.deviceSn}}</div>
                        </el-tooltip>
                          <span
                            v-if="item.onlineStatus===1"
                            style="float:right;color:#06C906;font-size:0.923rem;"
                          >{{ t('521') }}</span>
                          <span
                            v-else-if="item.onlineStatus===0"
                            style="float:right;color:#E52B2B;font-size:0.923rem;"
                          >{{ t('520') }}</span>
                        </div>
                      </template>
                      <div v-if="(item.liveInfoList.length !== 0)?true:false">
                        <div
                          v-for="o in item.liveInfoList"
                          :key="o.id"
                          style="height:2.5rem"
                          class="text item"
                        >
                          <div class="card-header" style="height:100%">
                            <div class="icon_con">
                              <span
                                v-if="(o.liveRtmpPath === '')?false:true"
                                style="color:#12cc12;font-size:1.2307rem;overflow:hidden;"
                                class="iconfont icon-zaixianim"
                              ></span>
                            </div>
                            <el-tooltip
                              class="box-item"
                              effect="dark"
                              :content="o.liveName"
                              :disabled="o.liveName.length<15"
                              placement="bottom-start"
                            >
                            <div
                              style="margin:0px 0.8rem;color:#fff;font-size: 1rem;display: inline-block;height: 2.5rem;max-width:12.35rem;line-height: 2.25rem;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;"
                            >{{o.liveName}}</div>
                          </el-tooltip>
                            <div class="jiankong">
                              <span
                                v-show="(o.isAdd === null || o.isAdd === undefined || o.isAdd === false)?false:true"
                                class="iconfont icon-jiankong1"
                                style="color: #00ECF7;font-size:1rem;"
                              ></span>
                            </div>
                            <div
                              @click="handleVideoList(o)"
                              class="hover_opacity"
                              style="color:#30B4FF;font-size:1rem;float:right;display: inline-block;margin-top: 0.1rem;"
                            >{{ (o.isAdd === null || o.isAdd === undefined || o.isAdd === false)?'添加':'关闭' }}</div>
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="(item.liveInfoList.length === 0)?true:false"
                        style="color:#dee1e6;text-align:center;font-size: 0.9rem;"
                      >{{ t('567') }}</div>
                    </el-card>
                  </el-scrollbar>
                </div>
                <div v-if="airportNum === 0">
                  <div style="text-align:center;padding:1.53846rem;color:#fff">{{ t('223') }}</div>
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
        </el-tab-pane>
        <!-- <el-tab-pane :label="t('165')" name="UAV">
          <div>
            <el-collapse v-model="activeNameCollapse">
              <el-collapse-item name="1">
                <template #title> -->
                  <!-- <el-icon style="font-size: 1.269rem;color: #00F5FF;" class="header-icon" >
                                <info-filled />
                  </el-icon>-->
                  <!-- <div class="icon_con1">
                    <span
                      style="font-size: 1.2538rem;vertical-align: 0.07142rem;"
                      class="iconfont icon-quanbu"
                    ></span>
                  </div>
                  <div
                    style="margin-left:0.7692rem;color:#00F5FF;font-size: 1rem;"
                  >{{ uavCount }}</div>
                  <div style="margin-left:1.5384rem;font-size: 0.9rem;">{{ (uavNum === '')?0:uavNum }}个移动终端</div>
                </template>
                <div v-if="uavNum !== 0">
                  <el-scrollbar>
                    <el-card v-for="item in uavList" :key="item.deviceId" class="box-card">
                      <template #header>
                        <div class="card-header" style="height:100%">
                          <div class="icon_con">
                            <span style="font-size: 1.415rem;" class="iconfont icon-app1"></span>
                          </div>
                          <el-tooltip
                            class="box-item"
                            effect="dark"
                            :content="item.deviceSn"
                            :disabled="item.deviceSn.length<12"
                            placement="bottom-start"
                          >
                          <div
                            style="margin-left: 1rem;color: rgb(0, 236, 247);font-size: 1.0307rem;display: inline-block;display: inline-block;width: 12.1538rem;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;"
                          >{{item.deviceSn}}</div>
                        </el-tooltip>
                          <span
                            style="float:right;color:#06C906;font-size:0.923rem;"
                          >{{(item.onlineStatus===null)?'在线':'离线'}}</span>
                        </div>
                      </template>
                      <div v-if="(item.liveInfoList.length !== 0)?true:false">
                        <div
                          v-for="o in item.liveInfoList"
                          :key="o.id"
                          style="height:2.5rem"
                          class="text item"
                        >
                          <div class="card-header" style="height:100%">
                            <div class="icon_con">
                              <span
                                v-if="(o.liveRtmpPath === '')?false:true"
                                style="color:#12cc12;font-size:1.2307rem;overflow:hidden;"
                                class="iconfont icon-zaixianim"
                              ></span>
                            </div>
                            <el-tooltip
                              class="box-item"
                              effect="dark"
                              :content="o.liveName"
                              :disabled="o.liveName.length<15"
                              placement="bottom-start"
                            >
                            <div
                              style="margin:0px 0.8rem;color:#fff;font-size: 1rem;display: inline-block;height: 2.5rem;line-height: 2.25rem;width: 12.1538rem;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;"
                            >{{o.liveName}}</div>
                          </el-tooltip>
                            <div class="icon_con hover_opacity">
                              <span
                                v-show="(o.liveStatus === 0)?false:true"
                                class="iconfont icon-jiankong"
                              ></span>
                            </div>
                            <div
                              @click="handleVideoListUav(o)"
                              class="hover_opacity"
                              style="color:#30B4FF;font-size:1rem;float:right;display: inline-block;height: 2.5rem;line-height: 2.25rem;"
                            >{{ (o.isAdd === null || o.isAdd === undefined || o.isAdd === false)?'添加':'关闭' }}</div>
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="(item.liveInfoList.length === 0)?true:false"
                        style="color:#dee1e6;text-align:center;font-size: 0.9rem;"
                      >{{ t('567') }}</div>
                    </el-card>
                  </el-scrollbar>
                </div>
                <div v-if="airportNum === 0">
                  <div style="position:absolute;top:4rem;left:0;right:0;text-align:center;padding:1.53846rem;color:#fff">{{ t('223') }}</div>
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
        </el-tab-pane> -->
      </el-tabs>
    </div>
  </div>
</template>
<script>
import { t } from '../../../languages';
import {
  defineComponent,
  onMounted,
  reactive,
  toRefs,
  watch,
  ref,
  nextTick
} from "vue";
import device from "../../../network/device";
import emitter from "../../../utils/mitt";
export default defineComponent({
  props: {
    cellCount: {
      type: Number,
      default: 0
    },
    steamingId: {
      type: String,
      default: ""
    }
  },
  setup(props, { emit }) {
    const { cellCount, steamingId } = toRefs(props);
    const state = reactive({
      inputValue: "",
      activeNameCollapse: "1",
      labelRight: "AIRPORT",
      activeNameTab: "AIRPORT",
      airportCount: "",
      airportNum: "",
      airportList: [],
      uavCount: "",
      uavNum: "",
      uavList: [],
      activeNameVideo: t("209"),
      dataList: [],
      //点击添加的数组  装这里
      videoArr: []
    });
    emitter.on("changeStatus",(data)=>{
      let videoArr = JSON.parse(sessionStorage.getItem("videoArr"))
      let videoArrUav = JSON.parse(sessionStorage.getItem("videoArrUav"))
      if (videoArr === null) {
        methods.changeButtonStateUav(false, data.id);
      }
      else if(videoArr !== null && videoArrUav !== null && videoArrUav.includes(data.id)){
        methods.changeButtonStateUav(false, data.id);
      }
      else if(videoArrUav === null){
        methods.changeButtonState(false, data.id);
      }
      else if(videoArrUav !== null && videoArr !== null && videoArr.includes(data.id)){
        methods.changeButtonState(false, data.id);
      }
    })
    watch(steamingId, newVal => {
      state.airportList.forEach(e => {
        e.liveInfoList.forEach(item => {
          if (item.id === newVal) {
            item.isAdd = false;
          }
        });
      });
    });
    onMounted(() => {
      methods.getStreamingArray();
    });
    const methods = reactive({
      //添加视频
      handleVideoList(dataIn) {
        // if(cellCount === 4){
        if (!dataIn.isAdd) {
          //点击添加 ----生成开启列表   生成播放id列表
          //状态变成true
          methods.changeButtonState(true, dataIn.id);
          let tempData = JSON.parse(sessionStorage.getItem("videoArr"));
          if (tempData !== null && tempData !== undefined) {
            if (!tempData.includes(dataIn.id)) {
              tempData.push(dataIn.id);
              sessionStorage.setItem("videoArr", JSON.stringify(tempData));
            }
          } else {
            sessionStorage.setItem("videoArr", JSON.stringify([dataIn.id]));
          }
          //射回一个播放列表
          emit("queryVideoData", JSON.parse(sessionStorage.getItem("videoArr")));
          let startList = [];
          JSON.parse(sessionStorage.getItem("streamingList")).forEach(e => {
            e.liveInfoList.forEach(item => {
              if (item.isAdd) {
                startList.push(item);
              }
            });
          });
          emit("queryStartData", startList,'airport');
          emitter.emit('addJes',dataIn)
        } else {
          methods.changeButtonState(false, dataIn.id);
          emitter.emit('destoryJes',dataIn.id)
          // 点击关闭
          let tempData = JSON.parse(sessionStorage.getItem("videoArr"));
          tempData.forEach((e, index) => {
            if (e === dataIn.id) {
              tempData.splice(index, 1);
            }
          });
          sessionStorage.setItem("videoArr", JSON.stringify(tempData));
          emit("queryVideoData", JSON.parse(sessionStorage.getItem("videoArr")));
          let startList = [];
          JSON.parse(sessionStorage.getItem("streamingList")).forEach(e => {
            e.liveInfoList.forEach(item => {
              if (item.isAdd) {
                startList.push(item);
              }
            });
          });
          emit("queryStartData", startList,'airport');
        }
      },
      //拼接播放地址
      apliceAddress(liveFlight, port) {
        const middle = liveFlight.replace("rtmp", "ws");
        let videoUrl = middle
          .replace(middle.split(":")[2].split("/")[0], port)
          .concat(".flv");
        return videoUrl;
      },
      //改变按钮状态
      changeButtonState(type, data) {
        state.airportList.forEach(e => {
          e.liveInfoList.forEach(item => {
            if (item.id === data) {
              item.isAdd = type;
            }
          });
        });
        let tempData = JSON.parse(sessionStorage.getItem("streamingList"))
          tempData.forEach(e => {
                e.liveInfoList.forEach(item => {
                  item.deviceSn = e.deviceSn;
                  if(item.id === data){
                    item.isAdd = type;
                    if(type === true){
                      item.isMute = true
                      item.isLuzhi = true
                      item.isPause = true
                      item.isFlv = true
                    }
                  }
                });
              });
        sessionStorage.setItem(
              "streamingList",
              JSON.stringify(tempData)
            );
        // state.airportList.forEach(e => {
        //   e.liveInfoList.forEach(item => {
        //     if (item.id === data) {
        //       item.isAdd = type;
        //       if(type === true){
        //         item.isMute = false
        //         item.isLuzhi = false
        //         item.isPause = false
        //       }
        //     }
        //   });
        // });
        // sessionStorage.setItem(
        //   "streamingList",
        //   JSON.stringify(state.airportList)
        // );
      },
      //添加视频-uav
      handleVideoListUav(dataIn) {
        // if(cellCount === 4){
        if (!dataIn.isAdd) {
          //点击添加 ----生成开启列表   生成播放id列表
          //状态变成true
          methods.changeButtonStateUav(true, dataIn.id);
          let tempData = JSON.parse(sessionStorage.getItem("videoArrUav"));
          if (tempData !== null && tempData !== undefined) {
            if (!tempData.includes(dataIn.id)) {
              tempData.push(dataIn.id);
              sessionStorage.setItem("videoArrUav", JSON.stringify(tempData));
            }
          } else {
            sessionStorage.setItem("videoArrUav", JSON.stringify([dataIn.id]));
          }
          //射回一个播放列表
          emit("queryVideoData", JSON.parse(sessionStorage.getItem("videoArrUav")));
          let startList = [];
          JSON.parse(sessionStorage.getItem("streamingListUav")).forEach(e => {
            e.liveInfoList.forEach(item => {
              if (item.isAdd) {
                startList.push(item);
              }
            });
          });
          emit("queryStartData", startList,'uav');
          emitter.emit('addJes',dataIn)
        } else {
          methods.changeButtonStateUav(false, dataIn.id);
          emitter.emit('destoryJes',dataIn.id)
          // 点击关闭
          let tempData = JSON.parse(sessionStorage.getItem("videoArrUav"));
          tempData.forEach((e, index) => {
            if (e === dataIn.id) {
              tempData.splice(index, 1);
            }
          });
          sessionStorage.setItem("videoArrUav", JSON.stringify(tempData));
          emit("queryVideoData", JSON.parse(sessionStorage.getItem("videoArrUav")));
          let startList = [];
          JSON.parse(sessionStorage.getItem("streamingListUav")).forEach(e => {
            e.liveInfoList.forEach(item => {
              if (item.isAdd) {
                startList.push(item);
              }
            });
          });
          emit("queryStartData", startList,'uav');
        }
      },
            //改变按钮状态 -uav
            changeButtonStateUav(type, data) {
        state.uavList.forEach(e => {
          e.liveInfoList.forEach(item => {
            if (item.id === data) {
              item.isAdd = type;
            }
          });
        });
        let tempData = JSON.parse(sessionStorage.getItem("streamingListUav"))
        if (tempData) {
          tempData.forEach(e => {
                e.liveInfoList.forEach(item => {
                  item.deviceSn = e.deviceSn;
                  if(item.id === data){
                    item.isAdd = type;
                    if(type === true){
                      item.isMute = true
                      item.isLuzhi = true
                      item.isPause = true
                      item.isFlv = true
                    }
                  }
                });
              });
        sessionStorage.setItem(
              "streamingListUav",
              JSON.stringify(tempData)
            );
        }
          
      },
      //搜索设备
      getStreamingArraySearch(){
        if (state.labelRight === "AIRPORT") {
          methods.getStreamingArray();
        }else{
          methods.getStreamingArrayUav();
        }
      },
      //获取推流信息列表-airport
      getStreamingArray() {
        device.queryStreamingArray(state.labelRight,state.inputValue).then(async res => {
          if (res.resultData) {
            state.airportCount = res.resultData[0].area;
            state.airportNum = res.resultData[0].dataList.length;
            res.resultData[0].dataList.forEach(e => {
              e.liveInfoList.forEach(a => {
                a.deviceSn = e.deviceSn;
              });
            });
            if (JSON.parse(sessionStorage.getItem("streamingList")) !== null) {
              JSON.parse(sessionStorage.getItem("streamingList")).forEach(e => {
                e.liveInfoList.forEach(a => {
                  if (a.isAdd !== undefined && a.isAdd !== null) {
                    res.resultData[0].dataList.forEach(s => {
                      s.liveInfoList.forEach(d => {
                        if (a.id === d.id) {
                          d.isAdd = a.isAdd;
                          d.isMute = true
                          d.isLuzhi = true
                          d.isPause = true
                          d.isFlv = true
                        }
                      });
                    });
                  }
                });
              });
              state.airportList = res.resultData[0].dataList;
              sessionStorage.setItem(
                "streamingList",
                JSON.stringify(state.airportList)
              );
              let startList = [];
              res.resultData[0].dataList.forEach(e => {
                e.liveInfoList.forEach(item => {
                  if (item.isAdd) {
                    startList.push(item);
                  }
                });
              });
              emit("queryStartData", startList,'airport');
            } else {
              state.airportList = res.resultData[0].dataList;
              sessionStorage.setItem(
                "streamingList",
                JSON.stringify(state.airportList)
              );
            }
            let tempData = JSON.parse(sessionStorage.getItem("videoArr"));
            if (tempData !== null && tempData !== undefined) {
              //射回一个播放列表
              emit(
                "queryVideoData",
                JSON.parse(sessionStorage.getItem("videoArr"))
              );
            } else {
              //射回一个播放列表
              emit("queryVideoData", []);
            }
          }
          else{
            state.airportList = [];
            state.airportCount = t('76');
            state.airportNum = 0;
          }
        });
      },
      //获取推流信息列表-UAV
      getStreamingArrayUav() {
        device.queryStreamingArray(state.labelRight,state.inputValue).then(async res => {
          if (res.resultData) {
            state.uavCount = res.resultData[0].area;
            state.uavNum = res.resultData[0].dataList.length;
            res.resultData[0].dataList.forEach(e => {
              e.liveInfoList.forEach(a => {
                a.deviceSn = e.deviceSn;
              });
            });
            if (JSON.parse(sessionStorage.getItem("streamingListUav")) !== null) {
              JSON.parse(sessionStorage.getItem("streamingListUav")).forEach(e => {
                e.liveInfoList.forEach(a => {
                  if (a.isAdd !== undefined && a.isAdd !== null) {
                    res.resultData[0].dataList.forEach(s => {
                      s.liveInfoList.forEach(d => {
                        if (a.id === d.id) {
                          d.isAdd = a.isAdd;
                          d.isMute = true
                          d.isLuzhi = true
                          d.isPause = true
                          d.isFlv = true
                        }
                      });
                    });
                  }
                });
              });
              state.uavList = res.resultData[0].dataList;
              sessionStorage.setItem(
                "streamingListUav",
                JSON.stringify(state.uavList)
              );
              let startList = [];
              res.resultData[0].dataList.forEach(e => {
                e.liveInfoList.forEach(item => {
                  if (item.isAdd) {
                    startList.push(item);
                  }
                });
              });
              emit("queryStartData", startList,'uav');
            } else {
              state.uavList = res.resultData[0].dataList;
              sessionStorage.setItem(
                "streamingListUav",
                JSON.stringify(state.uavList)
              );
            }
            let tempData = JSON.parse(sessionStorage.getItem("videoArrUav"));
            if (tempData !== null && tempData !== undefined) {
              //射回一个播放列表
              emit(
                "queryVideoData",
                JSON.parse(sessionStorage.getItem("videoArrUav"))
              );
            } else {
              //射回一个播放列表
              emit("queryVideoData", []);
            }
          }
          else{
            state.airportList = [];
            state.airportCount = t('76');
            state.airportNum = 0;
          }
        });
      },
      tabChange(name) {
        state.labelRight = name;
        state.inputValue = ''
        if (name === "AIRPORT") {
          emit("queryStartData", [],'airport');
          emit( "queryVideoData",[],'airport');
          methods.getStreamingArray();
          let startList = [];
          let streamingList = JSON.parse(sessionStorage.getItem("streamingList"))
          streamingList.forEach(e=>{
            e.liveInfoList.forEach((item)=>{
              if (item.isAdd) {
                startList.push(item)
                if (startList.length>4) {
                    startList.length = 4
                }
              }
            })
          })
          emitter.emit('tabChange',startList)
        }else{
          emit("queryStartData", [],'uav');
          emit( "queryVideoData",[],'uav');
          methods.getStreamingArrayUav();
          let startListUav = [];
          let streamingListUav = JSON.parse(sessionStorage.getItem("streamingListUav"))
          streamingListUav.forEach(e=>{
            e.liveInfoList.forEach((item,)=>{
              if (item.isAdd) {
                startListUav.push(item)
                if (startListUav.length>4) {
                  startListUav.length = 4
                }
              }
            })
          })
          
          emitter.emit('tabChange',startListUav)
        }
      },
      changeLayout(data) {
        state.cellCount = data;
      },
      onCancel() {
        emit("closeIsVisible");
        state.dialogTableVisible = false;
      }
    });

    return {
      ...toRefs(state),
      ...toRefs(methods)
    };
  }
});
</script>

<style lang="less" scoped>
/deep/.el-input__inner {
  color: #fff;
}
.jiankong{
  margin: 0px 0.8rem;
    color: rgb(255, 255, 255);
    font-size: 1rem;
    display: inline-block;
    height: 2.5rem;
    max-width: 12.35rem;
    line-height: 2.25rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.right_content {
  width: 100%;
  height: 100%;
  background: #00203d;
  .header {
    width: 100%;
    height: 3.692rem;
    background: #003063;
    position: relative;
    .header_text {
      width: 4.3076rem;
      height: 1.0769rem;
      font-size: 1.0769rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #00f5ff;
      line-height: 1.0769rem;
      position: absolute;
      left: 1.384rem;
      top: 1.3076rem;
    }
    .header_line {
      width: 3.153rem;
      height: 0.1538rem;
      background: #00f5ff;
      position: absolute;
      left: 1.384rem;
      top: 3.5449rem;
    }
  }
  .search {
    display: flex;
    align-items: center;
    height: 2.6153rem;
    margin: 1rem 1.3846rem;

    .select {
      height: 2.9625rem;
      border-radius: 0px 0.25rem 0.25rem 0px;
      width: 4.625rem;
      background: #0090da;
      margin-left: -1px;
      color: #fff;
      .el-icon {
        vertical-align: middle;
      }
    }
    /deep/.el-input {
      width: 19.84615rem;
      height: 2.61538rem;
      .el-input__wrapper {
        background-color: #0d4460;
        box-shadow: 0 0 0 0;
        border: 1px solid #51d2ff;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      border-radius: 0.25rem;

      &::placeholder {
        color: #a9b8c3;
      }
      color: #ffffff;
    }
  }
  .tab {
    /deep/.el-tabs--border-card {
      background: #00203e;
      border: none;
    }
    /deep/.el-tabs--border-card > .el-tabs__header {
      background: #0c649d;
      border: none;
    }
    /deep/.el-tabs--border-card > .el-tabs__content {
      padding: 0.9461rem 0.8461rem 1.23rem;
    }
    /deep/.el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
      color: #00f5ff;
      background: #0c649d;
      border: 1px solid #00203d;
    }
    /deep/.el-tabs--border-card > .el-tabs__header .el-tabs__item {
      border: 1px solid #00203d;
      height: 3.6923rem;
      line-height: 3.6923rem;
      text-align: center;
      font-size: 1.0769rem;
    }
    /deep/.el-tabs--border-card > .el-tabs__header .el-tabs__item {
      color: #fff;
    }
    /deep/.el-collapse {
      border-bottom: none;
      border-top: none;
    }
    /deep/.el-collapse-item__header {
      height: 2.8rem;
      background: #00203e;
      color: #fff;
      border-bottom: 1px solid #166fa8;
      border-top: none;
      line-height: 2.8rem;
    }
    /deep/.el-collapse-item__content {
      background: #00203e;
      padding-top: 0.861rem;
      padding-bottom: 0;
    }
    /deep/.el-collapse-item__arrow {
      color: #00f5ff;
      font-size: 1.384rem;
    }
    /deep/.el-collapse-item__arrow.is-active {
      color: #00f5ff;
      font-size: 1.384rem;
    }
    /deep/.el-collapse-item__wrap {
      border-bottom: none;
    }
    /deep/.el-card {
      border: 1px solid #004b79;
      margin-bottom: 10px;
      background: #014b79;
    }
    /deep/.el-card__header {
      padding: 0 1rem;
      background: #014b79;
      border-bottom: 1px solid #00ecf7;
      border-top: none;
      height: 3.2307rem;
      line-height: 3.32rem;
    }
    /deep/.el-card__body {
      background: #014b79;
      padding-left: 1rem;
      padding-right: 1rem;
      padding-top: 1rem;
      padding-bottom: 0.4rem;
    }
    .el-scrollbar {
        height: calc(100vh - 24.1rem);
      }
  }
  .icon_con {
    display: inline-block;
    width: 1.2307rem;
    // height: 18px;
    // line-height: 2rem;;
    // margin-bottom: 2px;
    text-align: center;
    border-radius: 2px;
    float: left;
    // overflow: hidden;
    color: #37daf6;
    // padding-top: 0.261538rem;
    .iconfont {
      font-size: 0.923rem;
    }
    .iconfont1 {
      font-size: 1.615rem;
    }
  }
  .icon_con1 {
    display: inline-block;
    width: 1.2307rem;
    // height: 18px;
    // line-height: 2rem;;
    // margin-bottom: 2px;
    text-align: center;
    border-radius: 2px;
    float: left;
    // overflow: hidden;
    color: #37daf6;
    padding-top: 0.261538rem;

    .iconfont1 {
      font-size: 1.615rem;
    }
  }
}
</style>