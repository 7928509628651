import { t } from '../languages';
import axios from 'axios'
import {
  ElMessage
} from 'element-plus'
import {
  router
} from '@/router';
import domMessage from '@/utils/messageOnce'
const messageOnce = new domMessage()

const request = axios.create({
  // 地址
  baseURL: process.env.VUE_APP_BASE_API,
  // 请求头
  headers: {
    get: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
    },
    post: {
      'Content-Type': 'application/json;charset=utf-8'
    },
    put: {
      'Content-Type': 'application/json;charset=utf-8'
    },
    delete: {
      'Content-Type': 'application/json;charset=utf-8'
    }
  },
  // 跨域请求是否提供凭据信息(cookie、HTTP认证及客户端SSL证明等), 
  // 实际中用途就是跨域请求是要不要携带cookie
  withCredentials: true,
  // 超时时间
  timeout: 5000,
  // 修改请求数据
  // transformRequest: [(data) => {
  //   // 非formData类型数据转json字符串
  //   if (Object.prototype.toString.call(data) !== '[object FormData]') {
  //     data = JSON.stringify(data)
  //   }
  //   return data
  // }],
  // // 修改响应数据
  // transformResponse: [(data) => {
  //   // 修改响应数据
  //   if (typeof data === 'string' && data.startsWith('{')) {
  //     data = JSON.parse(data)
  //   }
  //   return data
  // }]
})

// 请求拦截器
request.interceptors.request.use((config) => {
  //添加token至请求头
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['token'] = token;
  }
  return config;
}, (error) => {
  if (error.code === 'ECONNABORTED') {
    ElMessage.error(t('4'))
    return Promise.reject(error)
  } else {
    const errorMsg = {
      msg: t('5')
    }
    return Promise.reject(errorMsg)
  }
})

// 响应拦截器
request.interceptors.response.use((response) => {
  // 状态码大于等于200，小于300为请求成功
  if (response.status >= 200 && response.status < 300) {
    // const status = response.data.resultStatus;
    // if (status && response.data.resultData) {
    //   // 存在resultData，返回存在resultData，返回resultStatus
    //   return Promise.resolve(status, response.data.resultData);
    // } else {
    //   // 不存在resultData，返回resultStatus
    //   return 


    Promise.resolve(status);
    // }
    if (response.data.resultStatus === false) {
      messageOnce.error(`${response.data.errorMessage}`)
      if (response.data.errorCode === '403') {
        // token失效或拒绝访问
        // router.push('/');
        // localStorage.removeItem('token')
      }
    }
    // 返回data
    return Promise.resolve(response.data);
  } else {
    // 状态码异常，返回整个response
    return Promise.resolve(response);
  }
}, (error) => {
  console.log(error);
  const errorMsg = {
    msg: t('6')
  }
  return Promise.reject(errorMsg)
})

export default request

/* 
  使用方法
  return request({
    url: "/user/info",
    method: "get",
    params,
  });
*/