import { t } from '../languages';
import service from "./service";
import request from "./http.js";
const device = {
    // 新增设备型号信息
    addDeviceModel: (data) => {
        return service.post({
            url: `/api/v1/device/model?model=${data.model}&type=${data.type}`,
        })
    },
    // 修改设备型号信息
    editDeviceModel: (data) => {
        return service.put({
            url: `/api/v1/device/model?id=${data.id}&model=${data.model}&type=${data.type}`
        })
    },
    // 获取设备类型枚举列表
    getDeviceModelList: () => {
        return service.get({
            url: '/api/v1/device/model/getDeviceEnum'
        })
    },
    //获取机场型号
    getAirportModelList: () => {
        return service.get({
            url: '/api/v1/device/model/list/airport'
        })
    },
    // 获取其他设备型号列表
    getOtherModelList: () => {
        return service.get({
            url: '/api/v1/device/model/list/other'
        })
    },
    // 获取无人机型号
    getUavModelList: () => {
        return service.get({
            url: '/api/v1/device/model/list/uav'
        })
    },
    //删除设备型号信息
    delDeviceModel: (id) => {
        return service.delete({
            url: `/api/v1/device/model/${id}`
        })
    },
    // 新增设备信息
    addDevice: (data) => {
        return service.post({
            url: '/api/v1/device',
            data
        })
    },
    //修改设备信息
    editDevice: (data) => {
        return service.put({
            url: '/api/v1/device',
            data
        })
    },
    // 删除设备信息
    delDevice: (data) => {
        return service.delete({
            url: '/api/v1/device',
            data
        })
    },
    // 查询设备展示列表
    getDeviceList: (data) => {
        return service.post({
            url: `/api/v1/device/list?type=${data.type}&pageNo=${data.pageNo}&pageSize=${data.pageSize}`,
            data
        })
    },
    // 根据id获取其他设备信息
    getDeviceOther: (id) => {
        return service.get({
            url: `/api/v1/device/other/${id}`
        })
    },
    // 根据id获取机场设备信息
    getDeviceUav: (id) => {
        return service.get({
            url: `/api/v1/device/uav/${id}`
        })
    },
    // 根据设备类型分组获取设备
    getDeviveRegion: (type) => {
        return service.get({
            url: `/api/v1/device/list/region?type=${type}`
        })
    },

    // 指挥中心省市区筛选列表
    getDressDevive: () => {
        return service.get({
            url: `/api/v1/device/list/getDeviceType`
        })
    },
    // 指挥中心根据省市区获取机场终端
    getCenterDeviveList: (data) => {
        return service.get({
            url: `/api/v1/device/list/region2?type=${data.type}&regionType=${data.regionType}&deviceName=${data.deviceName}`
        })
    },
    // 获取全部设备信息 
    getDeviceListByType: (params) => {
        return service.get({
            url: `/api/v1/device/listDeviceByType`,
            params
        })
    },
    // 根据SN获取型号
    getDeviceModel: (sn) => {
        return service.get({
            url: `/api/v1/device/getModelBySn?sn=${sn}`
        })
    },
    // 获取自动机场坐标及备降点信息
    getAlternatePoint: (sn) => {
        return service.get({
            url: `/api/v1/device/model/get/alternatePoint/${sn}`,
        })
    },
    // 获取自动机场坐标及备降点信息
    getAlternatePoint: (sn) => {
        return service.get({
            url: `/api/v1/device/model/get/alternatePoint/${sn}`,
        })
    },
    // 获取自动机场坐标及备降点信息
    updateAlternate: (data) => {
        return service.post({
            url: `/api/v1/device/model/settings/alternatePoint/sn`,
            data
        })
    },
    // 根据sn获取适航条件 
    getAirworthiness: (sn) => {
        return service.get({
            url: `/api/v1/device/model/get/airworthiness/${sn}`,
        })
    },
    // 根据sn修改适航条件 
    updateAirworthiness: (sn, data) => {
        return service.post({
            url: `/api/v1/device/model/settings/airworthiness/${sn}`,
            data
        })
    },
    // 获取所有机场模型参数  
    getAllAirportParam: () => {
        return service.get({
            url: `/api/v1/device/listNestModelParam`,
        })
    },
    // 获取机场模型参数  
    getAirportParam: (sn) => {
        return service.get({
            url: `/api/v1/device/nestModelParam/${sn}`,
        })
    },
    // 修改机场模型参数 
    updateAirportParam: (params) => {
        return service.get({
            url: `/api/v1/device/updNestModelParam`,
            params
        })
    },
    // 根据设备SN获取其坐标 
    refreshPosition: (sn) => {
        return service.get({
            url: `/api/v1/device/model/get/coordinate/${sn}`,
        })
    },
    // 根据SN获取作业信息 
    getJobInfo: (sn) => {
        return service.get({
            url: `/api/v1/device/device/get/jobInfo/${sn}`,
        })
    },
    // 根据SN获取推流信息 
    getLiveBySn: (nestSn) => {
        return service.get({
            url: `/api/v1/live/getLiveBySn/${nestSn}`,
        })
    },
    // 根据修改推流信息 
    OperateLive: (data) => {
        return service.post({
            url: `/api/v1/live/operate`,
            data
        })
    },
    // 根据SN获取推流信息 
    getRemoteStatu: (nestSn) => {
        return service.get({
            url: `/api/v1/remote/getRemoteStatus/${nestSn}`,
        })
    },
    // 根据sn获取推流信息 
    queryStreamingList: (sn) => {
        return service.get({
            url: `/api/v1/live/show/liveBySn/${sn}`,
        })
    },
    // 根据sn获取推流信息 
    addStreaming: (data) => {
        return service.post({
            url: `/api/v1/live/add/LiveInfo`,
            data
        })

    },
    // 删除推流信息 
    delStreaming: (data) => {
        return service.delete({
            url: `/api/v1/live/delete/LiveInfo`,
            data
        })

    },
    // 编辑推流信息 
    editStreaming: (data) => {
        return service.post({
            url: `/api/v1/live/settings/LiveInfo`,
            data
        })

    },
    // 获取指定 推流类型 的推流信息列表 
    queryStreamingArray: (query, deviceName) => {
        return service.get({
            url: `/api/v1/live/show/liveInfo/list?liveType=${query}&deviceName=${deviceName}`,
        })
    },
    // 获取日志按钮
    queryLog: (data) => {
        return service.post({
            url: `/api/v1/app/logList?nestName=${data.nestName}&nestSn=${data.nestSn}&firmwareType=${data.firmwareType}&pageNo=${data.pageNo}&pageSize=${data.pageSize}`,
            data
        })

    },
    // 获取日志按钮
    queryLogBtn: (data) => {
        return service.post({
            url: `/api/v1/appLog`,
            data
        })

    },
    // 删除日志
    deleteLog: (data) => {
        return service.delete({
            url: `/api/v1/appLog/delNest`,
            data
        })

    },
    //获取机场型号下拉
    getDeviceModelAirportList: () => {
        return service.get({
            url: `/api/v1/device/model/list/airport/down`,
        })
    },
    queryStreamingArray: (query, deviceName) => {
        return service.get({
            url: `/api/v1/live/show/liveInfo/list?liveType=${query}&deviceName=${deviceName}`,
        })
    },
    // 获取版本列表 
    getVersionList: (data) => {
        return service.post({
            url: `/api/v1/versionNest/List`,
            data
        })
    },
    // 查询机场APP版本（刷新按钮） 
    refreshVersion: (params) => {
        return service.get({
            url: `/api/v1/versionNest/query`,
            params
        })
    },
    // 执行更新命令
    updateVersion: (params) => {
        return service.get({
            url: `/api/v1/versionNest/update`,
            params
        })
    },
    // 机场更新版本记录分页列表
    getVersionRecord: (params) => {
        return service.get({
            url: `/api/v1/appUpdate/versionRecord`,
            params
        })
    },
    // 获取机场推流状态
    getLiveStatus: (sn) => {
        return service.get({
            url: `/api/v1/getLiveStatus/${sn}`,
        })
    },
    // 修改机场推流状态 
    pushLiveStatus: (sn, data) => {
        return service.post({
            url: `/api/v1/pushLiveStatus/${sn}`,
            data
        })
    },
    //根据设备ID获取保险列表
    getInsuranceList: (deviceId) => {
        return service.get({
            url: `/api/v1/show/insuranceList/${deviceId}`,
        })
    },
    /* 获取修改的保险详情 */
    editInsurance: (id) => {
        return service.get({
            url: `/api/v1/show/insurance/${id}`,
        })
    },

    
    /* 添加保险详情 */
    addInsuranc: (data) => {
        return service.post({
            url: "/api/v1/add/insurance",
            data
        })
    },
    /* 保存修改保险详情 */
    saveInsuranc: (data) => {
        return service.put({
            url: "/api/v1/settings/insurance",
            data
        })
    },
    /* 删除保险详情 */
    deleteInsuranc: (id) => {
        return service.delete({
            url: `/api/v1/delete/insurance/${id}`,
        })
    },
    // 机场测试权限验证
    getTestAirport: () => {
        return service.get({
            url: '/api/v1/test/airport'
        })
    },

    /* 添加指点飞行任务 */
    addPointFlight: (data,sn) => {
        return service.post({
            url: `/api/v1/point/task/${sn}`,
            data
        })
    },

    /* 添加配件 */
    addAccessory: (data) => {
        return service.post({
            url: `/api/v1/device/accessory/add`,
            data
        })
    },
    /* 编辑配件 */
    editAccessory: (data) => {
        return service.post({
            url: `/api/v1/device/accessory/edit`,
            data
        })
    },
    /* 删除配件 */
    delAccessory: (data) => {
        return service.delete({
            url: '/api/v1/device/accessory/del',
            data
        })
    },
    /* 获取配件 */
    getAccessoryList: (data) => {
        return service.get({
            url: `/api/v1/device/accessory/query/list/${data.pageNo}/${data.pageSize}?modelId=${data.modelId}&queryInfo=${data.queryInfo}&region=${data.region}&teamId=${data.teamId}`
        })
    },
    /* 获取详情 */
    getAccessoryDetail: (id) => {
        return service.get({
            url: `/api/v1/device/accessory/query/${id}`
        })
    },
    /* 获取安全飞行高度 */
    getFlyHeight: (sn) => {
        return service.get({
            url: `/api/v1/getPoint/taskHitht/${sn}`
        })
    },
    /* 设置安全飞行高度 */
    editFlyHeight: (height,sn) => {
        return request({
            url: `/api/v1/setPoint/taskHitht/${sn}`,
            method: "post",
            params: {
                height: height,
            }
        });
    },
    
    
    

}
export default device