import { t } from '../languages';
import { ElMessage } from "element-plus";
import emitter from "@/utils/mitt";
import domMessage from "@/utils/messageOnce";

let websock = null;
let linkTimer = 0; //重试次数
let timer = null;
let lastConnectTime = 0; //上次重试时间
const messageOnce = new domMessage();
/**
 * 打开通信连接
 */
const socket = () => {
  function openSocketConnect() {
    if (typeof WebSocket === "undefined") {
      ElMessage.error(t("47"));
    } else {
      const wsUrl = `${window.g.websockURL}`;
      websock = new WebSocket(wsUrl);
      // console.log(websock);
      // websock.binaryType = "blob";
      websock.binaryType = "arraybuffer";
      websock.onopen = websocketonopen;
      websock.onclose = websocketclose;
      websock.onerror = websocketonerror;
      websock.onmessage = websocketonmessage;
    }
    console.log(t("48"));
  }
  /**
   * websocket发送消息
   * @param {* 数据对象} data  Object
   */
  function websocketsend(data) {
    if (websock && websock.readyState === 1) {
      websock.send(JSON.stringify(data));
    } else {
      messageOnce.error(t("49"));
    }
  }

  /**
   * websocket发送ArrayBuffer消息
   */
  function sendArrayBuffer(data) {
    if (websock && websock.readyState === 1) {
      console.log(websock.binaryType);
      websock.send(data);
    } else {
      messageOnce.error(t("49"));
    }
  }

  /* 连接已开启 */
  function websocketonopen() {
    ElMessage.success(t("50"));
    linkTimer = 0;
    // console.log(t('51'));
    emitter.emit("ws-open");

    //心跳检测
    clearInterval(timer);
    timer = setInterval(function () {
      if (websock.readyState === 1) {
        const heartBeat = {
          action: "WS_HEAR_BEAT",
          code: "",
          data: new Date().valueOf(),
        };
        websock.send(JSON.stringify(heartBeat));
      }
    }, 30000);
  }

  /* 重连 */
  function reconnection() {
    const now = new Date().valueOf();
    // lastConnectTime控制重连间隔
    if (linkTimer < 3 && now - lastConnectTime >= 6000) {
      linkTimer++;
      lastConnectTime = now;
      setTimeout(() => {
        ElMessage.info(t("52") + linkTimer + t("53"));
        openSocketConnect();
      }, 6000);
    }
  }

  /* websocket关闭 */
  function websocketclose(e) {
    ElMessage.info(t("54"));
    if (e.code === 1006) {
      reconnection();
    }
    console.log(
      t("55") + e.code + " " + e.reason + " " + e.wasClean
    );
  }

  /* websocket错误 */
  function websocketonerror(event) {
    console.log(event);
    // 产生错误已关闭 -- 进行重连
    if (websock.readyState === WebSocket.CLOSED) {
      reconnection();
    }
  }

  /* websocket接收消息 -- 在此做消息分发 */
  function websocketonmessage(e) {
    const dataInfo = JSON.parse(e.data);
    emitter.emit("nest-message", dataInfo);
    const action = dataInfo.action;
    if (action === "APP_LOG") {
      if (dataInfo.data) {
        emitter.emit("queryLogSuccess", dataInfo);
      }
    } else if (action === "NEST_LIVE_STATUS") {
      if (dataInfo.data) {
        if (dataInfo.status) {
          emitter.emit("controlStreaing", dataInfo);
        }
      }
    } else if (action === "OPUS_LIST") {
      emitter.emit("speaker-audio-list", dataInfo);
    } else if (action === "MEGAPHONE_STA_WS") {
      // 设备状态信息
      emitter.emit("speaker-device-status", dataInfo);
    } else if (action === "MEGAPHONE_PLAY_SOURCE_WS") {
      // 播放源信息
      emitter.emit("speaker-source-status", dataInfo);
    } else if (action === "MEGAPHONE_PLAY_STA_WS") {
      // 播放音频状态信息
      emitter.emit("speaker-audio-status", dataInfo);
    } else if (action === "MEGAPHONE_INFO_WS") {
      // 播放音频状态信息
      emitter.emit("speaker-audio-info", dataInfo);
    } else if (action === "OPUS_LIST_BGM") {
      // 背景音列表
      emitter.emit("speaker-bgm-list", dataInfo);
    } else if (action === "MEGAPHONE_PLAY_STA_WS_BGM") {
      // 背景音播放状态
      emitter.emit("speaker-bgm-status", dataInfo);
    } else if (action === "MEGAPHONE_TZD_INFO_WS") {
      // 探照灯状态信息
      emitter.emit("speaker-tzd-info", dataInfo);
    } else if (action === "CTL_STATUS_WS") {
      // 警示灯状态信息
      emitter.emit("speaker-ctl-info", dataInfo);
    } else if (action === "STA_SOLAR") {
      // 能耗信息
      emitter.emit("energy-info", dataInfo);
    }
    // emitter.emit('chat-message', dataInfo);
  }

  /* 关闭通信连接 */
  function closeSocketConnect() {
    clearInterval(timer);
    websock.close();
  }

  /* 获取ws状态 */
  function getWsState() {
    return websock ? websock.readyState : 0;
  }

  return {
    openSocketConnect,
    websocketsend,
    sendArrayBuffer,
    closeSocketConnect,
    getWsState,
  };
};
export default socket;
