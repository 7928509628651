import { t } from '../languages';

/**
 * 根据类型和文件流下载文件
 * @param {* 类型 默认excel 即'application/vnd.ms-excel'} type 
 * @param {* 文件名称} fileName 
 * @param {* 流} data 
 */
export function download(data, fileName, type = 'application/vnd.ms-excel') {
  const blob = new Blob([data], {
    type: `${type};charset=utf-8`
  })
  const downloadElement = document.createElement('a')
  const href = window.URL.createObjectURL(blob) //创建下载的链接
  downloadElement.style.display = 'none'
  downloadElement.href = href
  downloadElement.download = fileName; //下载后文件名
  document.body.appendChild(downloadElement)
  downloadElement.click() //点击下载
  document.body.removeChild(downloadElement) //下载完成移除元素
  window.URL.revokeObjectURL(href) //释放掉blob对象
}


/* 格式化日期函数 */
export function dateFormatter(time,format = 'YYYY-MM-DD HH:mm:ss') {
  let date
  if (typeof Number(time) === 'number') {
    if ((time + '').length === 10) {
      date = new Date(time * 1000)
    } else {
      date = new Date(time)
    }
  } else {
    // date = new Date(Number(time))
    return '00:00:00'
  }

  const formatter = {
    YYYY: date.getFullYear(),
    MM: (date.getMonth() + 1 + '').padStart(2, '0'),
    DD: (date.getDate() + '').padStart(2, '0'),
    HH: (date.getHours() + '').padStart(2, '0'),
    mm: (date.getMinutes() + '').padStart(2, '0'),
    ss: (date.getSeconds() + '').padStart(2, '0')
  }

  for (const key in formatter) {
    format = format.replace(key, formatter[key])
  }

  return format
}



/* 时间戳格式化为时分秒 */
export function parseTime(timestamp) {
  let date;
  if(timestamp.toString().length > 10){
     date = new Date(timestamp);
  }else{
     date = new Date(timestamp*1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
  }
  let Y = date.getFullYear() + "-";
  let M =
    (date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1) + "-";
  let D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
  let h =
    (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
  let m =
    (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
    ":";
  let s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  // let strDate = Y + M + D + h + m + s;
  let strDate = h + m + s;
  return strDate;
}

/* 时间戳格式化为年月日 */
export function parseTimes(timestamp) {
  let date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
  let Y = date.getFullYear() + "-";
  let M =
    (date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1) + "-";
  let D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
  let h =
    (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
  let m =
    (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
    ":";
  let s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  let strDate = Y + M + D;
  return strDate;
}

/* 时间戳格式化为中国标准时间 */
export function chinaTime(timestamp){
  let date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
  return date
}

//根据url获取json文件数据
export async function getJsonData(url){
  let response = await fetch(url);
  return await response.json();
}

/* 秒数转分钟 */
export function secondToMinute(totalSecond){
  totalSecond = parseInt(totalSecond);
  if(isNaN(totalSecond)){
    return '';
  }
  let minute = Math.floor(totalSecond / 60);

  let second = totalSecond % 60;
  minute = minute < 10 ? '0' + minute : minute;
  second = second < 10 ? '0' + second : second;
  return minute + ':' + second;
}


/* 防抖函数 */
export function debounce(fn, delay) {
  let timer = null;
  return function () {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      fn();
    }, delay);
  };
}


/**
 * @description: 检查网址可用性
 * @param {*} url   地址
 * @param {*} succCall   成功回调
 * @param {*} errorCall   失败回调
 * @return {*}  undefined
 */
 export function checkWebsiteAvailability(url, succCall, errorCall) {
  fetch(url)
      .then((response) => {
          if (response.ok) {
              succCall()
          } else {
              errorCall()
          }
      })
      .catch(() => {
          errorCall()
      });
}
