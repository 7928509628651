<template>
  <div class="content">
    <router-view></router-view>
  </div>
</template>

<script>
import { t } from '../../../languages';
import { defineComponent, ref, watch } from "@vue/runtime-core";
import { useRoute } from "vue-router";
export default defineComponent({
  setup() {
    const right = ref("1.5625rem");
    // const bottom = ref("0.8125rem");
    const route = useRoute();
    if (route.path == "/commandcenterIndex") {
      // 防止刷新拿默认值
      right.value = "0";
    }
    watch(route, (newName) => {
      right.value = newName.path === "/commandcenterIndex" ? "0" : "1.5625rem";
      //  bottom.value = newName.path === "/commandcenterIndex" ? "0" : "0.8125rem";
    });

    return {
      right,
      // bottom
    };
  },
});
</script>

<style lang='less' scoped>
.content {
  position: absolute;
  top: 6.25rem;
  right: v-bind(right);
  bottom: 0.8125rem;
  // v-bind(bottom)
  left: 5.5625rem;
  // overflow-y: auto;
  transition: all 0.3s ease-in-out;
}
</style>