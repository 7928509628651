<template>
  <el-config-provider :locale="locale">
    <router-view></router-view>
  </el-config-provider>
</template>

<script>
import { t } from './languages';
import { defineComponent, onMounted, onUnmounted, computed } from "vue";
import { store } from '@/store';
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import en from 'element-plus/dist/locale/en.mjs'
import { checkWebsiteAvailability } from "@/utils/utilFun.js";

export default defineComponent({
  setup() {
    const appName = window.g.appName;
    document.title = appName

    function clear(){
      localStorage.removeItem("userInfo");
      localStorage.removeItem("menusId");
      localStorage.removeItem("token");
    }

    //取得cookie    
    function getCookie(name) {
      const nameEQ = name + "=";
      if(document.cookie.indexOf(nameEQ) !== -1){
        return true;
      } else {
        return false;
      }
      
    }
    if(!getCookie('token')){
      //清除
      clear();
    }

    const state = store.state;

    function handleVisibility(){
        //页面可见性，页面可见说明是切回当前页面
      // 如果页面可见 -- 检查store里面的用户id和localstroge的用户id是否一致
      // 不一致则刷新页面
      if(!document.hidden){
        let vuexUserId = '';
        if (state.user.userInfo) {
          vuexUserId = state.user.userInfo.id;
        }

        const userInfoJson = localStorage.getItem('userInfo')
        let localUserId = '';
        if (userInfoJson !== null) {
          const userInfo = JSON.parse(userInfoJson);
          localUserId = userInfo.id;
        }

        // 用户id不一致时刷新页面
        if(vuexUserId && localUserId && vuexUserId !== localUserId){
          history.go(0);
        }
      }
    }

    onMounted(()=>{
      // 监听文档可见性
      document.addEventListener('visibilitychange', handleVisibility);
    })


    onUnmounted(()=>{
      document.removeEventListener('visibilitychange', handleVisibility)
    })

    const locale = computed(() => (state.system.language === 'zh' ? zhCn : en))

    // 检查mars3d地形服务
    checkWebsiteAvailability(
      "https://data.mars3d.cn",
      () => {
        localStorage.setItem("mars3dTerrain", true);
      },
      () => {
        localStorage.setItem("mars3dTerrain", false);
      }
    );

    // 检查天地图影像服务
    checkWebsiteAvailability(
      "https://t5.tianditu.gov.cn/img_w/wmts?service=wmts&request=GetTile&version=1.0.0&LAYER=img&tileMatrixSet=w&TileMatrix=3&TileRow=3&TileCol=7&style=default&format=tiles&tk=47421dd0051ada3957f279b484932789",
      () => {
        localStorage.setItem("tiandituCon", true);
      },
      () => {
        localStorage.setItem("tiandituCon", false);
      }
    );

    return {
      locale
    }
  },
});
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #fff;
}
</style>
