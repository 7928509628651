<template>
  <div class="select-wrapper">
    <label v-if="lableshow" class="label" >{{ label }}</label>
    <el-select v-model="val" :clearable="clearable" @clear="onClear" @change="onSelect" :disabled="isDisabled">
      <el-option
        v-for="(item, index) in options"
        :key="index"
        :label="item[labelname]"
        :value="{ value:item[value],label:item[labelname]}"
      >
        <slot />
      </el-option>
    </el-select>
  </div>
</template>

<script>
import { t } from '../../languages';
import { defineComponent, reactive, ref, toRefs } from 'vue';
export default defineComponent({
  name: 'eleSelect',
  props: {
    label: {
      type: String,
      default: '',
    },
    lableshow:{
      type:Boolean,
      default: true,
    },
    options: {
      type: Array,
      default: () => [],
    },
    labelname: {
      type: String,
      default: 'label',
    },
    value: {
      type: String,
      default: 'value',
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    // 下拉菜单绑定的值
    selectVal: {
      type: String,
      default: '',
    },
    selectLable: {
      type: String,
      default: '',
    },
  },
  emits: ['on-clear', 'on-select', 'update:selectVal','update:selectLable'],
  setup(_, { emit }) {
    const val = ref('');
    const methods = reactive({
      onSelect: () => {
        console.log(val)
        console.log(val.value.value)
        //传value
        emit('update:selectVal', val.value.value);
        //传lable
        emit('update:selectLable', val.value.label);
        emit('on-select');
      },
      onClear: () => {
        emit('on-clear');
      },
    });
    return {
      val,
      ...toRefs(methods),
    };
  },
});
</script>

<style lang="less" scoped>
.select-wrapper {
  display: flex;
  align-items: center;
  .label {
    font-size: 1rem;
    color: #36e5ff;
    margin-right: 0.75rem;
    margin-left: 1.15rem;
  }
  .el-select {
    /deep/.el-input__wrapper {
      width: 10.5rem;
      border: 1px solid #36e5ff;
      border-radius: 0.25rem;
      background-color: @lightBackground;
   
      box-shadow: 0 0 0 0;
      .el-input__inner {
        color: #fff;
      }
    }
  }
}
</style>