import { t } from '../languages';
import request from "./http.js";
import service from "./service.js";

const taskManage = {
    //获取列表
    getTaskList: (params) => {
        return service.get({
            url: `/api/v1/task/page`,
            params
        });
    },
    //根据区域获取人员列表
    getMemberList: (params) => {
        return service.get({
            url: `/api/v1/task/getUserDown`,
            params
        });
    },
    //根据区域获取设备列表
    getDeviceList: (params) => {
        return service.get({
            url: `/api/v1/task/getDeviceDown`,
            params
        });
    },
    // 导出文件
    exportExcel: () => {
        return service.get({
            url: `/api/v1/task/export`,
        });
    },
    // 批量删除人员
    batchDelMember: (data) => {
        return service.delete({
            url: `/api/v1/task/delete`,
            data
        });
    },
    //获取创建人列表
    getCreateList: () => {
        return service.get({
            url: `/api/v1/task/taskCreateUserList`
        })
    },
    //根据id获取任务信息  (详情)
    getTaskMessage: (taskId) => {
        return service.get({
            url: `/api/v1/task/${taskId}`
        })
    },
    //获取计划任务数量
    getPlanTask: () => {
        return service.get({
            url: `/api/v1/task/getTaskCycleCount`
        })
    },
    //提交任务信息
    updateTaskMessage: (data) => {
        return service.post({
            url: `/api/v1/task/update`,
            data
        });
    },
    //获取周期任务列表
    getTaskCycleList: (params) => {
        return service.get({
            url: `/api/v1/taskCycle/list`,
            params
        });
    },
    //获取任务名称下拉列表
    getTaskSelectList: () => {
        return service.get({
            url: `/api/v1/tasks/listName`,
        });
    },
    // 批量删除任务
    batchDelTask: (data) => {
        return service.delete({
            url: `/api/v1/taskCycle/delete`,
            data
        });
    },
    //根据id移除任务
    deleteTask: (id) => {
        return service.delete({
            url: `/api/v1/taskCycle/deleteById/${id}`,
        });
    },
    //获取周期任务下拉列表
    getCycleMenu: () => {
        return service.get({
            url: `/api/v1/taskCycle/cycleModeList`
        })
    },
    //获取任务下拉列表
    getTaskMenu: () => {
        return service.get({
            url: `/api/v1/task/taskList`
        })
    },
    //新增周期任务
    addCycleTask: (data) => {
        return service.post({
            url: `/api/v1/taskCycle/add`,
            data
        });
    },
    //更新周期任务开启状态
    upDateCycleTask: (id, openStatus) => {
        return request({
            url: `/api/v1/taskCycle/updateById/${id}`,
            method: "post",
            params: {
                openStatus: openStatus,
            }
        });
    },
    // 指挥中心任务记录管理
    // 获取执行中或待执行的任务记录
    getExecution: (deviceSn) => {
        return service.get({
            url: `/api/v1/task/record/execution?deviceSn=${deviceSn}`
        });
    },
    // 获取任务列表
    getCenterTaskList: (data) => {
        return service.get({
            url: `/api/v1/task/listBySn/${data.sn}?keyword=${data.keyword}`
        });
    },
    // 获取飞行任务记录列表
    getFlyList: (data) => {
        return service.get({
            url: `/api/v1/task/record/${data.sn}?keyword=${data.keyword}`
        });
    },
    // 执行任务
    issueTask: (data) => {
        return service.get({
            url: `/api/v1/tasks/${data.taskId}/operate/${data.operateType}?issueDate=${data.issueDate}`
        });
    },
    // 取消执行
    cancelTask: (taskRecordId) => {
        return service.delete({
            url: `/api/v1/task/record/cancel/${taskRecordId}`
        });
    },
    // 删除待执行任务
    deletePending: (taskRecordId) => {
        return service.delete({
            url: `/api/v1/task/record/${taskRecordId}`
        });
    },
    // 创建任务
    createTask: (data) => {
        return service.post({
            url: `/api/v1/task/addTask`,
            data
        });
    },
    // 删除任务列表
    centerDeleteTask: (data) => {
        return service.delete({
            url: `/api/v1/task/delete`,
            data
        });
    },
    // 计划周期任务列表
    getExecutionList: (deviceSn) => {
        return service.get({
            url: `/api/v1/taskCycle/list/${deviceSn}`
        });
    },
    //获取执行中的任务信息
    executionTask: (params) => {
        return service.get({
            url: `/api/v1/task/record/execution`,
            params
        });
    },
    //通过任务记录ID获取执行记录
    getListExecLog: (recordId) => {
        return service.get({
            url: `/api/v1/tasks/listExecLog/${recordId}`
        });
    },

    
    //根据任务ID获取航线信息
    airwayTask: (params) => {
        return service.get({
            url: `/api/v1/task/record/airway`,
            params
        });
    },
    //获取无人机飞行轨迹
    getFlightTrajectory: (sn) => {
        return service.get({
            url: `/api/v1/getFlightTrajectory/${sn}`,
        });
    },
    //获取指挥中心回传图片
    getPicture: (recordId) => {
        return service.get({
            url: `/api/v1/tasks/records/${recordId}/images`,
        });
    },
    //机场测试控制操作
    airportControl: (params,time) => {
        let timeout = time==undefined?0:time;//认证需要设置超时时间
        return service.get({
            url: `/api/v1/operate/command`,
            params,
            isFormData:false,
            timeout
        });
    },
    //获取操作日志
    getLog: (nestSn) => {
        return service.get({
            url: `/api/v1/getOperateLog/${nestSn}`,
        });
    },
    getLive: (nestSn) => {
        return service.get({
            url: `/api/v1/live/show/liveBySn/${nestSn}`,
        });
    },
    //获取任务记录列表
    getTaskRecord: (params) => {
        return service.get({
            url: `/api/v1/task/record/page`,
            params
        });
    },
    //获取任务状态下拉框
    getTaskStatusList: () => {
        return service.get({
            url: `/api/v1/task/record/getStatusDown`,
        });
    },
    // 删除任务记录列表
    deleteTaskRecord: (data) => {
        return service.delete({
            url: `/api/v1/task/record/delList`,
            data
        });
    },
    //根据任务记录id获取图片列表
    getPicList: (recordId) => {
        return service.get({
            url: `/api/v1/tasks/records/${recordId}/images`,
        });
    },
    getLiveBySn: (nestSn) => {
        return service.get({
            url: `/api/v1/live/show/liveBySn/${nestSn}`,
        });
    },
    // 停止推流
    pauseStreaming: (query) => {
        return service.get({
            url: `/api/closestream?streamPath=${query}`,
        })
    },
    // 获取m7s推流sdp
    queryM7sSdp: (data) => {
        return service.post({
            url: `/api/v1/live/getRtcSdp`,
            data
        });
    },
    //任务记录详情【列表】
    getTaskRecordDetails: (params) => {
        return service.get({
            url: `/api/v1/get/TaskRecordDetails/List`,
            params
        });
    },
    //通过任务记录id获取任务记录详细信息
    getTaskRecordMessage: (taskRecordId) => {
        return service.get({
            url: `/api/v1/task/detail/record/${taskRecordId}`,
        });
    },
    //重命名任务名称
    rewriteTaskName: (taskId, taskName) => {
        return request({
            url: `/api/v1/task/updName`,
            method: "put",
            params: {
                taskId: taskId,
                taskName: taskName
            }
        });
    },
    /* 获取绕飞任务列表 */
    getDetourMissionList: (nestSn) => {
        return service.get({
            url: `/api/v1/get/aroundTask/${nestSn}`,
        });
    },
    /* 添加绕飞任务 */
    addDetourMission:(data)=>{
        return service.post({
            url: `/api/v1/add/aroundTask`,
            data
        });
    },
    /* 获取绕飞任务最低执行任务电量 */
    getLowPower:(nestSn)=>{
        return service.get({
            url:`/api/v1/zsj/query/battery/power/${nestSn}`
        })
    },
    /* 设置绕飞任务最低执行电量 */
    updateLowPower:(nestSn,power)=>{
        console.log(nestSn,power,"333");
       
        return service.post({
            url:`/api/v1/zsj/set/battery/power/${nestSn}?power=${power}`,

        })
    }

}
export default taskManage;