<template>
  <div class="msg_content">
    <!-- <el-scrollbar ref="myScrollbar" height="421px"> -->
    <div class="chat-content" id="chat" ref="chat">
      <div id="loading" class="loading" v-if="loading" v-loading="loading"></div>
      <!-- recordContent 聊天记录数组-->
      <div v-for="itemc in recordContent" :key="itemc.id">
        <!-- 对方 -->
        <div class="word" v-if="userInfo.id !== itemc.userId && itemc.type === 0">
          <div class="info">
            <img @click.stop="openUserInfo(itemc)"  v-if="itemc.avatar" :src="itemc.avatar" /><div @click.stop="openUserInfo(itemc)"  class="divAvatar" v-else>{{ itemc.userName.slice(0, 1) }}</div>
            <span class="time">{{ itemc.userName }}</span>
            <div class="info-content">{{ itemc.msg }}</div>
          </div>
        </div>
        <!-- 对方 @ -->
        <div class="word" v-if="userInfo.id !== itemc.userId && itemc.type === 1">
          <div class="info">
            <img @click.stop="openUserInfo(itemc)"  v-if="itemc.avatar" :src="itemc.avatar" /><div @click.stop="openUserInfo(itemc)"  class="divAvatar" v-else>{{ itemc.userName.slice(0, 1) }}</div>
            <span class="time">{{ itemc.userName }}</span>
            <div class="info-content">{{ itemc.msg }}</div>
            <div class="weidu-content" v-if="itemc.confirmUser.split(',').includes(userInfo.id) && itemc.type === 1 &&!itemc.confirmedUser.split(',').includes(userInfo.id)"><span>{{ t('900') }}</span></div>
            <div class="yidu-content" v-else-if="itemc.confirmUser.split(',').includes(userInfo.id) && itemc.type === 1 "><span>{{ t('901') }}</span></div>
          </div>
        </div>
        <!-- 对方  地图标记-->
        <div class="word" v-else-if="userInfo.id !== itemc.userId && itemc.type === 3">
          <div class="info">
            <img @click.stop="openUserInfo(itemc)"  v-if="itemc.avatar" :src="itemc.avatar"><div @click.stop="openUserInfo(itemc)"  class="divAvatar" v-else>{{ itemc.userName.slice(0, 1) }}</div>
            <span class="time">{{itemc.userName}}</span>
            <div class="map-content">
                    <div class="map_pic"><el-image :src="itemc.msg" :preview-src-list="mapSrcList" :hide-on-click-modal="true" :initial-index="mapSrcListId.findIndex(item=>itemc.id === item)" fit="fill"/></div>
                    <div class="map_msg">
                      <div class="map_option">
                        <el-icon class="red_btn"><LocationFilled /></el-icon>
                      </div>
                      <div class="map_name" :title="itemc.locationName"><span>{{itemc.locationName}}</span></div>
                      <div class="map_option">
                      <el-icon
                        class="white_btn"
                        @click="goMarkedPoint(itemc)"
                        ><Right
                      /></el-icon>
                      </div>
                    </div>
                  </div>
          </div>
        </div>
        <!-- 对方 img -->
        <div class="word" v-else-if="userInfo.id !== itemc.userId && itemc.type === 2">
          <div class="info">
            <img @click.stop="openUserInfo(itemc)" v-if="itemc.avatar" :src="itemc.avatar"><div @click.stop="openUserInfo(itemc)"  class="divAvatar" v-else>{{ itemc.userName.slice(0, 1) }}</div>
            <span class="time">{{itemc.userName}}</span>
            <div class="img-content"><el-image :src="itemc.msg" :preview-src-list="srcList" :hide-on-click-modal="true" :initial-index="srcListId.findIndex(item=>itemc.id === item)" fit="cover"/></div>
          </div>
        </div>
        <div class="word-time" v-else-if="!itemc.userId && !itemc.type && !itemc.userName"><div class="time_content">{{ itemc }}</div></div>
        <div class="word-notice" v-else-if="itemc.senderId === userInfo.id && itemc.code==='CONFIRM_MSG'"><div class="notice_content">{{ itemc.userName + t('901') + '  '  + '"'}}{{(itemc.msg.length>10)?itemc.msg.slice(0,11)  +'...'  +'"':itemc.msg  +'"'}}<span @click="showYidu(itemc)">&nbsp;&nbsp;{{ t('384') }}</span></div></div>
        <!-- 公告 -->
         <div class="word-time" v-else-if="itemc.code==='CONFIRM_ANNOUNCEMENT'"><div class="notice_content">{{ itemc.userName===userInfo.username? t('902') :itemc.userName }}{{ t('903') }}</div></div>
        <!-- 我的 -->
        <div class="word-my" v-else-if="userInfo.id === itemc.userId && itemc.type === 0">
          <div class="info">
            <!-- <div class="weidu-content" v-if="itemc.contactText.includes('@')"><span>{{ t('900') }}</span></div> -->
            <div class="info-content">{{ itemc.msg }}</div>
            <span class="time">{{ itemc.userName }}</span>
            <img v-if="itemc.avatar" @click.stop="openUserInfo(itemc)"  :src="itemc.avatar"><div @click.stop="openUserInfo(itemc)"  class="divAvatar" v-else>{{ itemc.userName.slice(0, 1) }}</div>
          </div>
        </div>
         <!-- 我的 @ -->
         <div class="word-my" v-else-if="userInfo.id === itemc.userId && itemc.type === 1">
          <div class="info">
            <div class="infoAite-content">{{ itemc.msg }}</div>
            <span class="time">{{ itemc.userName }}</span>
            <img v-if="itemc.avatar" @click.stop="openUserInfo(itemc)"  :src="itemc.avatar"><div @click.stop="openUserInfo(itemc)"  class="divAvatar" v-else>{{ itemc.userName.slice(0, 1) }}</div>
          </div>
        </div>
        <!-- 我的 地图标记 -->
        <div class="word-my"  v-else-if="userInfo.id === itemc.userId && itemc.type === 3">
          <div class="info">
                  <div class="map-content">
                    <div class="map_pic"><el-image :src="itemc.msg" class="map_img_load" :preview-src-list="mapSrcList" :hide-on-click-modal="true" :initial-index="mapSrcListId.findIndex(item=>itemc.id === item)" fit="fill"/></div>
                    <div class="map_msg">
                      <div class="map_option">
                        <el-icon class="red_btn"><LocationFilled /></el-icon>
                      </div>
                      <div class="map_name" :title="itemc.locationName"><span>{{itemc.locationName}}</span></div>
                      <div class="map_option">
                        <el-icon
                        class="white_btn"
                        @click="goMarkedPoint(itemc)"
                        ><Right
                      /></el-icon>
                      </div>
                    </div>
                  </div>
                  <span class="time">{{itemc.userName}}</span>
                  <img v-if="itemc.avatar" @click.stop="openUserInfo(itemc)"  :src="itemc.avatar"><div @click.stop="openUserInfo(itemc)"  class="divAvatar" v-else>{{ itemc.userName.slice(0, 1) }}</div>
          </div>
        </div>
        <!-- 我的 img -->
        <div class="word-my"  v-else-if="userInfo.id === itemc.userId && itemc.type === 2">
          <div class="info">
                  <div class="img-content"><el-image :src="itemc.msg" :preview-src-list="srcList" :hide-on-click-modal="true" :initial-index="srcListId.findIndex(item=>itemc.id === item)" fit="cover"/></div>
                  <span class="time">{{itemc.userName}}</span>
                  <img v-if="itemc.avatar" @click.stop="openUserInfo(itemc)"  :src="itemc.avatar"><div @click.stop="openUserInfo(itemc)"  class="divAvatar" v-else>{{ itemc.userName.slice(0, 1) }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 公告待办 -->
    <div class="notice_box" v-if="isNotice">
        <div class="notice">{{ t('358') }}<i>|</i><span @click="submitNotice">{{ t('576') }}</span></div>
    </div>
    <!-- </el-scrollbar> -->
  </div>
  <div class="send_content">
    <el-tag
                class="mx-1"
                closable
                type="info"
                v-for="itemc in recordContentWeidu" :key="itemc.id"
                @click="aiteYouself"
                @close="handleClose"
            >
            <span>{{ (recordContentWeidu.length === 1)? itemc.userName:recordContentWeidu.length + t('203') }}{{ t('904') }}</span> <el-divider direction="vertical" />
        </el-tag>
        <el-tag
                class="mx-1"
                closable
                type="info"
                v-if="newMsgIscoming"
                @close="handleNewMsgClose"
                @click="showNewMsg"
            >
            <span><span class="iconfont icon-jiantouxia hover_opacity new-msg-jiantou"></span>{{newMsgIscomingCount}}{{ t('905') }}</span> <el-divider direction="vertical" />
        </el-tag>
    <div class="send_control">
      <div class="two_pointer">
        <div class="icon" :class="[chooseType.pic ? 'isActive' : '']">
          <el-upload
            ref="uploadRef"
            :action="picUploadUrl"
            :multiple="true"
            :show-file-list="false"
            :headers="config"
            :auto-upload="true"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            :file-list="fileList"
            accept="image/jpeg,image/jpg,image/png,image/gif,image/tif,image/bmp,image/dds,image/svg"
          >
            <template #trigger>
              <span
                class="iconfont icon-charutupian hover_opacity"
                @click="choosePic"
              ></span>
            </template>
          </el-upload>
        </div>
        <div class="icon" :class="[chooseType.user ? 'isActive' : '']">
          <span
            class="iconfont icon-aite1 hover_opacity"
            @click="chooseUser"
          ></span>
        </div>
      </div>
    </div>
    <div class="send_textare">
      <!-- <el-input id="textara" v-model.trim="sendMsg" type="textarea" /> -->
      <div id="divText" 
      ref="divTextRef" 
      contenteditable="true"
      @input="divInput"
      @paste="copyText"
      v-html="content"
      ></div>
      <el-button plain class="send_btn pointer" @click="sendBtn"
        >{{ t('906') }}</el-button
      >
    </div>
  </div>
  <div class="aite_position" v-if="handleaiteShow">
    <aite
      :currGroupId="aiteCurrGroupId"
      :aiteEdList="aiteEdList"
      @aiteEd="aiteEd"
      @closeaiteEd="
        handleaiteShow = false;
        chooseType.user = false;
      "
    ></aite>
  </div>
</template>

<script>
import { t } from '../../../languages';
import {
  defineComponent,
  reactive,
  toRefs,
  ref,
  onMounted,
  onBeforeUnmount,
  watch,
  computed,
  nextTick,
} from "vue";
import aite from "./aiteUser.vue";
import { ElMessage, ElNotification } from "element-plus";
import socket from "../websocket.js";
import chatV1 from "../../../network/chat.js";
import { parseTime } from "@/utils/utilFun";

// const userInfo = ref();
export default defineComponent({
  props: {
    newMsg: {
      type: Object,
      default: () => {},
    },
    subNotice: {
      type: Boolean,
      default: false,
    },
    trueNotice:{
      type: Object,
      default: () => {},
    },
    currGroupId: {
      type: String,
      default: '',
    },
    yiduMsg: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    aite,
  },
  emits: ["submitNotice",'openUserInfo','goMarkedPoint'],
  setup(props, { emit }) {
    const { newMsg,currGroupId,yiduMsg } = toRefs(props);
    const { websocketsend } = socket();
    const state = reactive({
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      token: '',
      sendMsg: "",
      result_text_show: "",
      chooseType: {
        pic: false,
        user: false,
      },
      config: {},
      handleaiteShow: false,
      recordContent: [],
      recordContentWeidu: [],
      recordContentYidu: [],
      recordContentMyAite: [],
      srcList:[],
      srcListId:[],
      mapSrcList:[],
      mapSrcListId:[],
      aiteCurrGroupId:'',
      dataId:'',
      loading:false,
      nowPageNum:2,
      aiteEdList:[],
      fileList:[],
      picUploadUrl:`${process.env.VUE_APP_BASE_API}/api/v1/imChat/upload`,
      newMsgIscoming:false,
      newMsgIscomingCount:0,
      lock:true,
      textSize: 0,
      fullContent: '',
      content: '',   // 文本数据
      onkeydownDom:'',
      aiteLength:0,
    });
    const divTextRef = ref(null)
    onMounted(() => {
      const chat = document.getElementById('chat')
      chat.addEventListener("scroll",()=>{
        if(chat.scrollTop <= 10 && state.loading === false){
          state.loading = true
          methods.queryGroupChatsOther(state.nowPageNum)
        }
        if(chat.scrollHeight - (chat.clientHeight + chat.scrollTop)<1){
          state.newMsgIscoming = false
          state.newMsgIscomingCount = 0
        }
      });
      state.token = localStorage.getItem("token")
      document.onkeydown = function (event) {
        state.onkeydownDom = event || window.event;
        if (state.onkeydownDom.key === '@') {
          //阻止默认事件，不让其输入@符号
          state.onkeydownDom.preventDefault();
          methods.chooseUser()
        }
        
        if (state.onkeydownDom && state.onkeydownDom.keyCode == 13) {
          //回车键的键值为13
          methods.sendBtn();
          state.onkeydownDom.cancelBubble = true;
          state.onkeydownDom.preventDefault();
          state.onkeydownDom.stopPropagation();
        }
      };
      methods.queryGroupChats();
      methods.textFocus();
    });
    onBeforeUnmount(()=>{
      document.onkeydown = ''
    })
    watch(
      () => props.newMsg,
      (val, prevVal) => {
        if (val.data.confirmUser.split(',').includes(state.userInfo.id) && val.data.type === 1) {
              state.recordContentWeidu.push(val.data)
            }
        if (val.data.confirmUser !== '' && val.data.type === 1 && val.data.userId === state.userInfo.id) {
              state.recordContentMyAite.push(val.data)
            }
        if (val.data.type === 3) {
          setTimeout(() => {
            let timestamp = Date.parse(new Date(state.recordContent[state.recordContent.length - 1].createTime).toString())
            //最新的消息与历史的最新一条消息相比较  如果大于五分钟（300000毫秒）就显示时间间隔
            if (val.data.createTime - timestamp > 300000) {
              state.recordContent.push(parseTime(val.data.createTime));
            }
          }, 800);
        }else{
          let timestamp = Date.parse(new Date(state.recordContent[state.recordContent.length - 1].createTime).toString())
            //最新的消息与历史的最新一条消息相比较  如果大于五分钟（300000毫秒）就显示时间间隔
            if (val.data.createTime - timestamp > 300000) {
              state.recordContent.push(parseTime(val.data.createTime));
            }
        }
        val.data.confirmedUser = ''
        state.recordContent.push(val.data);
          if (chat.value.scrollHeight - (chat.value.clientHeight + chat.value.scrollTop)>chat.value.clientHeight && val.data.userId !== state.userInfo.id) {
            state.newMsgIscoming = true
            state.newMsgIscomingCount = state.newMsgIscomingCount + 1
            if (state.recordContentWeidu.length>0) {
              state.newMsgIscoming = false
              state.newMsgIscomingCount = 0
            }
          }else{
            state.newMsgIscoming = false
            state.newMsgIscomingCount = 0
            if (val.data.type === 2) {
              state.srcList.push(val.data.msg)
              state.srcListId.push(val.data.id)
              nextTick(() => {
                setTimeout(() => {
                  chat.value.scrollTo({top: chat.value.scrollHeight, behavior: 'smooth'})
                },100);
              });
            }else if(val.data.type === 3){
              state.mapSrcList.push(val.data.msg)
              state.mapSrcListId.push(val.data.id)
              setTimeout(() => {
                chat.value.scrollTo({top: chat.value.scrollHeight, behavior: 'smooth'})
              },100);
            }else{
              nextTick(()=>{
                chat.value.scrollTo({top: chat.value.scrollHeight, behavior: 'smooth'})
              })
            }
          }
      }
    );
    watch(
      () => props.yiduMsg,
      (val, prevVal) => {
        state.recordContent.forEach(e=>{
          if(e.id === val.data.id){
            e.confirmedUser = val.data.confirmedUser
          }
        })
        state.recordContent.push(val.data);
        setTimeout(() => {
          if (chat.value.scrollHeight - (chat.value.clientHeight + chat.value.scrollTop) <  chat.value.clientHeight  && state.userInfo.id !== val.data.userId){
          nextTick(()=>{
              chat.value.scrollTo({top: chat.value.scrollHeight, behavior: 'smooth'})
            })
          }
        }, 100);
      }
    );
    state.config = computed(()=>{
    return { token:state.token }
    })
    const myScrollbar = ref(null);
    const uploadRef = ref(null);
    const chat = ref(null);
    const methods = reactive({
      //获取历史聊天记录
      queryGroupChats: async () => {
        let srcList = []
        let srcListId = []
        let mapSrcList = []
        let mapSrcListId = []
        let res = await chatV1.queryGroupChats(currGroupId.value,1);
        if (res.resultStatus) {
          // state.recordContent = res.resultData.data;
          res.resultData.data.forEach((element,index,arr) => {
            if (element.confirmedUser === null && element.type === 1) {
              element.confirmedUser = ''
            }
            if (element.confirmUser.split(',').includes(state.userInfo.id) && element.type === 1 &&!element.confirmedUser.split(',').includes(state.userInfo.id)) {
              state.recordContentWeidu.push(element)
            }
            if (element.confirmUser !== '' && element.type === 1 && element.userId === state.userInfo.id) {
              state.recordContentMyAite.push(element)
            }
            if (element.confirmUser === '' && element.type === 2) {
              srcList.push(element.msg)
              srcListId.push(element.id)
            }
            if (element.confirmUser === '' && element.type === 3) {
              mapSrcList.push(element.msg)
              mapSrcListId.push(element.id)
            }
          });
          let arrIndex = []
          let deepData = JSON.parse(JSON.stringify(res.resultData.data))
          for (let i = 0; i < deepData.length - 1; i++) {
            //第一条直接是日期加时间
            if (i === 0) {
              //把时间转成时间戳 ---》判断是否是今天
              let fristTime = Date.parse(new Date(deepData[i].createTime).toString());
              if (new Date(fristTime).toDateString() === new Date().toDateString()) {
                  //今天
                  deepData.unshift(parseTime(deepData[i].createTime))
              }else{
                //不是今天
                  deepData.unshift(deepData[i].createTime)
              }
            }else{
              let timestamp2 = ''
              let timestamp = ''
              timestamp = Date.parse(new Date(deepData[i].createTime).toString());
              timestamp2 = Date.parse(new Date(deepData[i+1].createTime).toString());
                if (timestamp2 - timestamp > 300000) {
                  let obj = {}
                  obj.time = timestamp2,
                  obj.timeDay = deepData[i+1].createTime,
                  obj.timeIndex = i
                  arrIndex.push(obj)
                }
            }
          }
          for (let a = arrIndex.length-1; a >= 0; a--) {
            if (new Date(arrIndex[a].time).toDateString() === new Date().toDateString()) {
              deepData.splice(arrIndex[a].timeIndex + 1,0,parseTime(arrIndex[a].time))
            }else{
              deepData.splice(arrIndex[a].timeIndex + 1,0,arrIndex[a].timeDay)
            }
          }
          state.recordContent = deepData.concat(state.recordContent)
          state.mapSrcList = mapSrcList.concat(state.mapSrcList)
          state.mapSrcListId = mapSrcListId.concat(state.mapSrcListId)
          state.srcList = srcList.concat(state.srcList)
          state.srcListId = srcListId.concat(state.srcListId)
        }
        nextTick(() => {
          setTimeout(() => {
            chat.value.scrollTop = 20
            chat.value.scrollTo({top: chat.value.scrollHeight, behavior: 'smooth'})
          //  chat.value.scrollTop = chat.value.scrollHeight;
          },100);
        });
      },
      //加载获取其他页的数据
      queryGroupChatsOther: async (pageNum) => {
        let srcList = []
        let srcListId = []
        let mapSrcList = []
        let mapSrcListId = []
        let daheight = chat.value.scrollHeight
        let res = await chatV1.queryGroupChats(currGroupId.value,pageNum);
        if (res.resultStatus) {
          state.nowPageNum = state.nowPageNum + 1
          res.resultData.data.forEach((element,index,arr) => {
            if (element.confirmedUser === null && element.type === 1) {
              element.confirmedUser = ''
            }
            if (element.confirmUser.split(',').includes(state.userInfo.id) && element.type === 1 &&!element.confirmedUser.split(',').includes(state.userInfo.id)) {
              state.recordContentWeidu.push(element)
            }
            if (element.confirmUser !== '' && element.type === 1 && element.userId === state.userInfo.id) {
              state.recordContentMyAite.push(element)
            }
            if (element.confirmUser === '' && element.type === 2) {
              srcList.push(element.msg)
              srcListId.push(element.id)
            }
            if (element.confirmUser === '' && element.type === 3) {
              mapSrcList.push(element.msg)
              mapSrcListId.push(element.id)
            }
          });
          let arrIndex = []
          let deepData = JSON.parse(JSON.stringify(res.resultData.data))
          for (let i = 0; i < deepData.length - 1; i++) {
            if (i === 0) {
              //把时间转成时间戳 ---》判断是否是今天
              let fristTime = Date.parse(new Date(deepData[i].createTime).toString());
              if (new Date(fristTime).toDateString() === new Date().toDateString()) {
                  //今天
                  deepData.unshift(parseTime(deepData[i].createTime))
              }else{
                // 不是今天
                  deepData.unshift(deepData[i].createTime)
              }
            }else{
              let timestamp2 = ''
              let timestamp = ''
              timestamp = Date.parse(new Date(deepData[i].createTime).toString());
              timestamp2 = Date.parse(new Date(deepData[i+1].createTime).toString());
                if (timestamp2 - timestamp > 300000) {
                  let obj = {}
                  obj.time = timestamp2,
                  obj.timeDay = deepData[i+1].createTime,
                  obj.timeIndex = i
                  arrIndex.push(obj)
                }
          }
        }
        for (let a = arrIndex.length-1; a >= 0; a--) {
          if (new Date(arrIndex[a].time).toDateString() === new Date().toDateString()) {
              deepData.splice(arrIndex[a].timeIndex + 1,0,parseTime(arrIndex[a].time))
            }else{
              deepData.splice(arrIndex[a].timeIndex + 1,0,arrIndex[a].timeDay)
            }
          }
          state.recordContent = deepData.concat(state.recordContent)
          state.mapSrcList = mapSrcList.concat(state.mapSrcList)
          state.mapSrcListId = mapSrcListId.concat(state.mapSrcListId)
          state.srcList = srcList.concat(state.srcList)
          state.srcListId = srcListId.concat(state.srcListId)
          nextTick(()=>{
            state.loading = false
            chat.value.scrollTop = chat.value.scrollHeight - daheight - 50
          })
      }
    
    },
      //发送图片  之前的校验
      beforeAvatarUpload: (rawFile) => {
        state.chooseType.pic = false;
        // 图片格式
        const imgType = "image/jpeg,image/jpg,image/png,image/gif,image/tif,image/bmp,image/dds,image/svg".includes(rawFile.type);
        if (!imgType || rawFile.type === '') {
          ElNotification({
            title: t("130"),
            message: t("907"),
            type: "error",
          });
          return false;
        }
        return true;
      },
      //上传成功
      handleAvatarSuccess(data){
        const enterControl = {
          action: "GROUP_COMM",
          code: "TEXT_MSG",
          groupCode: currGroupId.value, //群组id
          data: {
            type: 2, //0-普通消息，1-@消息，2-任务消息
            confirmUser: "", //被@成员列表 成员id逗号隔开。（若不是@消息，则该字段为空字符串）
            level: 1, //消息等级
            userId: state.userInfo.id, // 用户id（消息发送人）
            userName: state.userInfo.username, // 用户id（消息发送人）
            avatar: state.userInfo.avatar, // 用户id（消息发送人）
            msg: data.resultData.url, //消息内容
          },
        };
        websocketsend(enterControl);
      },
      //接收被@的用户
      aiteEd(data,dataId) {
        state.chooseType.user = false;
        for (let i = 0; i < data.length; i++) {
            let spanNode1 = document.createElement('span')
            let spanNode2 = document.createElement('span')
            spanNode1.className = 'at-text'
            spanNode1.style.color = '#049CFF'
            spanNode1.innerHTML = '@' + data[i]
            spanNode1.id = dataId[i]
            //  设置@人的节点不可编辑
            spanNode1.contentEditable = false
            // spanNode2.innerHTML = ' '
            spanNode2.innerHTML = '&nbsp;'
            let frag = document.getElementById('divText'),
                node, lastNode;
            frag.appendChild(spanNode1)
            while ((node = spanNode2.firstChild)) {
              lastNode = frag.appendChild(node)
            }
        }
        state.handleaiteShow = false;
        //把光标移至最后
        if (window.getSelection) {//ie11 10 9 ff safari
          divTextRef.value.focus(); //解决ff不获取焦点无法定位问题
          var range = window.getSelection();//创建range
          range.selectAllChildren(divTextRef.value);//range 选择obj下所有子内容
          range.collapseToEnd();//光标移至最后
        }
        else if (document.selection) {//ie10 9 8 7 6 5
            var range = document.selection.createRange();//创建选择对象
            //var range = document.body.createTextRange();
            range.moveToElementText(divTextRef.value);//range定位到obj
            range.collapse(false);//光标移至最后
            range.select();
        }
      },
      //点击 ‘提到了你’
      aiteYouself(){
        state.newMsgIscoming = false
        state.newMsgIscomingCount = 0
        let deleteAiteMsg = ''
        let sendMsgReplace = state.recordContentWeidu[0].msg.replace(/\s/g,",").split(',')
        sendMsgReplace.forEach(e=>{
          if (e.search('@') === -1) {
            deleteAiteMsg = e
          }
        })
        nextTick(() => {
          document.getElementsByClassName('weidu-content')[0].scrollIntoView({
            behavior: "smooth",  // 平滑过渡
            block:    "center"    // 上边框与视窗顶部平齐
          });
        });
        const weiduControl = {
          action: "CONFIRM_READ",
          code: "CONFIRM_MSG",
          groupCode: currGroupId.value, //群组id
          data: {
            userId: state.userInfo.id, // 用户id
            senderId: state.recordContentWeidu[0].userId, // 用户id
            id: state.recordContentWeidu[0].id, //未读信息id
            msg: deleteAiteMsg, //未读信息id
            userName: state.userInfo.username, //未读信息id
          },
        };
        websocketsend(weiduControl);
        state.recordContentWeidu.shift()
      },
      //点击 ‘提到了你的关闭符号 大致与点击提到了你相同  但是这个不跳转’
      handleClose(){
        let deleteAiteMsg = ''
        let sendMsgReplace = state.recordContentWeidu[0].msg.replace(/\s/g,",").split(',')
        sendMsgReplace.forEach(e=>{
          if (e.search('@') === -1) {
            deleteAiteMsg = e
          }
        })
        const weiduControl = {
          action: "CONFIRM_READ",
          code: "CONFIRM_MSG",
          groupCode: currGroupId.value, //群组id
          data: {
            userId: state.userInfo.id, // 用户id
            senderId: state.recordContentWeidu[0].userId, // 用户id
            id: state.recordContentWeidu[0].id, //未读信息id
            msg: deleteAiteMsg, //未读信息id
            userName: state.userInfo.username, //未读信息id
          },
        };
        websocketsend(weiduControl);
        state.recordContentWeidu.shift()
      },
      //点击查看已读信息
      showYidu(data){
        state.recordContentMyAite.forEach((e,index)=>{
          if(e.id === data.id){
            nextTick(() => {
              document.getElementsByClassName('infoAite-content')[index].scrollIntoView({
                behavior: "smooth",  // 平滑过渡
                block:    "center"    // 上边框与视窗顶部平齐
              });
            });
          }
        })
      },
      //发送图片
      choosePic: async () => {
        state.chooseType.pic = !state.chooseType.pic;
        setTimeout(() => {
          state.chooseType.pic = false;
        }, 500);
      },
      //@用户
      chooseUser: async () => {
        let aiteSpanDom = document.getElementById('divText').getElementsByTagName("span")
        state.aiteEdList = []
        if (aiteSpanDom.length !== 0) {
          for (let i = 0; i < aiteSpanDom.length; i++) {
            state.aiteEdList.push(aiteSpanDom[i].id)
          }
        }
        state.aiteCurrGroupId = currGroupId.value
        state.handleaiteShow = !state.handleaiteShow;
        state.chooseType.user = !state.chooseType.user;
      },
      //发送普通信息和@信息
      sendBtn: async () => {
        if(divTextRef.value.textContent === ''){
          ElMessage.warning(t("908"));
        
        }else if(divTextRef.value.textContent.trim().length === 0){
          ElMessage.warning(t("908"));
          divTextRef.value.textContent = "";
        }else{
          let aiteSpanDom = document.getElementById('divText').getElementsByTagName("span")
          let aiteEdLists = []
          if (aiteSpanDom.length !== 0) {
            for (let i = 0; i < aiteSpanDom.length; i++) {
              aiteEdLists.push(aiteSpanDom[i].id)
            }
          }
          let aiteEdListsEd = aiteEdLists.toString()
          const enterControl = {
            action: "GROUP_COMM",
            code: "TEXT_MSG",
            groupCode: currGroupId.value, //群组id
            data: {
              type: (!aiteEdListsEd)? 0 : 1, //0-普通消息，1-@消息，2-任务消息
              confirmUser: aiteEdListsEd, //被@成员列表 成员id逗号隔开。（若不是@消息，则该字段为空字符串）
              level: 1, //消息等级
              userId: state.userInfo.id, // 用户id（消息发送人）
              userName: state.userInfo.username, // 用户id（消息发送人）
              avatar: state.userInfo.avatar, // 用户id（消息发送人）
              msg: divTextRef.value.textContent, //消息内容
            },
          };
          websocketsend(enterControl);
          divTextRef.value.textContent = "";
        }
      },
      /* 时间戳转换为时间 */
      timestampToTime(timestamp) {
        timestamp = timestamp ? timestamp : null;
        let date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
        let Y = date.getFullYear() + '-';
        let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
        let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
        let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
        let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
        return Y + M + D + h + m + s;
      },
      //关闭有消息来了的通知
      handleNewMsgClose(){
        state.newMsgIscoming = false
        state.newMsgIscomingCount = 0
      },
      //查看最新的消息
      showNewMsg(){
        nextTick(() => {
          setTimeout(() => {
            chat.value.scrollTo({top: chat.value.scrollHeight, behavior: 'smooth'})
            state.newMsgIscoming = false
            state.newMsgIscomingCount = 0
            },100);
          });
      },
      // 输入中文开始时
      compositStart(e) {
        state.lock = false;
        document.getElementById("divText").focus();
        document.execCommand("selectAll", false, null);
        document.getSelection().collapseToEnd();
      },
      // 输入中文结束时
      compositEnd(e) {
        state.lock = true;
        methods.divInput(e);
      },
      //divtext 输入事件
      divInput(e){
        let spanLength = 0
        let onlySpanLength = 0
          state.aiteLength = document.getElementById('divText').getElementsByTagName("span").length
            if (state.aiteLength !== 0) {
              for (let i = 0; i < state.aiteLength; i++) {
                spanLength = e.srcElement.children[i].outerHTML.length + 6 + spanLength
                onlySpanLength = e.srcElement.children[i].innerText.length + 1 + onlySpanLength
            }
            }
        if(state.lock) {
          state.textSize = e.target.innerText.length - onlySpanLength
          if(state.textSize >= 200) {
            state.textSize = 200;
            ElMessage.warning(t("909"));
            // state.fullContent = e.target.innerText.substring(0, 200);
            e.target.innerHTML = e.target.innerHTML.substring(0,spanLength+state.textSize);
            methods.textFocus();
          } else {
            state.fullContent = ''
          }
        } else if (state.fullContent) {
          // 目标对象：超过200字时候的中文输入法
          // 原由：虽然不会输入成功，但是输入过程中字母依然会显现在输入框内
          // 弊端：谷歌浏览器输入法的界面偶尔会闪现
          e.target.innerText = state.fullContent;
          state.lock = true;
          methods.textFocus();
        }
      },
      // 文本输入框聚焦，焦点在最后位置
      textFocus() {
        setTimeout(() => {
          document.getElementById("divText").focus();
          document.execCommand("selectAll", false, null);
          document.getSelection().collapseToEnd();
        }, 0);
      },
      // 粘贴富文本转为纯文本
      copyText(e) {
        e.stopPropagation();
        e.preventDefault();
        let text = '', event = (e.originalEvent || e);
        if (event.clipboardData && event.clipboardData.getData) {
          text = event.clipboardData.getData('text/plain');
        } else if (window.clipboardData && window.clipboardData.getData) {
          text = window.clipboardData.getData('Text');
        }
        if (document.queryCommandSupported('insertText')) {
          document.execCommand('insertText', false, text);
        } else {
          document.execCommand('paste', false, text);
        }
        let spanLength = 0
        let onlySpanLength = 0
          state.aiteLength = document.getElementById('divText').getElementsByTagName("span").length
            if (state.aiteLength !== 0) {
              for (let i = 0; i < state.aiteLength; i++) {
                spanLength = e.srcElement.children[i].outerHTML.length + 6 + spanLength
                onlySpanLength = e.srcElement.children[i].innerText.length + 1 + onlySpanLength
            }
            }
        if(state.lock) {
          state.textSize = e.target.innerText.length - onlySpanLength
          if(state.textSize >= 200) {
            state.textSize = 200;
            ElMessage.warning(t("909"));
            // state.fullContent = e.target.innerText.substring(0, 200);
            e.target.innerHTML = e.target.innerHTML.substring(0,spanLength+state.textSize);
            methods.textFocus();
          } else {
            state.fullContent = ''
          }}
      },
    });

    // 公告
    const isNotice = ref(false);
    watch(()=>props.subNotice,(newVal)=>{
      if(newVal){
        isNotice.value = true;
      }else{
        isNotice.value = false;
      }

    })
    const submitNotice = () => {
      emit("submitNotice");
      isNotice.value = false;
    }
    watch(
      () => props.trueNotice,
      (notice) => {
        state.recordContent.push(notice.data);
        if (chat.value.scrollHeight - (chat.value.clientHeight + chat.value.scrollTop) <  chat.value.clientHeight){
          nextTick(() => {
            chat.value.scrollTop = chat.value.scrollHeight;
          });
        }
      }
    );
    
    //前往标记点
    const goMarkedPoint = (data) => {
      emit("goMarkedPoint",data);
    }

    //打开用户中心
    const openUserInfo = (data) => {
      emit("openUserInfo",'userinfo',data.userId);
    }
    return {
      ...toRefs(state),
      ...toRefs(methods),
      myScrollbar,
      uploadRef,
      chat,
      isNotice,
      submitNotice,
      goMarkedPoint,
      openUserInfo,
      divTextRef
    };
  },
});
</script>
<style lang="less" scoped>
/* 整个滚动条 */
::-webkit-scrollbar {
    /* 对应纵向滚动条的宽度 */
    width: 6px;
}

::-webkit-scrollbar-thumb {
	border-radius: 10px;
	border-style: dashed;
	border-color: transparent;
	border-width: 2px;
	background-color: #049cff;
	background-clip: padding-box;
}

/* 整个滚动条 */::-webkit-scrollbar-thumb:hover {
  width: 12px;
	background: #049cff;
} 
/* 滚动条上的滚动滑块 */
::-webkit-scrollbar-thumb {
    background-color: #49b1f5;
    border-radius: 32px;
}



.msg_content {
  width: 100%;
  height: 70%;
  position: relative;
  .loading{
    width: 100%;
    height: 20px;
    line-height: 20px;
    text-align: center;
    /deep/ .el-loading-mask{
      background: none;
    }
    /deep/ .el-loading-spinner{
      top: 100%;
    }
    /deep/ .el-loading-spinner .circular{
      width: 1.25rem;
      height: 1.25rem;
    }
    /deep/ .el-loading-spinner .path{
      stroke: #0b659f;
    }
  }
  .chat-content {
    width: 100%;
    padding: 0.625rem 1.25rem;
    // padding-bottom: 3.5rem;
    position: relative;
    height: 100%;
    max-height: 500px;
    overflow-y: auto;
    .word {
      display: flex;
      align-items: flex-end;
      padding-bottom: 1.5rem;
      padding-top: 1.5rem;
      img {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        cursor: pointer;
      }
      .divAvatar{
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        line-height: 2.5rem;
        text-align: center;
        display: inline-block;
        background: #000;
        cursor: pointer;
      }
      .info {
        width: 90%;
        display: flex;
        justify-content: flex-start;
        position: relative;
        .time {
          font-size: 0.875rem;
          color: #fff;
          margin: 0;
          height: 1.25rem;
          line-height: 1.25rem;
          position: absolute;
          top: -1.5625rem;
          left: 3.6375rem;
        }
        .weidu-content {
          height: 40px;
          width: auto;
          font-size: 0.875rem;
          text-align: center;
          background: #049cff;
          position: relative;
          color: #fff;
          border-radius: 4px;
          line-height: 40px;
          padding: 0px 12px;
          margin-left: 10px;
        }
        .yidu-content {
          height: 40px;
          width: auto;
          font-size: 0.875rem;
          text-align: center;
          background: #154d72;
          position: relative;
          color: #fff;
          border-radius: 4px;
          line-height: 40px;
          padding: 0px 12px;
          margin-left: 10px;
        }
        .info-content {
          max-width: 50%;
          padding: 12px;
          font-size: 0.875rem;
          background: #fff;
          position: relative;
          color: #000;
          margin-left: 0.9375rem;
          border-radius: 4px;
          word-wrap: break-word;
          word-break: break-all;
        }
        .img-content {
          width: 10.625rem;
          // height: 9.375rem;
          font-size: 0.875rem;
          float: right;
          margin-left: 0.9375rem;
          position: relative;
          border-radius: 4px;
          /deep/ .el-image__preview{
          border-radius: 4px;
          }
          img {
            max-width: 100%;
            max-height: 100%;
            border-radius: 4px;
          }
        }
        .img-content:after,.img-content:before{
        content: "";
        display: table;
        }
        .img-content:after{
            clear: both;
        }
        .img-content{
            *zoom: 1;
        }
        .map-content {
          width: 14.925rem;
          font-size: 0.875rem;
          float: right;
          margin-left: 0.9375rem;
          position: relative;
          border-radius: 4px;
          background: #049cff;
          .map_pic{
            width: 14.925rem;
            height: 7.0625rem;
            /deep/ .el-image{
              max-width: 100%;
              max-height: 100%;
            }
            /deep/ .el-image__inner {
              border-top-left-radius:4px;
              border-top-right-radius:4px;
              width: 14.925rem;
              height: 7.0625rem;
            }
          }
          .map_msg{
            width: 100%;
            height: 1.7875rem;
            display: flex;
            .map_name{
              width: 75%;
              text-align: left;
              font-size: 0.75rem;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              span{
                width: 90%;
                display: inline-block;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
              }
            }
            .map_option{
              width: 12%;
              height: 100%;
              position: relative;
              display: flex;
              justify-content: center;
              align-items: center;
              .white_btn{
                color: #fff;
                cursor: pointer;
                font-size: 1.1rem;
              }
              .red_btn{
                color: #ec4844;
                cursor: pointer;
                font-size: 1.1rem;
              }
            }
          }
        }
        .map-content::after{
          position: absolute;
          left: -6px;
          top: 5px;
          content: "";
          border-right: 6px solid #fff;
          border-top: 6px solid transparent;
          border-bottom: 8px solid transparent;
        }
        .map-content:after,.map-content:before{
        content: "";
        display: table;
        }
        .map-content:after{
            clear: both;
        }
        .map-content{
            *zoom: 1;
        }
        //小三角形
        .info-content::before {
          position: absolute;
          left: -8px;
          top: 8px;
          content: "";
          border-right: 10px solid #fff;
          border-top: 8px solid transparent;
          border-bottom: 8px solid transparent;
        }
      }
    }

    .word-my {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-bottom: 1.5rem;
      padding-top: 1.5rem;
      img{
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        cursor: pointer;
      }
      .divAvatar{
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        line-height: 2.5rem;
        text-align: center;
        display: inline-block;
        background: #000;
        cursor: pointer;
      }
      .info {
        width: 90%;
        margin-left: 10px;
        display: flex;
        justify-content: flex-end;
        position: relative;
        .time {
          font-size: 0.875rem;
          color: #fff;
          height: 1.25rem;
          line-height: 1.25rem;
          position: absolute;
          top: -1.5625rem;
          right: 3.5375rem;
        }
        .weidu-content {
          width: auto;
          height: 40px;
          font-size: 0.875rem;
          text-align: center;
          background: #049cff;
          position: relative;
          color: #fff;
          border-radius: 4px;
          line-height: 40px;
          padding: 0px 12px;
          margin-right: 10px;
        }
        .info-content {
          max-width: 50%;
          padding: 12px;
          font-size: 0.875rem;
          margin-right: 0.9375rem;
          position: relative;
          background: #049cff;
          text-align: left;
          border-radius: 4px;
          word-wrap: break-word;
          word-break: break-all;
        }
        .infoAite-content {
          max-width: 50%;
          padding: 12px;
          font-size: 0.875rem;;
          margin-right: 0.9375rem;
          position: relative;
          background: #049cff;
          text-align: left;
          border-radius: 4px;
          word-wrap: break-word;
          word-break: break-all;
        }
        .img-content {
          width: 10.625rem;
          font-size: 0.875rem;
          float: right;
          margin-right: 0.9375rem;;
          position: relative;
          border-radius: 4px;
          overflow: hidden;
          /deep/ .el-image__preview{
          border-radius: 4px;
          }
          img {
            max-width: 100%;
            max-height: 100%;
            border-radius: 4px;
          }
        }
        .img-content:after,.img-content:before{
        content: "";
        display: table;
        }
        .img-content:after{
            clear: both;
        }
        .img-content{
            *zoom: 1;
        }
        .map-content {
          width: 14.925rem;
          font-size: 0.875rem;
          float: right;
          margin-right: 0.9375rem;;
          position: relative;
          border-radius: 4px;
          background: #049cff;
          .map_pic{
            width: 14.925rem;
            height: 7.0625rem;
            /deep/ .el-image{
              max-width: 100%;
              max-height: 100%;
            }
            /deep/ .el-image__inner {
             border-top-left-radius:4px;
             border-top-right-radius:4px;
             width: 14.925rem;
             height: 7.0625rem;
            }
          }
          .map_msg{
            width: 100%;
            height: 1.7875rem;
            display: flex;
            .map_name{
              width: 75%;
              text-align: left;
              font-size: 0.75rem;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              span{
                width: 90%;
                display: inline-block;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
              }
            }
            .map_option{
              width: 12%;
              height: 100%;
              position: relative;
              display: flex;
              justify-content: center;
              align-items: center;
              .white_btn{
                color: #fff;
                cursor: pointer;
                font-size: 1.1rem;
              }
              .red_btn{
                color: #ec4844;
                cursor: pointer;
                font-size: 1.1rem;
              }
            }
          }
        }
        .map-content::after{
          position: absolute;
          right: -6px;
          top: 5px;
          content: "";
          border-left: 6px solid #049cff;
          border-top: 6px solid transparent;
          border-bottom: 8px solid transparent;
        }
        .map-content:after,.map-content:before{
        content: "";
        display: table;
        }
        .map-content:after{
            clear: both;
        }
        .map-content{
            *zoom: 1;
        }
        //小三角形
        .info-content::after {
          position: absolute;
          right: -8px;
          top: 8px;
          content: "";
          border-left: 10px solid #049cff;
          border-top: 8px solid transparent;
          border-bottom: 8px solid transparent;
        }
      }
    }
    .word-time {
      text-align: center;
      padding-bottom: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      .time_content {
        width: auto;
        height: 1.25rem;
        font-size: 0.75rem;
        // background: #000;
        line-height: 1.25rem;
        text-align: center;
      }
      .notice_content{
        height: 1.25rem;
        font-size: 0.75rem;
        line-height: 1.25rem;
        text-align: center;
        color: #c3c3c3;
      }
    }
    .word-notice {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #c3c3c3;
      padding-bottom: 1rem;
      .notice_content {
        width: auto;
        height: 1.25rem;
        font-size: 0.75rem;
        line-height: 1.25rem;
        text-align: center;
        max-width: 50%;
      }
      span {
        cursor: pointer;
        color: #1598f0;
        width: auto;
        height: 1.25rem;
        font-size: 0.75rem;
        line-height: 1.25rem;
        text-align: center;
        display: inline-block;
      }
    }
  }
    // 公告
  .notice_box{
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0 .625rem;
    .notice{
    border-radius: .9375rem;
    height: 1.875rem;
    line-height: 1.875rem;
    cursor: pointer;
    font-size: .75rem;
    i{
      color: #666666;
      margin: 0 .3125rem;
    }
    span{
      color: #00ff00;
      font-weight: 500;
    }
    }
  }
}
.send_content {
  width: 100%;
  height: 30%;
  border-top: 3px solid #144e6e;
  position: relative;
  .new-msg-jiantou{
    font-size: 0.1rem;
    color: #36b0ff;
    padding-right: 0.4rem;
  }
  /deep/ .el-tag.is-closable {
    cursor: pointer;
    width: auto;
    height: 2.2rem;
    position: absolute;
    top: -3rem;
    right: 0.5rem;
    background: #0b4265;
    border: #0b4265;
    box-shadow: 0px 0px 1px #e8eaed;
    color: #fff;
    font-size: 0.75rem;
    span {
      line-height: 2.2rem;
    }
  }
  /deep/.el-divider--vertical {
    height: 1.2rem;
    border-left: 1px solid #049cff;
  }
  .send_control {
    width: 100%;
    height: 2rem;
    .two_pointer {
      display: flex;
      padding-top: 0.3125rem;
      padding-left: 0.625rem;
      .icon {
        display: inline-block;
        width: 1.8rem;
        height: 1.8rem;
        line-height: 1.8rem;
        text-align: center;
        margin: auto 0px;
        margin-right: 0.8rem;
        vertical-align: middle;
        border-radius: 50%;
        background: #fff;
        color: #049cff;
      }
      .isActive {
        display: inline-block;
        width: 1.8rem;
        height: 1.8rem;
        line-height: 1.8rem;
        text-align: center;
        margin: auto 0px;
        margin-right: 0.8rem;
        vertical-align: middle;
        border-radius: 50%;
        background: #049cff;
        color: #fff;
      }
    }
  }
  .send_textare {
    width: 100%;
    height: calc(100% - 2rem);
    padding-left: 5px;
    padding-top: 10px;
    position: relative;
    #divText{
      height: 100%;
      border: none;
      height: 100%;
      background: none;
      color: #fff;
      box-shadow: none;
      padding-top: 5px;
      padding-left: 5px;
      font-size: 0.875rem;
      outline: none;
      word-break: break-all;
    }
    /deep/ .el-textarea {
      height: 100%;
      border: none;
    }
    /deep/ .el-textarea__inner {
      height: 100%;
      background: none;
      color: #fff;
      box-shadow: none;
      padding-top: 5px;
      padding-left: 5px;
      font-size: 0.875rem;
    }
    .send_btn {
      position: absolute;
      right: 4%;
      width: 4.4625rem;
      height: 1.8625rem;
      bottom: 10%;
      font-size: 0.875rem;
      color: #049cff;
      background: #004775;
      border: 1px solid #033b61;
    }
    .send_btn:hover{
      background: #fff;
    }
    .monitory-text {
      color: red;
    }
  }
  .pointer {
    cursor: pointer;
  }
  // 必要的
  //隐藏底部滚动条
  /deep/ .el-scrollbar__wrap {
    overflow: scroll;
    height: 100% !important;
    overflow-x: hidden !important;
  }
}
.aite_position {
  position: absolute;
  bottom: 30%;
  right: 10%
}
</style>