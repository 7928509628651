<template>
  <div class="headerc">
    <img src="../../../asset/img/topbg.png" class="head_img" alt />
    <h1 class="system_name" :style="{ fontSize: systemNameSize + 'rem' }">
      {{ systemName?systemName: t('807')  }}
    </h1>
    <div class="company flex-a">
      <div class="add">
        <img :src="systemLogo" alt="" v-show="logo"/>
      </div>
      <div class="companyName">{{ organizationName?organizationName:'广州智飞科技有限公司' }}</div>
    </div>
    <div class="nav flex-fe">
      <el-tooltip
        class="box-item"
        popper-class="codeOutToolTip"
        placement="bottom"
      >
        <!-- 设置 -->
        <template #content>
          <span>{{ t('808') }}</span>
        </template>
        <div style="cursor: pointer" @click="superAdmin">
          <img class="nanwid" src="../../../asset/img/setup.png" alt />
        </div>
      </el-tooltip>
      <!-- 监控中心 -->
      <el-tooltip
        class="box-item"
        popper-class="codeOutToolTip"
        placement="bottom"
      >
        <template #content>
          <span>{{ t('809') }}</span>
        </template>
          <img @click="openMonitoringCenter" style="cursor: pointer" class="nanwid" src="../../../asset/img/monitorCenter.png" alt />
      </el-tooltip>
      <!-- 聊天室 -->
      <el-tooltip
        class="box-item"
        popper-class="codeOutToolTip"
        placement="bottom"
      >
        <template #content>
          <span>{{ t('171') }}</span>
        </template>
        <img @click="chatRoomIs = true" style="cursor: pointer" class="nanwid" src="../../../asset/img/ims.png" alt />
      </el-tooltip> 
      <!-- 安卓APP -->
      <!-- <el-tooltip
        class="box-item"
        popper-class="codeOutToolTip"
        placement="left"
      >
        <template #content>
          <span>{{ t('810') }}</span>
        </template>
        <div>
          <el-popover
            placement="bottom"
            :width="125"
            trigger="click"
            popper-class="codeToolTip"
          >
            <template #reference>
              <img
                class="nanwid"
                style="cursor: pointer"
                src="../../../asset/img/code.png"
                alt
              />
            </template>
            <div class="codeImg flex-dc flex-fac">
              <img src="../../../asset/img/code-com.png" alt="" />
              <span>{{ t('811') }}APP</span>
            </div>
          </el-popover>
        </div>
      </el-tooltip> -->

      <!-- 个人中心 -->
      <el-tooltip
        class="box-item"
        popper-class="codeOutToolTip"
        placement="bottom"
      >
        <template #content>
          <span>{{ t('173') }}</span>
        </template>
        <div class="flex-a user_cursor" @click="Jump('/userCenterIndex')">
          <img class="nanwid" src="../../../asset/img/user.png" alt />
          <div class="user_name">{{ userName }}</div>
        </div>
      </el-tooltip>
      <!-- 退出登录 -->
      <el-tooltip
        class="box-item"
        popper-class="codeLogOutToolTip"
        placement="bottom"
      >
        <template #content>
          <span class="out_Span" @click="logout">{{ t('639') }}</span>
        </template>
        <div class="logOut flex-fac">
          <el-icon style="color: #36e5ff"><CaretBottom /></el-icon>
        </div>
      </el-tooltip>
    </div>
    <monitoringDialog
      :isVisible="monitoringShow"
      :isCenter="isCenter"
      @closeIsVisible="closeIsVisible"
    ></monitoringDialog>
    <chatRoom
      ref="chatRoomRef"
      v-if="chatRoomIs"
      v-model:isVisible="chatRoomIs"
    ></chatRoom>
  </div>

</template>
<script>
import { t } from '../../../languages';
import { router } from "@/router";
import { defineComponent, computed, onMounted, reactive,toRefs, ref, nextTick, onBeforeUnmount } from "vue";
import { store } from "@/store";
import syssetting from "@/network/system";
import user from "../../../network/user";
import { ElMessage } from "element-plus";
import socket from '@/utils/websocket'
import emitter from '../../../utils/mitt'
import monitoringDialog from "@/views/monitoring-center/monitoringDialog.vue";
import chatRoom from "@/views/chat-room/index.vue";

export default defineComponent({
  components:{
    monitoringDialog,
    chatRoom,
  },
  setup() {
    const { openSocketConnect, closeSocketConnect } = socket();

    let Token = localStorage.getItem("token"); //有token刷新页面重连websocket
    Token && openSocketConnect();

    onMounted(() => {
      getSystemMessage();
      getSystemLogo();
    });
    const state = reactive({
      systemNames: "",
      organizationNames: "",
      systemNameSizes: "",
      logo: "",
      monitoringShow:false,
      isCenter:true,
    });
    const systemName = computed(() => {
      if (store.state.system.systemInfo.systemName !== "") {
        return store.state.system.systemInfo.systemName;
      } else {
        return state.systemNames;
      }
    });
    const systemLogo = computed(() => {
      if (store.state.system.systemLogo !== "") {
        return store.state.system.systemLogo;
      } else {
        //取接口获取的数据
        return state.logo;
      }
    });
    const systemNameSize = computed(() => {
      if (store.state.system.systemNameSize !== "") {
        return store.state.system.systemNameSize;
      } else {
        return state.systemNameSizes;
      }
    });
    const organizationName = computed(() => {
      if (store.state.system.systemInfo.organizationName !== "") {
        //取vuex的数据
        return store.state.system.systemInfo.organizationName;
      } else {
        //取接口获取的数据
        return state.organizationNames;
      }
    });
    
    const userName = computed(() => {
      if (typeof store.state.user.userInfo.username !== "undefined") {
        //取vuex的数据
        return store.state.user.userInfo.username;
      } else {
        //取localStorage数据
        const userInfoJson = localStorage.getItem("userInfo");
        if (userInfoJson !== null) {
          // 设置vuex用户数据
          const userInfo = JSON.parse(userInfoJson);
          store.commit("setUserInfo", userInfo);
          return userInfo.username;
        } else {
          return t("80");
        }
      }
    });
    // const userInfo = localStorage.getItem('userInfo');

    const getSystemMessage = async () => {
      const res = await syssetting.getSystemData();
      if(!res.resultStatus) return;
      state.systemNames = res.resultData.systemName;
      state.organizationNames = res.resultData.organizationName;
      state.systemNameSizes = res.resultData.systemNameSize;
      store.dispatch("openRegion", res.resultData.openRegion);
      store.dispatch("defaultRegion", res.resultData.defaultRegion);
      emitter.emit('getDefaultRegion');
      emitter.emit('getOpenRegion');
    };
    const getSystemLogo = async () => {
      const res = await syssetting.getLogoURL();
      if(!res.resultStatus) return;
      state.logo = res.resultData;
      state.logo = state.logo
        ? state.logo
        : '';
    };
    //前往个人中心
    const Jump = (path) => {
      router.push(path);
    };
    // 退出登录
    const logout = async () => {
      let res = await user.loginOut();
      if (res.resultStatus) {
        closeSocketConnect();
        ElMessage.success(t("643"));
        router.push("/");
      }
      localStorage.removeItem("token");
      localStorage.removeItem("menusId");
    };
    // 暂时限制不是超级管理员不能进系统设置
    const superAdmin = () => {
      if (store.state.user.userInfo.type != "SUPPER_ADMIN") {
        ElMessage.warning(t("812"));
      } else {
        router.push("/systemSettingIndex");
      }
    };
    emitter.on("openMonitoring", () => {
      setTimeout(() => {
        state.monitoringShow = true
      }, 200);
    });
    const openMonitoringCenter = ()=>{
      emitter.emit('stopJessibuca')
      state.monitoringShow = true
    }
    const closeIsVisible = () => {
      state.monitoringShow = false
    }

    const chatRoomIs = ref(false)
    const chatRoomRef = ref(null)
    emitter.on("open-chatRoom", (data) => {
        if(chatRoomIs.value === true){
          // 聊天室活跃中
          chatRoomRef.value.sendLocation(true, data);
        } else {
          // 聊天室处于非活跃状态
          chatRoomIs.value = true;
          nextTick(()=>{
            chatRoomRef.value.sendLocation(false, data);
          })
        }
    });

    onBeforeUnmount(()=>{
      emitter.off("open-chatRoom");
    })
    
    return {
      systemName,
      organizationName,
      systemLogo,
      systemNameSize,
      userName,
      getSystemLogo,
      getSystemMessage,
      logout,
      superAdmin,
      Jump,
      openMonitoringCenter,
      closeIsVisible,
      ...toRefs(state),
      chatRoomIs,
      chatRoomRef,
    };
  },
});
</script>

<style>
/* 修改二维码样式 */
.codeToolTip {
  background: #015469 !important;
  border: 1px solid #015469 !important;
}
.codeToolTip.el-popper.is-light .el-popper__arrow::before {
  border: 1px solid #015469 !important;
  background: #015469 !important;
}
.codeToolTip.el-popover.el-popper {
  min-width: 125px !important;
}
/* 修改退出登录样式 */
.codeOutToolTip {
  background: #015469 !important;
  border: 1px solid #015469 !important;
}
.codeOutToolTip.el-popper.is-dark .el-popper__arrow::before {
  border: 1px solid #015469;
  background: #015469;
}
.codeOutToolTip.el-popper.is-light .el-popper__arrow::before {
  border: 1px solid #015469;
  background: #015469;
}
.codeOutToolTip.el-popover.el-popper {
  min-width: 125px !important;
  height: 3.75rem !important;
}
.codeLogOutToolTip{
   background: #015469 !important;
  border: 1px solid #015469 !important;
}
.codeLogOutToolTip.el-popper.is-dark .el-popper__arrow::before {
  border: 1px solid #015469;
  background: #015469;
}
.codeLogOutToolTip.el-popper.is-light .el-popper__arrow::before {
  border: 1px solid #015469;
  background: #015469;
}
.codeLogOutToolTip.el-popover.el-popper {
  min-width: 150px !important;
}
</style>
<style lang="less" scoped>
.headerc {
  position: relative;
  width: 100%;
  height: 5.1875rem;
}
.head_img {
  width: 100%;
  height: 100%;
}
.system_name {
  position: absolute;
  line-height: 4.1875rem;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  font-weight: bold;
  letter-spacing: 0.375rem;
  text-align: center;
  text-shadow: 0px 7px 2px rgba(10, 95, 130, 0.5);
  background: rgba(255, 255, 255, 1);
  color: #fff;
  -webkit-background-clip: text;
}
.company {
  position: absolute;
  left: 1.25rem;
  top: 0.5rem;
  width: 40.4375rem;
  height: 4.6875rem;
  .add {
    width: 3.9875rem;
    height: 2.0625rem;
    border-radius: 0.5rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .companyName {
    margin-left: 1.25rem;
    color: #ffffff;
    font-size: 1.25rem;
  }
}
.user_cursor {
  cursor: pointer;
}
.nav {
  position: absolute;
  right: 1.875rem;
  top: 0.5rem;
  width: 40.375rem;
  height: 4.6875rem;
  .nanwid {
    display: block;
    width: 2.25rem;
    height: 2.25rem;
    margin-right: 1.0625rem;
  }
}
.user_name {
  color: @heightLight;
}
//1600px
// @media screen and (max-width: 100rem) {
//   .system_name {
//     font-size: 36px !important;
//   }
// }
// //1400px
// @media screen and (max-width: 87.5rem) {
//   .system_name {
//     font-size: 1.5rem !important;
//   }
// }
// //1200px
// @media screen and (max-width: 75rem) {
//   .system_name {
//     font-size: 1.5rem !important;
//   }
// }
// //1000px
// @media screen and (max-width: 62.5rem) {
//   .system_name {
//     font-size: 1.25rem !important;
//   }
// }
// // 800px
// @media screen and (max-width: 50rem) {
//   .system_name {
//     font-size: 1.25rem !important;
//   }
// }
.codeImg {
  padding: 0.625rem 0.625rem 0 0.625rem;
  img {
    width: 83px;
    height: 83px;
  }
  span {
    margin-top: 0.6875rem;
    display: inline-block;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
  }
}
.logOut {
  width: 1.875rem;
  height: 2.5rem;
  margin-left: 0.625rem;
  cursor: pointer;
  // .el-icon {
  // margin-left: 1.5rem;
  // }
}
.out_Span {
  cursor: pointer;
  width: 3.75rem;
  height: 1.875rem;
  line-height: 1.875rem;
  text-align: center;
}
</style>