<template>
  <div class="menus">
    <ul>
      <li
        v-for="(item, index) in dataList"
        :key="index"
        @click="Jump(item.path, item.id)"
      >
        <img
          :src="
            (item.id == imgId && item.path == $route.matched[1].path) ||
            (item.id == 4 && ($route.matched[1].path == '/deviceManageIndex'||$route.matched[1].path == '/otherAsset')) ||
            (item.id == 5 && ($route.matched[1].path == '/roleOrder'||$route.matched[1].path == '/teamManage'))
              ? item.url1
              : item.url
          "
          alt=""
        />
        <div class="title">{{ item.title }}</div>
      </li>
    </ul>
  </div>
</template>

<script>
import { t } from '../../../languages';
import { router } from "@/router";
import { reactive, toRefs, defineComponent, onMounted } from "vue";
// import { deepCopy } from "@/utils/utilFun.ts";
export default defineComponent({
  // mounted() {
  //   for (let i = 0; i < routeList.length; i++) {
  //     if(routeList[i].meta.id===this.dataList[i].id){
  //       this.dataList[i].meta.push(routeList[i].meta)
  //     }
  //   }
  // },
  // props:['imgId'],
  setup() {
    // let hashL=window.location.hash;
    // console.log(hashL)
    // watch(hashL,(newName,oldName)=>{
    //   console.log(newName);
    //   console.log(oldName);
    // })
    const state = reactive({
      imgId: 1,
      dataList: [
        {
          id: 1,
          title: "",
          path: "/homeIndex",
          url: require("../../../asset/img/home.png"),
          url1: require("../../../asset/img/home_bg1.png"),
        },
        {
          id: 2,
          title: t("154"),
          path: "/commandcenterIndex",
          url: require("../../../asset/img/command.png"),
          url1: require("../../../asset/img/command_bg.png"),
        },
        {
          id: 3,
          title: t("155"),
          path: "/dataMangeIndex",
          url: require("../../../asset/img/data.png"),
          url1: require("../../../asset/img/data_1.png"),
        },
        {
          id: 6,
          title: t("158"),
          path: "/taskcenter",
          url: require("../../../asset/img/person.png"),
          url1: require("../../../asset/img/person_bg.png"),
        },
        {
          id: 4,
          title: t("163"),
          path: "/airport",
          url: require("../../../asset/img/equipment.png"),
          url1: require("../../../asset/img/requiment_1.png"),
        },
        {
          id: 5,
          title: t("168"),
          path: "/personManageIndex",
          url: require("../../../asset/img/person.png"),
          url1: require("../../../asset/img/person_bg.png"),
        },
      ],
    });
    onMounted(() => {
      // 防止刷新侧边栏图标点亮不对
      state.imgId = localStorage.getItem("menusId") || state.imgId;
    });
    const methods = reactive({
      Jump(path, id) {
        state.imgId = id;
        localStorage.setItem("menusId", id);
        router.push(path);
      },
    });
    return {
      ...toRefs(state),
      ...toRefs(methods),
    };
  },
});
</script>

<style lang='less' scoped>
.menus {
  position: absolute;
  // overflow-y: auto;
  top: 6.25rem;
  left: 0;
  bottom: 0;

  ul {
    width: 5.5625rem;
    padding: 0;
    margin: 0;
  }
  li {
    cursor: pointer;
    // margin: 0 1rem 0 1.1875rem;
    writing-mode: vertical-lr;
    width: 3.375rem;
    height: 8.825rem;
    margin-top: 0.375rem;
    margin-left: 1.1875rem;

    img {
      position: relative;
      width: 100%;
      height: 100%;
    }
  }
  .title {
    color: #ffffff;
    font-weight: 400;
    position: absolute;
    padding-top: 3.4375rem;
    left: 2.1875rem;
    white-space: nowrap;
  }
  li:first-child {
    margin-top: 0;
    width: 3.375rem;
    height: 4.875rem;
  }
}
</style>