<template>
  <div class="tree_wrapper">
    <el-tree
      class="custom_tree"
      ref="elTree"
      :data="treeData"
      :props="defaultProps"
      :node-key="nodeKey"
      :default-expand-all="allExpand"
      :default-expanded-keys="expandedKeys"
      :expand-on-click-node="false"
      highlight-current
      @node-click="nodeClick"
       accordion
    >
      <template v-if="hasPopover" class="custom_tree_node" v-slot="{ node, data }">
        <slot name="iconcontent" :node="node"></slot>
        <span>{{ node.label }}</span>
        <el-popover
          placement="right"
          :show-arrow="false"
          trigger="hover"
       
          popper-class="nopadding_popover"
        >
          <template #reference>
            <el-icon class="el_icon">
              <MoreFilled />
            </el-icon>
          </template>
          <!-- popover内容 -->
          <slot name="popovercontent" :node="node" :data="data"></slot>
        </el-popover>
      </template>
    </el-tree>
  </div>
</template>
<script>
import { t } from '../../languages';
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'eleTree',
  props: {
    // 树的数据
    treeData: {
      type: Array,
      default: () => [],
    },
    // 默认展开的树的数组
    expandedKeys: {
      type: Array,
      default: () => [],
    },

    // 配置选项
    defaultProps: {
      children: 'children',
      label: 'label',
    },
    // 树节点的唯一标识的属性
    nodeKey: {
      type: String,
      default: '',
    },
    // 改变树宽度
    changeTreeWidth: {
      type: Boolean,
      default: false,
    },
    // 默认展开全部
    allExpand: {
      type: Boolean,
      default: true,
    },
    // 是否有popover
    hasPopover: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['node-click'],
  setup(props, { emit }) {
    const elTree = ref(null);
    /* 点击树节点 */
    function nodeClick(nodeData, dataNode, nodeCom) {
      emit('node-click', nodeData, dataNode, nodeCom);
    }

    /* 手动选中树节点  key为唯一标识（必须设置node-key） */
    function setCurrentKey(key) {
      elTree.value.setCurrentKey(key);
    }
    return {
      elTree,
      nodeClick,
      setCurrentKey,
    };
  },
});
</script>
<style lang="less" scoped>
.el-tree {
  color: @heightLight;
  background: transparent;
}
/deep/ .el-tree-node__content {
  height: 40px;
  // background: #00192c;
  // padding-left: 1.25rem !important;
  .el-tree-node__expand-icon {
    margin-left: 1.25rem;
  }
}
/deep/ .el-tree-node:focus > .el-tree-node__content {
  background: @blue;
  color: #fff; //背景色
}
/deep/ .el-tree-node__content {
  &:hover {
    background: @blue;
    color: #fff;
  }
}
/deep/
  .el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  background: @blue;
  color: #fff; //背景色
}
.el_icon {
  position: absolute;
  right: 0.875rem;
  font-size: 1.2rem;

  svg {
    color: #fff;
  }
}
</style>
