<template>
  <div class="imgDialog">
    <el-dialog
      v-model="dialogVisible"
      lock-scroll
      width="100%"
      @close="closeDialog"
    >
      <!-- 若有左上角按钮，则需传入图层距离按钮的距离imgTop -->
      <div class="dialogBody" :style="{ marginTop: imgTop }">
        <div class="container_box full_height">
          <video id="myVideo" controls></video>
          <!-- <div class="container full_height" id="container"></div> -->
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { t } from '../../languages';
import { defineComponent, ref, onMounted, nextTick, toRefs } from "vue";
import { initVideo } from "@/utils/flv";
export default defineComponent({
  /**
   * 使用时传参(不需要用到则不用传)
   * isVisebleImg --是否显示
   * imgTop --图片与插槽间距
   *
   * 绑定事件
   * onClose --同步设置关闭弹窗
   */
  props: {
    isVisebleVideo: [Boolean],
    imgTop: {
      type: String,
      default: "4rem",
    },
    openType: {
      type: String,
      default: "",
    },
    dataUrl: {
      type: String,
    },
  },
  emits: ["onClose"],
  setup(props, { emit }) {
    let myVideo; //video的dom
    const maxTime = ref(); //  视频总时长(秒数)
        //解析外部组件传入的值
    let { isVisebleVideo, dataUrl } = toRefs(props);
    /* 弹窗关闭事件 */
    let dialogVisible = ref(false);
    dialogVisible.value = isVisebleVideo.value;

    //关闭按钮
    function closeDialog() {
      dialogVisible.value = false;
      emit("onClose");
    }

    onMounted(() => {
      nextTick(()=>{
        initPanorama();
      })
    });
    /* 初始化全景 */
    function initPanorama() {
      myVideo = document.getElementById("myVideo");
      myVideo.addEventListener("canplay", function () {
        setTimeout(() => {
          maxTime.value = this.duration; //视频时长
        }, 1000);
      });
      initVideo(myVideo, dataUrl.value, 'mp4');
      myVideo.play();
    }

    return {
      dialogVisible,
      closeDialog,
      maxTime,
    };
  },
});
</script>
<style scoped lang="less">
.imgDialog {
  .dialogBody {
    width: 95.625rem;
    height: 45.5rem;
    margin: 0;
    padding: 0;
  }
}
/deep/.el-dialog {
  background: rgba(0, 0, 0, 0.6);
  margin: 0;
  height: calc(100% - 3.75rem);
  .el-dialog__header {
    padding-top: 0;
    padding-left: 5.875rem;
    margin-top: 3.75rem;
  }
  .el-dialog__body {
    padding-left: 13.0625rem;
    padding-top: 0;
    padding-bottom: 0;
  }
  .el-dialog__close {
    color: #ffffff;
    font-size: 23px;
    font-weight: bold;
  }
}
.el-dialog__header {
  margin-bottom: 1.5rem;
}
.container_box {
  position: relative;
  #myVideo {
  width: 100%;
  height: 100%;
  background: #000;
}
}

</style>
